import { combineReducers, configureStore } from "@reduxjs/toolkit";

import appSlice from "store/slices/appSlice";
import chatSlice from "store/slices/chatSlice/slice";
import datasetSlice from "store/slices/datasetSlice/slice";
import homepageSlice from "store/slices/homepageSlice";
import deckSlice from "store/slices/deckSlice/slice";
import fileSlice from "store/slices/filesSlice/slice";
import favoritesSlice from "store/slices/favoritesSlice";
import trashSlice from "store/slices/trashSlice/trashSlice";
import settingsSlice from "store/slices/settingsSlice";
import { RESET_APP_STORE } from "store/actions_types";
import { apiSlice } from "store/api/apiSlice";

const appReducer = combineReducers({
  app: appSlice,
  dataset: datasetSlice,
  homepage: homepageSlice,
  deck: deckSlice,
  files: fileSlice,
  favorites: favoritesSlice,
  trash: trashSlice,
  settings: settingsSlice,
  chat: chatSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP_STORE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(apiSlice.middleware),
  });
};

export default store;
