import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { navigate } from "services/react_router";

import { getUserInfo } from "services/APIs";
import {
  handleIsUserDeactivated,
  selectHistoryPathname,
  selectHistorySearchParam,
  selectHistoryStateProp,
  updateUserInfo,
} from "store/slices/appSlice";
import { updateRecentlyUserInfo } from "store/slices/homepageSlice";

export function useGetUserInfo() {
  const dispatch = useDispatch();

  const pathname = useSelector(selectHistoryPathname);
  const planHistState = useSelector((state) =>
    selectHistoryStateProp(state, "plan")
  );
  const planSearParam = useSelector((state) =>
    selectHistorySearchParam(state, "plan")
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const data = { params: { create_on_sign_up: true } };
    getUserInfo(data).then(handleSuccess).catch(handleError);
  }, []);

  function handleSuccess({ data }) {
    const { onboarding_required } = data.onboarding;

    dispatch(updateUserInfo({ user: data }));
    setLoading(false);
    if (onboarding_required) {
      const navigateTo = { pathname: "/profile" };

      if (planHistState) {
        navigateTo.search = `?plan=${planHistState}`;
      }

      if (planSearParam) {
        navigateTo.search = `?plan=${planSearParam}`;
      }

      navigate(navigateTo);
      return;
    }

    let isHomePage = pathname === "/";
    if (isHomePage) {
      dispatch(updateRecentlyUserInfo({ loading: false, error: false, data }));
    }
  }

  function handleError(error) {
    const code = error.response?.data?.error_code;

    if ([4031, 4032, 4033].includes(code)) {
      const code = error.response.data.error_code;
      const status = error.response.status;

      setLoading(false);
      dispatch(handleIsUserDeactivated({ status, code }));
    }
  }

  return { loading };
}
