const initialState = {
  files: { data: null, loading: true, error: false },
  fileDeletionScrState: { show: false },
  fileToRestore: null,
  selectedFilesState: {
    files: [],
    lastSelectedFile: null,
  },
};

export default initialState;
