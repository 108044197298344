import { createSelector } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";

import {
  FILE_DELET_FIHS,
  FILE_MOVE_FIHS,
  FILE_OPTS_FIHS,
  FILE_RENAME_FIHS,
} from "services/history_states";
import { filesApiSlice } from "store/api/filesApiSlice";
import { selectHistoryStateProp } from "store/slices/appSlice";
import store from "store/store";

// Get data from cache
const selectFilesResult = filesApiSlice.endpoints.getFiles.select();
export const selectFilesData = createSelector(
  selectFilesResult,
  (files) => files.data
);

export const selectIsFetchingFiles = createSelector(
  selectFilesResult,
  (result) => result.isLoading && !!result.data
);

export const selectIsEmptyState = createSelector(selectFilesData, (data) => {
  if (!data) return;
  // ALGORITHM: check "pages/FilesPage/EmptyState/EmptyState"
  const { parentFolderId, sharedFilesIds, ownedFilesIds } = data;

  if (parentFolderId) {
    return false;
  }

  return !(sharedFilesIds.length + ownedFilesIds.length);
});

export const selectParentFolderId = createSelector(selectFilesData, (data) => {
  return data.parentFolderId;
});

export const selectParentFolder = createSelector(selectFilesData, (data) => {
  return data.files.entities[data.parentFolderId];
});

export const selectParentFolderName = createSelector(
  selectFilesData,
  (data) => {
    if (!data) return;
    if (!data.parentFolderId) return;

    return data.files.entities[data.parentFolderId].file_name;
  }
);

export const selectFilesIds = createSelector(
  selectFilesData,
  (state) => state.files.isSharedView,
  (data, shared) => {
    if (data.parentFolderId) {
      return data.childrenIds;
    }

    if (shared) {
      return data.sharedFilesIds;
    }
    return data.ownedFilesIds;
  }
);

export const selectFileById = createSelector(
  selectFilesData,
  (_, fileId) => fileId,
  (data, fileId) => {
    return data.files.entities[fileId];
  }
);

export const selectSelectedFiles = createSelector(
  selectFilesData,
  (state) => state.files.select.filesIds,
  (data, filesIds) => {
    const files = [];
    filesIds.forEach((id) => {
      const file = data.files.entities[id];
      if (file) {
        files.push(file);
      }
    });

    return files;
  }
);

export const selectSelectedFilesIds = ({ files }) => files.select.filesIds;

export const selectSelectedFilesCount = ({ files }) =>
  files.select.filesIds.length;

export const selectLastSelectedFileId = ({ files }) =>
  files.select.lastSelectedFileId;

export const selectIsFileSelected = ({ files }, fileId) => {
  const selectedFiles = files.select.filesIds;
  return selectedFiles.some((id) => id === fileId);
};

export const selectFileRenameFile = createSelector(
  selectFilesData,
  (state) => state.files.rename.fileId,
  (data, fileId) => data.files.entities[fileId]
);

export const selectDatasetById = createSelector(
  selectFilesData,
  (state) => state.app.newlyCreatedDataset,
  (_, fileId) => fileId,
  (data, newlyCreatedDataset, fileId) => {
    // If the dataset in a new dataset, return it
    if (newlyCreatedDataset?.file_id === fileId) {
      return newlyCreatedDataset;
    }

    // Return dataset from files store
    const file = data.files.entities[fileId];
    return file;
  }
);

export const selectIsFileMoveScrOpened = (state) => {
  // ALGORITHM
  // Check "pages/FilesPage/FileMove/FileMove"

  const { files } = state;

  const selectedFilesIds = files.select.filesIds;
  const fileMoveState = files.fileMove;

  // ALGORITHM 1.a. and 2.a.
  if (!selectedFilesIds.length) return;

  if (isMobile) {
    const histState = selectHistoryStateProp(state, FILE_MOVE_FIHS);
    // ALGORITHM 1.b.
    return histState;
  }

  // ALGORITHM 2.b.
  return fileMoveState.show;
};

export const selectIsSharedView = ({ files }) => files.isSharedView;

export const selectPageLoader = ({ files }) => files.pageLoader;

export const selectIsDeletionScreOpened = (state) => {
  // ALGORITHM
  // Check "pages/FilesPage/FileDeletion/FileDeletion"

  const { files } = state;

  const selectedFiles = files.select.filesIds;
  const showFileDeletion = files.fileDeletionScrState.show;

  // ALGORITHM 1.a. and 2.a.
  if (!selectedFiles.length) return;

  if (isMobile) {
    const histState = selectHistoryStateProp(state, FILE_DELET_FIHS);

    // ALGORITHM 1.b.
    return histState;
  }

  // ALGORITHM 2.b.
  return showFileDeletion;
};

export const selectIsFileOptsOpened = (state, showFileOptions, value) => {
  // ALGORITHM
  // Check "pages/FilesPage/FileOptions/FileOptions"
  if (isMobile) {
    const histState = selectHistoryStateProp(state, FILE_OPTS_FIHS);

    // ALGORITHM 1.a.
    return histState === value;
  }

  // ALGORITHM 2.a.
  return showFileOptions;
};

export const selectIsFileRenameScrOpened = (state) => {
  // ALGORITHM
  // Check "pages/FilesPage/FileRename/FileRename"

  const { show, fileId } = state.files.rename;

  if (isMobile) {
    const histState = selectHistoryStateProp(state, FILE_RENAME_FIHS);

    // ALGORITHM 1.a.
    return histState && show && fileId;
  }

  // ALGORITHM 2.a.
  return show && fileId;
};

export const selectIsDraggingActive = ({ files }) =>
  files.drag.isDraggingActive;

//
export function getFileByIndex(index) {
  const storeState = store.getState();
  const fileId = selectFilesIds(storeState)[index];
  const file = selectFileById(storeState, fileId);

  return file;
}

export function getParentFolderId() {
  const storeState = store.getState();
  const parentFolderId = selectParentFolderId(storeState);

  return parentFolderId;
}

export function getParentFolder() {
  const storeState = store.getState();
  const folder = selectParentFolder(storeState);

  return folder;
}

export function getFileType(fileId) {
  const storeState = store.getState();
  const file = selectFileById(storeState, fileId);

  return file.file_type;
}
