import { apiSlice } from "store/api/apiSlice";

// NOTE: Endpoints definitions will be ended with FP to avoid overriding
export const filesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFiles: builder.query({
      query: (arg) => {
        const { parent_folder_id } = arg;

        let params = {};
        if (parent_folder_id) {
          params.parent_folder_id = parent_folder_id;
        }

        const url = "/files";

        return { url, params };
      },
      transformResponse: (respData) => {
        const {
          files_owned_by_user,
          files_shared_with_user,
          children_files,
          parent_folder,
        } = respData;

        // Normalized data
        const data = {
          files: {
            entities: {},
            ids: [],
          },
          ownedFilesIds: [],
          sharedFilesIds: [],
          folderIds: [],
          decksIds: [],
          datasetsIds: [],
          parentFolderId: null,
          childrenIds: [],
        };

        if (parent_folder) {
          data.parentFolderId = parent_folder.file_id;
          data.files.entities[parent_folder.file_id] = parent_folder;
          data.files.ids.push(parent_folder.file_id);

          children_files.forEach((file) => {
            normalizeData({ data, file, type: "children" });
          });
        } else {
          files_owned_by_user.forEach((file) => {
            normalizeData({ data, file, type: "owned" });
          });
          files_shared_with_user.forEach((file) => {
            normalizeData({ data, file, type: "shared" });
          });
        }

        return data;
      },
      serializeQueryArgs: () => "getFiles",
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
    }),
    createFolder: builder.mutation({
      query: (arg) => {
        const { fileName, parent_folder_id } = arg;

        let params = {};
        if (parent_folder_id) {
          params.parent_folder_id = parent_folder_id;
        }

        const body = {
          file_type: "folder",
          file_name: fileName,
        };

        return { url: "/files", method: "POST", body, params };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const fileId = data.file_id;

              // Add the new folder to files
              draft.files.entities[fileId] = data;
              draft.files.ids.push(fileId);

              // Add the file id to folder ids array
              draft.folderIds.push(fileId);

              // Check if the parent folder is opened, add the file to children ids
              const ascending_hierarchy = data.ascending_hierarchy;
              const parentFolderId = ascending_hierarchy[0]?.folder_file_id;

              if (parentFolderId === draft.parentFolderId) {
                draft.childrenIds.push(fileId);
              } else {
                draft.ownedFilesIds.push(fileId);
              }
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    addToFavorites: builder.mutation({
      query: (arg) => {
        const { fileId } = arg;
        return { url: `/files/${fileId}/stars`, method: "PUT" };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        // TODO: update the response of API in backend
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];

              file.date_created = data.date_created;
              file.date_last_modified = data.date_last_modified;
              file.starred_by_user_requesting = true;
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    removeFromFavorites: builder.mutation({
      query: (arg) => {
        const { fileId } = arg;
        const body = { op: "replace", path: "/trashed", boolean_value: true };

        return { url: `/files/${fileId}/stars`, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        // TODO: update the response of API in backend, return last_modified_date
        function pessimisticUpdate() {
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];

              file.starred_by_user_requesting = false;
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    patchFileNameFP: builder.mutation({
      query: (arg) => {
        const { fileId, value } = arg;

        const body = {
          op: "replace",
          path: "/file_name",
          value,
        };

        return { url: `/files/${fileId}`, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;
              Object.assign(file, data);
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    validateDatasetFP: builder.mutation({
      query: (arg) => {
        const { fileId, boolean_value } = arg;

        const body = {
          op: "replace",
          path: "/validated",
          boolean_value,
        };

        return { url: `/files/${fileId}/dataset`, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;
              Object.assign(file.file_dataset, data.dataset);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    changeItemPriorityFP: builder.mutation({
      query: (arg) => {
        const { fileId, detail_id, aggregation_id, integer_value } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          integer_value,
        };

        if (aggregation_id) {
          body.aggregation_id = aggregation_id;
          body.path = "/dataset_aggregations/display_priority";
        }

        if (detail_id) {
          body.detail_id = detail_id;
          body.path = "/dataset_details/display_priority";
        }

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;
              Object.assign(file.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    changeItemHideFP: builder.mutation({
      query: (arg) => {
        const { fileId, detail_id, aggregation_id, boolean_value } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          boolean_value,
        };

        if (aggregation_id) {
          body.aggregation_id = aggregation_id;
          body.path = "/dataset_aggregations/hidden";
        }

        if (detail_id) {
          body.detail_id = detail_id;
          body.path = "/dataset_details/hidden";
        }

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;
              Object.assign(file.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateDatasetItemNameFP: builder.mutation({
      query: (arg) => {
        const { fileId, value, aggregation_id, detail_id } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          value,
        };

        if (aggregation_id) {
          body.aggregation_id = aggregation_id;
          body.path = "/dataset_aggregations/aggregation_display_name_value";
        }

        if (detail_id) {
          body.detail_id = detail_id;
          body.path = "/dataset_details/display_name";
        }

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;
              Object.assign(file.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    createDatasetAggregationFP: builder.mutation({
      query: (arg) => {
        const {
          fileId,
          components,
          aggregation_display_name,
          aggregation_filters,
        } = arg;

        const url = `/files/${fileId}/dataset/dataset_aggregations`;

        const body = {
          aggregation_type: "formula",
          components,
          aggregation_display_name,
        };

        if (aggregation_filters) {
          body.aggregation_filters = aggregation_filters;
        }

        return { url, method: "POST", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;
              Object.assign(file.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    deleteMetricFP: builder.mutation({
      query: (arg) => {
        const { fileId, boolean_value, aggregations_list } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          path: "/dataset_aggregations/multiple/trashed",
          boolean_value,
          aggregations_list,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;
              Object.assign(file.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateDatasetItemDefFP: builder.mutation({
      query: (arg) => {
        const { fileId, req_body } = arg;

        const url = `/files/${fileId}/dataset`;

        return { url, method: "PATCH", body: req_body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;
              Object.assign(file.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateFilePrivacyFP: builder.mutation({
      query: (arg) => {
        const { fileId, value } = arg;

        const url = `/files/${fileId}`;

        const body = {
          op: "replace",
          path: "/private",
          boolean_value: value,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;
              Object.assign(file, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    createFilePermissionFP: builder.mutation({
      query: (arg) => {
        const {
          existing_permission_user_id,
          permission_file_id,
          permission_role_name,
        } = arg;
        const url = "/permissions";
        const body = {
          existing_permission_user_id,
          permission_file_id,
          permission_role_name,
        };
        const params = { base_url: window.location.origin };
        return { url, method: "POST", body, params };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { permission_file_id } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;
        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          const { file_permissions } = data;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[permission_file_id];

              if (!file) return;
              file.file_permission_count += 1;
              file.file_permissions = file_permissions;
            })
          );
        }
        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updatePermissionFP: builder.mutation({
      query: (arg) => {
        const { permissionId, role } = arg;

        const url = `/permissions/${permissionId}`;

        const body = {
          op: "replace",
          path: "/permission_role_name",
          value: role,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;

          const {
            permission_role_name,
            permission_user,
            permission_id,
            date_last_modified,
          } = data;

          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;

              file.date_last_modified = date_last_modified;
              file.file_permissions = file.file_permissions.map(
                (permission) => {
                  if (permission_id === permission.permission_id) {
                    return {
                      permission_role_name,
                      permission_user,
                      permission_id,
                    };
                  } else {
                    return permission;
                  }
                }
              );
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    deletePermissionFP: builder.mutation({
      query: (arg) => {
        const { permissionId, params } = arg;

        const url = `/permissions/${permissionId}`;

        return { url, method: "DELETE", params };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId, permissionId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;

          const {
            permission_file_date_last_modified,
            permission_file_user_last_modified,
          } = data;

          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const file = draft.files.entities[fileId];
              if (!file) return;

              file.date_last_modified = permission_file_date_last_modified;
              file.user_last_modified = permission_file_user_last_modified;
              file.file_permission_count -= 1;
              file.file_permissions = file.file_permissions.filter(
                (permission) => permission.permission_id !== permissionId
              );
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    moveMultiFilesFP: builder.mutation({
      query: (arg) => {
        const { files_list, targetFolderId } = arg;

        const url = "/files";
        const body = {
          op: "move",
          path: "/multiple",
          files_list,
        };

        if (targetFolderId) {
          body.target_folder_id = targetFolderId;
        } else {
          body.target_root = true;
        }

        return { url, method: "PATCH", body };
      },
    }),
    deleteMultiFilesFP: builder.mutation({
      query: (arg) => {
        const { files_list } = arg;

        const url = "/files";
        const body = {
          op: "replace",
          path: "/multiple/trashed",
          boolean_value: true,
          files_list,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;

          const { files } = data.success;

          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              files.forEach((file) => {
                const fileId = file.file_id;

                // Remove the file from files
                delete draft.files.entities[fileId];
                draft.files.ids = draft.files.ids.filter((id) => id !== fileId);

                // TODO: return file type in API

                if (draft.folderIds.includes(fileId)) {
                  draft.folderIds = draft.folderIds.filter(
                    (id) => id !== fileId
                  );
                } else if (draft.datasetsIds.includes(fileId)) {
                  draft.datasetsIds = draft.datasetsIds.filter(
                    (id) => id !== fileId
                  );
                } else if (draft.decksIds.includes(fileId)) {
                  draft.decksIds = draft.decksIds.filter((id) => id !== fileId);
                }

                if (draft.childrenIds.includes(fileId)) {
                  draft.childrenIds = draft.childrenIds.filter(
                    (id) => id !== fileId
                  );
                } else if (draft.ownedFilesIds.includes(fileId)) {
                  draft.ownedFilesIds = draft.ownedFilesIds.filter(
                    (id) => id !== fileId
                  );
                } else {
                  draft.sharedFilesIds = draft.sharedFilesIds.filter(
                    (id) => id !== fileId
                  );
                }
              });
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    restoreMultiFilesFP: builder.mutation({
      query: (arg) => {
        const { files_list } = arg;

        const url = "/files?trashed=true";
        const body = {
          op: "replace",
          path: "/multiple/trashed",
          boolean_value: false,
          files_list,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { files: filesArg } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = filesApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;

          const { files } = data.success;

          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              files.forEach((file) => {
                const fileId = file.file_id;

                const fileArg = filesArg.find(
                  ({ file_id }) => file_id === fileId
                );
                const fileType = fileArg.file_type;

                // Add the file from files
                draft.files.entities[fileId] = fileArg;
                draft.files.ids.push(fileId);

                if (fileType === "folder") {
                  draft.folderIds.push(fileId);
                } else if (fileType === "deck") {
                  draft.decksIds.push(fileId);
                } else if (fileType === "dataset") {
                  draft.datasetsIds.push(fileId);
                }

                // Check if the parent folder is opened, add the file to children ids
                const ascending_hierarchy = fileArg.ascending_hierarchy;
                const parentFolderId = ascending_hierarchy[0]?.folder_file_id;

                if (parentFolderId === draft.parentFolderId) {
                  draft.childrenIds.push(fileId);
                } else {
                  draft.ownedFilesIds.push(fileId);
                }
              });
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
  }),
});

function normalizeData(args) {
  const { data, file, type } = args;
  const { file_id, file_type } = file;

  data.files.entities[file_id] = file;
  data.files.ids.push(file_id);

  if (file_type === "deck") data.decksIds.push(file_id);
  else if (file_type === "dataset") data.datasetsIds.push(file_id);
  else if (file_type === "folder") data.folderIds.push(file_id);

  if (type === "children") {
    data.childrenIds.push(file_id);
  }

  if (type === "owned") {
    data.ownedFilesIds.push(file_id);
  }

  if (type === "shared") {
    data.sharedFilesIds.push(file_id);
  }
}

export const {
  useGetFilesQuery,
  usePatchFileNameFPMutation,
  useCreateFolderMutation,
  useAddToFavoritesMutation,
  useRemoveFromFavoritesMutation,
  useValidateDatasetFPMutation,
  useChangeItemPriorityFPMutation,
  useChangeItemHideFPMutation,
  useUpdateDatasetItemNameFPMutation,
  useCreateDatasetAggregationFPMutation,
  useDeleteMetricFPMutation,
  useUpdateDatasetItemDefFPMutation,
  useUpdateFilePrivacyFPMutation,
  useUpdatePermissionFPMutation,
  useDeletePermissionFPMutation,
  useCreateFilePermissionFPMutation,
  useMoveMultiFilesFPMutation,
  useDeleteMultiFilesFPMutation,
  useRestoreMultiFilesFPMutation,
} = filesApiSlice;
