function OverdataLogoIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-overdata_logo_icon"
      {...props}
    >
      {/* Square  */}
      <path
        className="svg-overdata_logo_icon-path"
        d="M32 0H0V4.48H32V0Z"
        fill="var(--overdata-logo-secondary, #003381)"
      />
      <path
        className="svg-overdata_logo_icon-path"
        d="M4.48 0H0V32H4.48V0Z"
        fill="var(--overdata-logo-secondary, #003381)"
      />
      <path
        className="svg-overdata_logo_icon-path"
        d="M32.0034 0H27.5234V32H32.0034V0Z"
        fill="var(--overdata-logo-secondary, #003381)"
      />
      <path
        className="svg-overdata_logo_icon-path"
        d="M32 27.52H0V32H32V27.52Z"
        fill="var(--overdata-logo-secondary, #003381)"
      />

      {/* Letter  */}
      <path
        className="svg-overdata_logo_icon-path"
        d="M9.60156 16.255V15.9925C9.60156 15.0008 9.74324 14.0883 10.0265 13.255C10.3099 12.4134 10.7224 11.6842 11.264 11.0675C11.8057 10.4508 12.4724 9.97167 13.264 9.62999C14.0557 9.27999 14.964 9.10495 15.9891 9.10495C17.014 9.10495 17.9265 9.27999 18.7265 9.62999C19.5265 9.97167 20.1974 10.4508 20.7391 11.0675C21.2891 11.6842 21.7057 12.4134 21.9891 13.255C22.2724 14.0883 22.414 15.0008 22.414 15.9925V16.255C22.414 17.2383 22.2724 18.1508 21.9891 18.9925C21.7057 19.8258 21.2891 20.555 20.7391 21.18C20.1974 21.7966 19.5308 22.2758 18.7391 22.6175C17.9474 22.9592 17.0391 23.13 16.014 23.13C14.9891 23.13 14.0766 22.9592 13.2766 22.6175C12.4849 22.2758 11.814 21.7966 11.264 21.18C10.7224 20.555 10.3099 19.8258 10.0265 18.9925C9.74324 18.1508 9.60156 17.2383 9.60156 16.255ZM13.2016 15.9925V16.255C13.2016 16.8217 13.2516 17.3508 13.3516 17.8425C13.4516 18.3342 13.6099 18.7675 13.8265 19.1425C14.0516 19.5092 14.3432 19.7966 14.7016 20.005C15.0599 20.2134 15.4974 20.3175 16.014 20.3175C16.514 20.3175 16.9432 20.2134 17.3016 20.005C17.6599 19.7966 17.9474 19.5092 18.164 19.1425C18.3808 18.7675 18.5391 18.3342 18.6391 17.8425C18.7474 17.3508 18.8016 16.8217 18.8016 16.255V15.9925C18.8016 15.4425 18.7474 14.9258 18.6391 14.4425C18.5391 13.9508 18.3766 13.5175 18.1516 13.1425C17.9349 12.7592 17.6474 12.4592 17.2891 12.2425C16.9308 12.0258 16.4974 11.9175 15.9891 11.9175C15.4808 11.9175 15.0474 12.0258 14.6891 12.2425C14.3391 12.4592 14.0516 12.7592 13.8265 13.1425C13.6099 13.5175 13.4516 13.9508 13.3516 14.4425C13.2516 14.9258 13.2016 15.4425 13.2016 15.9925Z"
        fill="var(--overdata-logo-primary, #0e0d0f)"
      />
    </svg>
  );
}

export default OverdataLogoIcon;
