import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { selectChatName } from "store/slices/chatSlice/selectors";
import { selectDeckName } from "store/slices/deckSlice/selectors";
import {
  selectIsSharedView,
  selectParentFolderName,
} from "store/slices/filesSlice/selectors";
import { useAppCaption } from "./hooks";

// Custom hooks to handle page title
export const usePageTitle = () => {
  const isHomePage = useMatch("/");
  const isChatPage = useMatch("/chat");
  const isChatsPage = useMatch("/chats/:file_id");
  const isDeckPage = useMatch("/decks/:deck_id");
  const isFavoritesPage = useMatch("/favorites");
  const isTrashPage = useMatch("/trashbin");
  const isFilesPage = useMatch("/files");
  const isFolderPage = useMatch("/files/:file_id");

  const folderName = useSelector(selectParentFolderName);
  const deckName = useSelector(selectDeckName);
  const chatName = useSelector(selectChatName);
  const isSharedView = useSelector(selectIsSharedView);
  const appCaption = useAppCaption();

  let pageTitle = "";

  if (isHomePage) {
    pageTitle = `Home - ${appCaption}`;
  }

  if (isChatPage) {
    pageTitle = `Chat - ${appCaption}`;
  }

  if (isChatsPage && chatName) {
    pageTitle = `${chatName} - ${appCaption}`;
  }

  if (isDeckPage && deckName) {
    pageTitle = `${deckName} - ${appCaption}`;
  }

  if (isFilesPage) {
    pageTitle = isSharedView
      ? `Files shared with me - ${appCaption}`
      : `My files - ${appCaption}`;
  }

  if (isFolderPage && folderName) {
    pageTitle = `${folderName} - ${appCaption}`;
  }

  if (isFavoritesPage) {
    pageTitle = `Favorites - ${appCaption}`;
  }

  if (isTrashPage) {
    pageTitle = `Trash bin - ${appCaption}`;
  }

  if (!pageTitle) {
    pageTitle = "From spreadsheets to insights in one click";
  }

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle, appCaption]);
};
