import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useScreenBreakpoints } from "services/hooks";
import {
  initBeacon,
  largeScrBeaconConfig,
  nonLargeScrBeaconConfig,
  updateBeaconConfig,
  updateBeaconIdentify,
} from "services/help_scout";
import {
  selectIsUserOnboardRequired,
  selectUserInfo,
} from "store/slices/appSlice";
import { activeHelpScout } from "services/constants";

import "./style.css";

// TODO
// Update beacon identify after users update his info.

function BeaconLogic() {
  const user = useSelector(selectUserInfo);

  const { isLargeScr } = useScreenBreakpoints();

  useEffect(() => {
    initBeacon();
    updateBeaconIdentify(user);
  }, []);

  useEffect(() => {
    if (isLargeScr) {
      updateBeaconConfig(largeScrBeaconConfig);
    } else {
      updateBeaconConfig(nonLargeScrBeaconConfig);
    }
  }, [isLargeScr]);

  return <></>;
}

function BeaconLogicWrapper() {
  const isUserOnboardRequired = useSelector(selectIsUserOnboardRequired);

  // Initialize beacon after user has completed his profile.
  if (isUserOnboardRequired || activeHelpScout !== "true") {
    return <></>;
  }

  return <BeaconLogic />;
}

export default BeaconLogicWrapper;
