// Thunks
import {
  getTrashedFilesThunk,
  deleteFilePermenantlyThunk,
  restoreFilesThunk,
} from "store/slices/trashSlice/thunks";

function extraReducers(builder) {
  builder.addCase(getTrashedFilesThunk.fulfilled, (state, action) => {
    state.files = { data: action.payload, loading: false, error: false };
  });

  builder.addCase(getTrashedFilesThunk.rejected, (state, action) => {
    state.files = {
      data: null,
      loading: false,
      error: action.error,
    };
  });

  builder.addCase(deleteFilePermenantlyThunk.fulfilled, (state, action) => {
    const { success } = action.payload;

    const successFilesIds = success.files.map(({ file_id }) => file_id);

    state.files.data = state.files.data.filter(
      (file) => !successFilesIds.includes(file.file_id)
    );
    return;
  });

  builder.addCase(restoreFilesThunk.fulfilled, (state, action) => {
    const { success } = action.payload;

    const successFilesIds = success.files.map(({ file_id }) => file_id);

    state.files.data = state.files.data.filter(
      (file) => !successFilesIds.includes(file.file_id)
    );
  });
}

export default extraReducers;
