import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";

import { navigate } from "services/react_router";
import {
  handleIsUserDeactivated,
  handleSubscribeLimitState,
} from "store/slices/appSlice";
import {
  deactivatedErrorCodes,
  subscriptionStatusLimitsCodes,
} from "services/error_messages";
import { SUBSCR_LIMIT_STATE_HS } from "services/history_states";

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (e) => {
      const code = e.response.data.error_code;
      const status = e.response.status;
      if (deactivatedErrorCodes.includes(code)) {
        dispatch(handleIsUserDeactivated({ status, code }));
      }

      if (status === 403 && subscriptionStatusLimitsCodes.includes(code)) {
        if (isMobile) {
          navigate(undefined, {
            stat: { [SUBSCR_LIMIT_STATE_HS]: { show: true } },
          });
        }

        dispatch(handleSubscribeLimitState({ show: true, status, code }));
      }

      return Promise.reject(e);
    };

    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [dispatch]);

  return children;
};

export default AxiosInterceptor;
