import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";

import Theme from "pages/Theme";

import { useSegment } from "services/segment";
import { usePaddle } from "services/paddle";
import { useHelpScout } from "services/help_scout";

import "./style.css";

const HomePage = lazy(() => import("pages/HomePage/HomePage"));
const NotebookPage = lazy(() => import("pages/NotebookPage/NotebookPage"));
const LandingPage = lazy(() => import("pages/LandingPage/LandingPage"));
const ChatPage = lazy(() => import("pages/ChatsPage/ChatPage"));
const ChatsPage = lazy(() => import("pages/ChatsPage/ChatsPage"));
const ProfilePage = lazy(() => import("pages/ProfilePage/ProfilePage"));
const FilesPage = lazy(() => import("pages/FilesPage/FilesPage"));
const TrashPage = lazy(() => import("pages/TrashPage/TrashPage"));
const FavoritesPage = lazy(() => import("pages/FavoritesPage/FavoritesPage"));
const QuickDropPage = lazy(() => import("pages/QuickDropPage/QuickDropPage"));
const SettingsPage = lazy(() => import("pages/SettingsPage/SettingsPage"));
const AppSideBar = lazy(() =>
  import("components/shared/AppSideBar/AppSideBar")
);
const AppTopBar = lazy(() => import("components/shared/AppTopBar/AppTopBar"));
const PlanChangeNotification = lazy(() =>
  import("components/shared/PlanChangeNotification/PlanChangeNotification")
);
const SubscribeScreen = lazy(() =>
  import("components/shared/SubscribeScreen/SubscribeScreen")
);
const ErrorBoundary = lazy(() =>
  import("components/shared/ErrorBoundary/ErrorBoundary")
);
const OnboardingScreen = lazy(() =>
  import("components/shared/OnboardingScreen/OnboardingScreen")
);
const DeferredPrompt = lazy(() =>
  import("components/shared/DeferredPrompt/DeferredPrompt")
);

// Render the basic HTML structrue for app
function AppPage() {
  useSegment();
  usePaddle();
  useHelpScout();

  return (
    <Theme>
      <div className="app-container">
        <Suspense fallback={<></>}>
          <AppPageHeader />
        </Suspense>
        <AppPageMain />
        <AppPageFooter />
      </div>

      <Suspense fallback={<></>}>
        <OnboardingScreen />
      </Suspense>

      <Suspense fallback={<></>}>
        <SettingsPage />
      </Suspense>

      <Suspense fallback={<></>}>
        <DeferredPrompt />
      </Suspense>

      <Suspense fallback={<></>}>
        <PlanChangeNotification />
      </Suspense>
    </Theme>
  );
}

function AppPageHeader() {
  // The element with id equals to "app-header-banner",
  // will be used to render any banner at top
  return (
    <div className="app-header">
      <div id="app-header-banner"></div>
      <Suspense fallback={<></>}>
        <AppTopBar />
      </Suspense>
    </div>
  );
}

function AppPageMain() {
  return (
    <main className="app-main">
      <Suspense fallback={<></>}>
        <AppSideBar />
      </Suspense>

      <Suspense fallback={<></>}>
        <SubscribeScreen />
      </Suspense>

      <Routes>
        <Route
          path="/profile"
          element={
            <Suspense fallback={<></>}>
              <ProfilePage />
            </Suspense>
          }
          errorElement={<Error />}
        />
        <Route
          path={"/decks/:deck_id"}
          element={
            <Suspense fallback={<></>}>
              <NotebookPage />
            </Suspense>
          }
          errorElement={<Error />}
        />
        <Route
          path={"/chats/:file_id"}
          element={
            <Suspense fallback={<></>}>
              <ChatsPage />
            </Suspense>
          }
          errorElement={<Error />}
        />
        <Route
          path={"/chat"}
          element={
            <Suspense fallback={<></>}>
              <ChatPage />
            </Suspense>
          }
          errorElement={<Error />}
        />
        <Route
          path="/favorites"
          element={
            <Suspense fallback={<></>}>
              <FavoritesPage />
            </Suspense>
          }
          errorElement={<Error />}
        />
        <Route
          path="/trashbin"
          element={
            <Suspense fallback={<></>}>
              <TrashPage />
            </Suspense>
          }
          errorElement={<Error />}
        />
        <Route
          path="/quickdrop"
          element={
            <Suspense fallback={<></>}>
              <QuickDropPage />
            </Suspense>
          }
          errorElement={<Error />}
        />
        <Route
          path="/files/:file_id?"
          element={
            <Suspense fallback={<></>}>
              <FilesPage />
            </Suspense>
          }
          errorElement={<Error />}
        />
        <Route
          path="/home"
          element={
            <Suspense fallback={<></>}>
              <HomePage />
            </Suspense>
          }
          errorElement={<Error />}
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<></>}>
              <LandingPage />
            </Suspense>
          }
          errorElement={<Error />}
        />
      </Routes>
    </main>
  );
}

function AppPageFooter() {
  // The element with id equals to "app-footer-banner"
  // will be used to render any banner at bottom
  return (
    <div className="app-footer">
      <div id="app-footer-banner"></div>
    </div>
  );
}

function Error() {
  return (
    <Suspense fallback={<></>}>
      <ErrorBoundary />
    </Suspense>
  );
}

export default AppPage;
