import { Auth0Provider } from "@auth0/auth0-react";

import { navigate } from "services/react_router";

import {
  auth0Domain,
  auth0ClientId,
  auth0ApiAudience,
} from "services/constants";

import "./style.css";

function Auth0ProviderWithHistory({ children }) {
  const onRedirectCallback = (appState) => {
    navigate(
      appState?.returnTo ? appState.returnTo : window.location.pathname,
      { state: appState }
    );
  };

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: auth0ApiAudience,
      }}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
