import { createSlice } from "@reduxjs/toolkit";

import initialState from "store/slices/chatSlice/initialState";
import reducers from "store/slices/chatSlice/reducers";

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers,
});

export const {
  updateFileSelect,
  updateShowChatEditionMenu,
  updateInstEditDeckItemId,
  updateInstEdit,
  updateShowChatDeletion,
} = chatSlice.actions;

export default chatSlice.reducer;
