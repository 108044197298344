import { usePageTitle } from "services/page_titles";

// The 'usePageTitle' hook is used in separate component
// to prevent re-rendering of whole page
function PageTitle() {
  usePageTitle();

  return <></>;
}

export default PageTitle;
