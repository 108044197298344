import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "services/axiosInterceptors";

import Auth0ProviderWithHistory from "components/Auth/Auth0ProviderWithHistory";

import { router } from "services/react_router";
import AppPage from "pages/AppPage";
import PageTitle from "components/shared/PageTitle/PageTitle";

import UserInfoProvider from "components/shared/UserInfoProvider/UserInfoProvider";
import BeaconLogic from "components/BeaconLogic/BeaconLogic";
import AxiosInterceptor from "components/shared/AxiosInterceptor/AxiosInterceptor";
import SegmentRegister from "components/SegmentRegister/SegmentRegister";
import PendoRegisterWrapper from "components/PendoRegister/PendoRegister";
import AccessToken from "components/shared/AccessToken/AccessToken";
import Toastify from "components/shared/Toastify/Toastify";

import { updateHistoryArray } from "store/slices/appSlice";
import { getSearchParam } from "services/helpers";
import { updateIsSharedView } from "store/slices/filesSlice/slice";

function Root() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Create the initial hitory object
    const { location, historyAction } = router.state;
    dispatch(updateHistoryArray({ location, historyAction }));

    // TODO: will be removed later here and from MockAppPage
    const search = location.search;
    const isSharedView = getSearchParam(search, "view") === "shared_with_me";
    dispatch(updateIsSharedView(isSharedView));

    const unsubscribe = router.subscribe((state) => {
      const { location, historyAction } = state;

      dispatch(updateHistoryArray({ location, historyAction }));

      // TODO: will be removed later
      const search = location.search;
      const isSharedView = getSearchParam(search, "view") === "shared_with_me";
      dispatch(updateIsSharedView(isSharedView));
    });

    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Auth0ProviderWithHistory>
      <AxiosInterceptor>
        <AccessToken>
          <UserInfoProvider>
            <AppPage />
            <PageTitle />
            <BeaconLogic />
            <SegmentRegister />
            <PendoRegisterWrapper />
            <Toastify />
          </UserInfoProvider>
        </AccessToken>
      </AxiosInterceptor>
    </Auth0ProviderWithHistory>
  );
}

export default Root;
