import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import {
  selectIsUserOnboardRequired,
  selectPendoRegisterUserInfo,
} from "store/slices/appSlice";

// Initialize pendo
function PendoRegister() {
  const user = useSelector(selectPendoRegisterUserInfo);
  const {
    user_id,
    user_name,
    user_email,
    plan,
    date_created,
    userCompanyRole,
    companyId,
    companyName,
    companyCreatedAt,
    user_picture_secure_url,
  } = user;

  useEffect(() => {
    const pendo = window.pendo;

    const visitor = {
      id: user_id,
      full_name: user_name,
      email: user_email,
      plan,
      createdAt: moment(date_created).toISOString(true),
    };
    const account = {};

    if (companyId) {
      visitor["userCompanyRole"] = userCompanyRole;

      account["id"] = companyId;
      account["name"] = companyName;
      account["plan"] = plan;
      account["createdAt"] = moment(companyCreatedAt).toISOString(true);
    }

    pendo.initialize({
      visitor,
      account,
    });

    // With any changes in user_name, user_picture_secure_url, companyName, companyId or plan,
    // The user will be re-identify
  }, [companyId, companyName, user_name, user_picture_secure_url, plan]);

  return <></>;
}

function PendoRegisterWrapper() {
  const isUserOnboardRequired = useSelector(selectIsUserOnboardRequired);

  // Initialize pendo after user have completed his profile.
  if (isUserOnboardRequired) {
    return <></>;
  }

  return <PendoRegister />;
}

export default PendoRegisterWrapper;
