// Initial State
import initialState from "store/slices/trashSlice/initialState";

function resetTrashSlice() {
  return initialState;
}

function showPageLoader(state) {
  state.files.loading = true;
  state.files.error = false;
}

function handleFileDeletionScr(state, action) {
  state.fileDeletionScrState = action.payload;
}

function updateSelectedFiles(state, action) {
  state.selectedFilesState.files = action.payload;
}

function updateFileToRestore(state, action) {
  state.fileToRestore = action.payload;
}

function onSelectFile(state, action) {
  // ALGORITHM: To handle select many files:
  // 1- Many files selection is enabled in large screen only.
  // 2- When user clicks on file and there are no selected files, add the new file.
  // 3- When user clicks on file and there are selected files:
  //    3.a. If 'shift', 'ctrl' and 'meta' keys are not clicked,
  //         update the selected files to have only the new file
  //    3.b. If 'ctrl' key or 'meta' key is clicked:
  //         3.b.i.  If file is already selected,
  //                 Remove the file and update the last selected file
  //         3.b.ii. If file is not already selected, add the file to selected files
  //    3.c. If 'shift' key is clicked, select all files in between

  const { event, file, isLargeScr } = action.payload;

  const fileToSelect = file;

  const files = state.files.data;
  const selectedFiles = state.selectedFilesState.files;
  const selectedFilesIds = selectedFiles.map(({ file_id }) => file_id);
  const lastSelectedFile = state.selectedFilesState.lastSelectedFile;

  state.selectedFilesState.lastSelectedFile = fileToSelect;

  // ALGORITHM 1.
  if (!isLargeScr) {
    state.selectedFilesState.files = [fileToSelect];
    return;
  }

  // ALGORITHM 2.
  if (!selectedFiles.length) {
    state.selectedFilesState.files = [fileToSelect];
    return;
  }

  const { ctrlKey, shiftKey, metaKey } = event;

  // ALGORITHM 3.a.
  if (!shiftKey && !ctrlKey && !metaKey) {
    state.selectedFilesState.files = [fileToSelect];
    return;
  }

  // ALGORITHM 3.b.
  if (ctrlKey || metaKey) {
    // ALGORITHM 3.b.i.
    const isSelected = selectedFilesIds.includes(fileToSelect.file_id);

    if (isSelected) {
      state.selectedFilesState.files = selectedFiles.filter(
        ({ file_id }) => file_id !== fileToSelect.file_id
      );
      state.selectedFilesState.lastSelectedFile =
        selectedFiles[selectedFiles.length - 2];

      return;
    }

    // ALGORITHM 3.b.ii.
    state.selectedFilesState.files.push(fileToSelect);
  }

  if (shiftKey) {
    const firstSelectedElement = lastSelectedFile
      ? lastSelectedFile
      : selectedFiles[0];
    const indexFirstSelectedElement = files.findIndex(
      ({ file_id }) => file_id === firstSelectedElement.file_id
    );

    const clickedElement = fileToSelect;
    const indexClickedElement = files.findIndex(
      ({ file_id }) => file_id === clickedElement.file_id
    );

    let startInd = Math.min(indexClickedElement, indexFirstSelectedElement);
    let endInd = Math.max(indexClickedElement, indexFirstSelectedElement);

    const newSelectedFiles = [...selectedFiles];

    files.forEach(({ file_id, file_type }, index) => {
      if (index >= startInd && index <= endInd) {
        const isSelected = selectedFilesIds.includes(file_id);

        if (isSelected) return;

        newSelectedFiles.push({ file_id, file_type });
      }
    });

    state.selectedFilesState.files = newSelectedFiles;

    return;
  }
}

const actions = {
  resetTrashSlice,
  showPageLoader,
  handleFileDeletionScr,
  updateSelectedFiles,
  onSelectFile,
  updateFileToRestore,
};

export default actions;
