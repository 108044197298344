import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectDisplayThemeUSERSET } from "store/slices/appSlice";

function Theme({ children }) {
  const theme = useSelector(selectDisplayThemeUSERSET);
  // Display theme value is one of:
  // - system --> string
  // - light  --> string
  // - dark   --> string
  // - overdata_light   --> string
  // - overdata_dark   --> string

  // The app theme is handled by two data-* attributes in body element:
  // - data-system-theme  --> To check if 'display_theme' equals to 'system'.
  // - data-theme         --> To be used in stylesheet.

  // 'data-system-theme' attribute value is one of:
  // - true   --> If 'display_theme' equals 'system'.
  // - false  --> If 'display_theme' doesn't equal 'system'.

  // 'data-theme' attribute value is one of:
  // - 'light'.
  // - 'dark'.
  // - 'overdata_light'.
  // - 'overdata_dark'.

  // ALGORITHM: The algorithm to handle the app theme
  // 1- Get the user preference [user_settings][display_theme].
  //
  // 2- If 'display_theme' doesn't equal to "system":
  //    2.a. The 'data-theme' will be equal the 'display_theme' value.
  //    2.b. The 'data-system-theme' will be 'false'.
  //
  // 3- If 'display_theme' equals to 'system':
  //    3.a. The 'data-system-theme' will be 'true'
  //    3.b. If browser doesn't support 'matchMedia', the 'data-theme' will be 'light'.
  //    3.c. If browser supports 'matchMedia', the 'data-theme' will be equals to user system settings.
  //
  // 4- If 'display_theme' equals to 'system' and browser supports 'matchMedia',
  //    Add event listener to detect if user changes his system settings.

  // ALGORITHM.1

  useEffect(() => {
    const body = document.body;
    const isSystemTheme = theme === "system";

    // ALGORITHM.2
    if (!isSystemTheme) {
      // ALGORITHM.2.a.
      body.dataset.theme = theme;
      // ALGORITHM.2.b.
      body.dataset.systemTheme = "false";
      return;
    }

    // ALGORITHM.3
    if (isSystemTheme) {
      // ALGORITHM.3.a.
      body.dataset.systemTheme = "true";

      // ALGORITHM.3.b.
      if (!window.matchMedia) {
        body.dataset.theme = "light";
      }

      // ALGORITHM.3.c.
      if (window.matchMedia) {
        let mqString = "(prefers-color-scheme:dark)";

        let userThemePreference = window.matchMedia(mqString).matches
          ? "dark"
          : "light";

        body.dataset.theme = userThemePreference;
      }
    }
  }, [theme]);

  useEffect(() => {
    function onChange(e) {
      const body = document.body;
      const isSystemTheme = body.dataset.systemTheme === "true";

      if (isSystemTheme) {
        let userThemePreference = e.target.matches ? "dark" : "light";
        body.dataset.theme = userThemePreference;
      }
    }

    // ALGORITHM.4
    if (window.matchMedia) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", onChange);
    }
  }, []);

  return <>{children}</>;
}

export default Theme;
