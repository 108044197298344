import { Suspense, lazy, memo, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { matchPath } from "react-router-dom";
import { useDispatch } from "react-redux";

import InitPageLoader from "components/shared/Loaders/InitPageLoader/InitPageLoader";
import SegmentPageTracking from "components/SegmentRegister/SegmentPageTracking";
import { handleAuthToken } from "store/slices/appSlice";
import { useLogin } from "components/Auth/hooks";

const AppPagePublic = lazy(() => import("pages/AppPagePublic/AppPagePublic"));

function AccessToken({ children, isDeckPage }) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [showPublicPage, setShowPublicPage] = useState(false);

  const [login] = useLogin();

  useEffect(() => {
    const getAccessToken = () => {
      getAccessTokenSilently().then(handleSucces).catch(handleError);

      function handleSucces(token) {
        setLoading(false);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        dispatch(handleAuthToken(`Bearer ${token}`));
      }

      function handleError(e) {
        if (e.error === "login_required" || e.error === "consent_required") {
          if (isDeckPage) {
            setLoading(false);
            setShowPublicPage(true);
            return;
          }

          login();
        }
      }
    };

    getAccessToken();
  }, []);

  if (loading) {
    return <InitPageLoader isPage={false} />;
  }

  if (showPublicPage) {
    return (
      <>
        <SegmentPageTracking />
        <Suspense fallback={<></>}>
          <AppPagePublic fileId={isDeckPage.params.deck_id} />
        </Suspense>
      </>
    );
  }

  return <>{children}</>;
}

const MemoAccessToken = memo(AccessToken);

function AccessTokenWrapper({ children }) {
  const { pathname } = window.location;
  let isDeckPage = matchPath("/decks/:deck_id", pathname);

  return (
    <>
      <MemoAccessToken isDeckPage={isDeckPage}>{children}</MemoAccessToken>
    </>
  );
}

export default AccessTokenWrapper;
