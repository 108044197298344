import { useEffect } from "react";

import { PADDLE_SANDBOX, PADDLE_VENDOR_ID } from "services/env";

export function usePaddle() {
  useEffect(() => {
    const loadScript = () => {
      return new Promise((resolve, reject) => {
        const paddleScript = document.createElement("script");
        paddleScript.src = "https://cdn.paddle.com/paddle/paddle.js";
        paddleScript.async = true;
        paddleScript.onload = resolve;
        paddleScript.onerror = reject;

        document.body.appendChild(paddleScript);
      });
    };

    const setupPaddle = async () => {
      try {
        await loadScript();

        const sandbox = PADDLE_SANDBOX === "true";
        const vendorId = Number(PADDLE_VENDOR_ID);

        if (sandbox) {
          window.Paddle.Environment.set("sandbox");
        }
        window.Paddle.Setup({ vendor: vendorId });
      } catch (error) {
        console.error("Error loading Paddle:", error);
      }
    };

    setupPaddle();

    return () => {
      // Clean up
    };
  }, []);

  return null;
}
