import axios from "axios";

import { qrunchinapiBaseUrl } from "services/constants";

// #region auditlogs
export const getAuditlogs = async (data) => {
  const { params } = data;

  const url = qrunchinapiBaseUrl + "/users/auth/auditlog";

  const result = await axios.get(url, { params });

  return result;
};
// #endregion auditlogs

// #region subscriptions
export const updateSubscription = async (data) => {
  const { subscription_id, req_body } = data;

  const url = qrunchinapiBaseUrl + `/subscriptions/${subscription_id}`;

  const result = await axios.patch(url, req_body);

  return result;
};
// #endregion subscriptions

// #region companies
export const updateCompany = async (data) => {
  const { company_id, req_body } = data;

  const url = qrunchinapiBaseUrl + `/companies/${company_id}`;

  const result = await axios.patch(url, req_body);

  return result;
};

export const companyAdditions = async (data) => {
  const { company_addition_id, req_body } = data;

  const url = qrunchinapiBaseUrl + `/company_additions/${company_addition_id}`;

  const result = await axios.patch(url, req_body);

  return result;
};
// #endregion companies

// #region users
export const getUserInfo = async (data) => {
  const { params } = data;

  const url = qrunchinapiBaseUrl + "/users/auth";

  const result = await axios.get(url, { params });

  return result;
};

export const getUsers = async () => {
  const url = qrunchinapiBaseUrl + "/users";

  const result = await axios.get(url);

  return result;
};

export const getUserPlan = async () => {
  const url = qrunchinapiBaseUrl + "/users/auth/user_plan";

  const result = await axios.get(url);

  return result;
};

export const inviteUsers = async (data) => {
  const { req_body } = data;

  const url = qrunchinapiBaseUrl + `/users?base_url=${window.location.origin}`;

  const result = await axios.put(url, req_body);

  return result;
};

export const inviteEmailCheck = async (data) => {
  const { params } = data;

  let url = qrunchinapiBaseUrl + "/users";

  const result = await axios.get(url, { params });

  return result;
};

export const updateUserInfo = async (data) => {
  const { req_body } = data;

  const url =
    qrunchinapiBaseUrl + `/users/onboarding?base_url=${window.location.origin}`;

  const result = await axios.put(url, req_body);

  return result;
};

export const updateUser = async (data) => {
  const { user_id, req_body } = data;

  const url = qrunchinapiBaseUrl + `/users/${user_id}`;

  const result = await axios.patch(url, req_body);

  return result;
};

export const getUserBySearch = async (data) => {
  const { params } = data;

  let url = qrunchinapiBaseUrl + "/users";

  const result = await axios.get(url, { params });

  return result;
};

export const resetPassword = async () => {
  const url =
    qrunchinapiBaseUrl +
    `/users/auth/password_reset?base_url=${window.location.origin}`;

  const result = await axios.put(url);

  return result;
};
// #endregion users

// #region permissions
export const createPermissions = async (data) => {
  const url =
    qrunchinapiBaseUrl + `/permissions?base_url=${window.location.origin}`;

  const result = await axios.post(url, data);

  return result;
};

export const deletePermission = async (data) => {
  const { permissionId, params } = data;

  const url = qrunchinapiBaseUrl + `/permissions/${permissionId}`;

  const result = await axios.delete(url, { params });

  return result;
};

export const updatePermission = async (data) => {
  const { permissionId, role } = data;

  const url = qrunchinapiBaseUrl + `/permissions/${permissionId}`;

  const req_body = {
    op: "replace",
    path: "/permission_role_name",
    value: role,
  };

  const result = await axios.patch(url, req_body);

  return result;
};
// #endregion permissions

// #region files
export const getFiles = async (data) => {
  const { params } = data;

  let url = qrunchinapiBaseUrl + "/files";

  const result = await axios.get(url, { params });

  return result;
};

export const getRecentFiles = async (data) => {
  const { params } = data;

  let url = qrunchinapiBaseUrl + `/files/recent`;

  const result = await axios.get(url, { params });

  return result;
};

export const getFilesBySearch = async (data) => {
  const { params } = data;

  let url = qrunchinapiBaseUrl + `/files`;

  const result = await axios.get(url, { params });

  return result;
};

export const getStarredFiles = async () => {
  let url = qrunchinapiBaseUrl + "/files/starred";

  const result = await axios.get(url);

  return result;
};

export const getTrashedFiles = async () => {
  let url = qrunchinapiBaseUrl + "/files/trashed";

  const result = await axios.get(url);

  return result;
};

export const deleteMultiFiles = async (data) => {
  const { files_list, value } = data;

  const url = qrunchinapiBaseUrl + `/files`;

  const req_body = {
    op: "replace",
    path: "/multiple/trashed",
    boolean_value: value,
    files_list,
  };

  const result = await axios.patch(url, req_body);

  return result;
};

export const deleteFilePermenantly = async (data) => {
  const { value, files_list, params } = data;

  const url = qrunchinapiBaseUrl + `/files`;

  const req_body = {
    op: "replace",
    path: "/multiple/trashed_permanently",
    boolean_value: value,
    files_list,
  };

  const result = await axios.patch(url, req_body, { params });

  return result;
};

export const restoreFiles = async (data) => {
  const { files_list, params } = data;

  const url = qrunchinapiBaseUrl + `/files?trashed=true`;

  const req_body = {
    op: "replace",
    path: "/multiple/trashed",
    boolean_value: false,
    files_list,
  };

  const result = await axios.patch(url, req_body, { params });

  return result;
};

export const getFile = async (data) => {
  const { fileId, params } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}`;

  const result = await axios.get(url, { params });

  return result;
};

export const patchFileName = async (data) => {
  const { fileId, value } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}`;

  const req_body = {
    op: "replace",
    path: "/file_name",
    value,
  };

  const result = axios.patch(url, req_body);

  return result;
};

export const addToFavorites = async (data) => {
  const { fileId } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/stars`;

  const result = await axios.put(url);

  return result;
};

export const removeFromFavorites = async (data) => {
  const { fileId } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/stars`;

  const req_body = { op: "replace", path: "/trashed", boolean_value: true };

  const result = await axios.patch(url, req_body);

  return result;
};

export const duplicateFile = async (data) => {
  const { fileId } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/copy`;

  const result = await axios.post(url);

  return result;
};

export const updateFilePrivacy = async (data) => {
  const { fileId, value } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}`;

  const req_body = {
    op: "replace",
    path: "/private",
    boolean_value: value,
  };

  const result = await axios.patch(url, req_body);

  return result;
};

// #endregion files

// #region Deck APIs
export const createDeck = async (data) => {
  const {
    parentFolderId,
    datasetId,
    file_name,
    deckUpdate,
    deckMode = "notebook",
  } = data;
  let url = qrunchinapiBaseUrl + "/decks";

  const params = { deck_mode: deckMode };
  const req_body = { file_name };

  if (datasetId) {
    params.include_dataset = true;
    req_body.dataset_file_id = datasetId;
  }

  if (parentFolderId) {
    params.parent_folder_id = parentFolderId;
  }

  if (deckUpdate) {
    params.deck_update = true;
  }

  const result = await axios.post(url, req_body, { params });

  return result;
};

export const createChat = async (data) => {
  const { parentFolderId, datasetId, fileName, deckUpdate } = data;
  let url = qrunchinapiBaseUrl + "/decks";

  const params = { deck_mode: "chat" };
  const req_body = { file_name: fileName };

  if (datasetId) {
    params.include_dataset = true;
    req_body.dataset_file_id = datasetId;
  }

  if (parentFolderId) {
    params.parent_folder_id = parentFolderId;
  }

  if (deckUpdate) {
    params.deck_update = true;
  }

  const result = await axios.post(url, req_body, { params });

  return result;
};

export const updateDeckDatatset = async (data) => {
  const { req_body, params } = data;

  let url = qrunchinapiBaseUrl + "/decks";

  const result = await axios.post(url, req_body, { params });

  return result;
};

export const checkDatasetReplacementCompatibility = async (data) => {
  const { deckId, params } = data;

  let url = qrunchinapiBaseUrl + `/files/${deckId}/deck/dataset_updatability`;

  const result = await axios.get(url, { params });

  return result;
};

// #endregion Deck APIs

// #region Dataset APIs
export const createDataset = async (data) => {
  const { file, params, onUploadProgress } = data;

  let url = qrunchinapiBaseUrl + "/datasets/source_file";

  const newFile = new FormData();
  newFile.append("source_file", file);

  const config = {
    params,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress,
  };

  const result = await axios.post(url, newFile, config);

  return result;
};

export const getAnalysisSuggestions = async (data) => {
  const { deckFileId, params } = data;

  const url = qrunchinapiBaseUrl + `/files/${deckFileId}/deck/query`;

  const result = await axios.get(url, { params });

  return result;
};

export const getDatasetContent = async (data) => {
  const { fileId, params } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/dataset/datacontent`;

  const result = await axios.get(url, { params });

  return result;
};

export const exportDatasetContent = async (data) => {
  const { fileId, params } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/dataset/datacontent`;

  const result = await axios.get(url, { responseType: "blob", params });

  return result;
};

export const validateDatasetAggregation = async (data) => {
  const { fileId, components, params } = data;

  const url =
    qrunchinapiBaseUrl + `/files/${fileId}/dataset/dataset_aggregations`;

  const req_body = {
    aggregation_type: "formula",
    components,
  };

  const result = await axios.post(url, req_body, { params });

  return result;
};

export const validateDatasetAggregationName = async (data) => {
  const {
    fileId,
    components,
    aggregation_display_name,
    aggregation_filters,
    params,
  } = data;

  const url =
    qrunchinapiBaseUrl + `/files/${fileId}/dataset/dataset_aggregations`;

  const req_body = {
    aggregation_type: "formula",
    components,
    aggregation_display_name,
  };

  if (aggregation_filters) {
    req_body.aggregation_filters = aggregation_filters;
  }

  const result = await axios.post(url, req_body, { params });

  return result;
};

export const validateDatasetItemName = async (data) => {
  const { fileId, params } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/dataset/available_name`;

  const result = await axios.get(url, { params });

  return result;
};

export const updateDatasetItemName = async (data) => {
  const { fileId, value, aggregation_id, detail_id } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/dataset`;

  const req_body = {
    op: "replace",
    value,
  };

  if (aggregation_id) {
    req_body.aggregation_id = aggregation_id;
    req_body.path = "/dataset_aggregations/aggregation_display_name_value";
  }

  if (detail_id) {
    req_body.detail_id = detail_id;
    req_body.path = "/dataset_details/display_name";
  }

  const result = await axios.patch(url, req_body);

  return result;
};

export const createDatasetAggregation = async (data) => {
  const { fileId, components, aggregation_display_name, aggregation_filters } =
    data;

  const url =
    qrunchinapiBaseUrl + `/files/${fileId}/dataset/dataset_aggregations`;

  const req_body = {
    aggregation_type: "formula",
    components,
    aggregation_display_name,
  };

  if (aggregation_filters) {
    req_body.aggregation_filters = aggregation_filters;
  }

  const result = await axios.post(url, req_body);

  return result;
};

export const getAttributeValuesOptions = async (data) => {
  const { fileId, params } = data;

  let url =
    qrunchinapiBaseUrl + `/files/${fileId}/dataset/attribute_value_options`;

  const result = await axios.get(url, { params });

  return result;
};

export const changeItemPriority = async (data) => {
  const { fileId, detail_id, aggregation_id, integer_value } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/dataset`;

  const req_body = {
    op: "replace",
    integer_value,
  };

  if (aggregation_id) {
    req_body.aggregation_id = aggregation_id;
    req_body.path = "/dataset_aggregations/display_priority";
  }

  if (detail_id) {
    req_body.detail_id = detail_id;
    req_body.path = "/dataset_details/display_priority";
  }

  const result = await axios.patch(url, req_body);

  return result;
};

export const changeItemHide = async (data) => {
  const { fileId, detail_id, aggregation_id, boolean_value } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/dataset`;

  const req_body = {
    op: "replace",
    boolean_value,
  };

  if (aggregation_id) {
    req_body.aggregation_id = aggregation_id;
    req_body.path = "/dataset_aggregations/hidden";
  }

  if (detail_id) {
    req_body.detail_id = detail_id;
    req_body.path = "/dataset_details/hidden";
  }

  const result = await axios.patch(url, req_body);

  return result;
};

export const validateDataset = async (data) => {
  const { fileId, boolean_value } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/dataset`;

  const req_body = {
    op: "replace",
    path: "/validated",
    boolean_value,
  };

  const result = await axios.patch(url, req_body);

  return result;
};

export const updateDatasetItemDef = async (data) => {
  const { fileId, req_body } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/dataset`;

  const result = await axios.patch(url, req_body);

  return result;
};

export const deleteMetric = async (data) => {
  const { fileId, boolean_value, aggregations_list } = data;

  const url = qrunchinapiBaseUrl + `/files/${fileId}/dataset`;

  const req_body = {
    op: "replace",
    path: "/dataset_aggregations/multiple/trashed",
    boolean_value,
    aggregations_list,
  };

  const result = await axios.patch(url, req_body);

  return result;
};

export const createDatasetSQL = async (data) => {
  const { database_uri, table_name, file_name } = data;

  const url = qrunchinapiBaseUrl + `/datasets/source_sql_table`;

  const req_body = {
    database_uri,
    table_name,
    file_name,
  };

  const result = await axios.post(url, req_body);

  return result;
};
// #endregion Dataset APIs

// #region Analyses APIs
export const getRelatedAnalyses = async (data) => {
  const { deck_item_id, params } = data;

  let url = qrunchinapiBaseUrl + `/deck_items/${deck_item_id}/derived`;

  const result = await axios.get(url, { params });

  return result;
};
// #endregion Analyses APIs

export const getAnalysisValueInfo = async (data) => {
  const { deckItemId, params } = data;

  const url =
    qrunchinapiBaseUrl + `/deck_items/${deckItemId}/value_information`;

  const result = axios.get(url, { params });

  return result;
};

// #region convert image APIs
export const convertHighchartsSvg = async (data) => {
  const { token, source } = data;
  const formData = new FormData();
  formData.append("source_file", source);

  const url = token
    ? "/images?input_format=svg&output_format=png&dpi=300&output_width=7000&output_height=3500"
    : "/public/images?input_format=svg&output_format=png&dpi=300&output_width=7000&output_height=3500";

  const options = {
    method: "post",
    url: qrunchinapiBaseUrl + url,
    data: formData,
    headers: { Authorization: token, "Content-Type": "multipart/form-data" },
    responseType: "blob",
  };

  const result = await axios(options).then((response) => response);

  return result;
};
// #endregion convert image APIs

// #region MFA
export const enrollMFAInvitation = async () => {
  const url =
    qrunchinapiBaseUrl +
    `/users/auth/user_mfa/enrollment?base_url=${window.location.origin}`;

  const result = await axios.put(url);

  return result;
};

export const disableMFA = async () => {
  const url =
    qrunchinapiBaseUrl +
    `/users/auth/user_mfa/disabled?base_url=${window.location.origin}`;

  const result = await axios.put(url);

  return result;
};
// #endregion MFA

const apis = {
  getFile,
  createDataset,
  patchFileName,
  createPermissions,
  updatePermission,
  deletePermission,
  addToFavorites,
  removeFromFavorites,
  updateFilePrivacy,
  getFiles,
  getStarredFiles,
  getTrashedFiles,
  deleteFilePermenantly,
  restoreFiles,
  createDatasetAggregation,
  deleteMultiFiles,
  changeItemPriority,
  changeItemHide,
  updateDatasetItemName,
  validateDataset,
  deleteMetric,
  updateDatasetItemDef,
  updateUser,
  enrollMFAInvitation,
  convertHighchartsSvg,
  createChat,
};

export default apis;
