import { LARGE_SCREEN_WIDTH, SMALL_SCREEN_WIDTH } from "services/constants";

export const getNameInitials = (fullName) => {
  if (!fullName) return "";

  const names = fullName.trim().split(" ");

  if (names.length === 1) {
    return names[0][0].toUpperCase();
  }

  return names[0][0].toUpperCase() + names[names.length - 1][0].toUpperCase();
};

export function isEllipsisActive(elem) {
  return (
    elem.clientWidth < elem.scrollWidth || elem.clientHeight < elem.scrollHeight
  );
}

export function roundNumber(num, decimals) {
  const value = Math.abs(num);

  if (!decimals && decimals !== 0) {
    return value;
  }

  return value.toFixed(decimals);
}

export function truncateText(text, numbers) {
  if (text.length < numbers) {
    return text;
  }

  return text.substring(0, numbers) + "...";
}

export function getSearchParam(search, param) {
  return new URLSearchParams(search).get(param);
}

export function isLargeScrFunc() {
  return window.innerWidth > LARGE_SCREEN_WIDTH;
}

export function isSmallScrFunc() {
  return window.innerWidth <= SMALL_SCREEN_WIDTH;
}

function getPosition(e) {
  let posx = 0;
  let posy = 0;

  if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  } else if (e.clientX || e.clientY) {
    posx =
      e.clientX +
      document.body.scrollLeft +
      document.documentElement.scrollLeft;
    posy =
      e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }

  return {
    x: posx,
    y: posy,
  };
}

export function positionMenu(e, id) {
  const menu = document.getElementById(id);

  let clickCoords = getPosition(e);
  let clickCoordsX = clickCoords.x;
  let clickCoordsY = clickCoords.y;

  let menuWidth = menu.offsetWidth + 20;
  let menuHeight = menu.offsetHeight + 4;

  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;

  if (windowWidth - clickCoordsX < menuWidth) {
    menu.style.left = windowWidth - menuWidth + "px";
  } else {
    menu.style.left = clickCoordsX + "px";
  }

  if (windowHeight - clickCoordsY < menuHeight) {
    menu.style.top = windowHeight - menuHeight + "px";
  } else {
    menu.style.top = clickCoordsY + "px";
  }
}

export function isNullOrUndefined(value) {
  return value === undefined || value === null;
}

export function addZeroWidthSpacetoText(text, char) {
  text = text.replaceAll(char, "/\u200B");
  return text;
}

export function convertTextToSlateNodes(text) {
  const nodes = text.split("\n").map((line) => ({
    type: "paragraph",
    children: [{ text: line }],
  }));

  return nodes;
}

export function getFileType(file) {
  const deckMode = file.file_deck?.deck_mode;
  let fileType = file.file_type;

  if (deckMode === "chat") {
    fileType = "chat";
  }

  if (deckMode === "notebook") {
    fileType = "notebook";
  }

  return fileType;
}

// https://web.dev/articles/optimize-long-tasks
export function yieldToMainThread() {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
}
