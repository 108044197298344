import { Suspense } from "react";
import { useSelector } from "react-redux";

import ErrorComponent from "components/shared/ErrorComponent/ErrorComponent";
import Logo from "components/shared/Logo/Logo";

import "./style.css";

function DeactivatedAccountPage() {
  const { isUserDeactivated } = useSelector(({ app }) => app);

  const code = isUserDeactivated.code;
  const status = isUserDeactivated.status;

  return (
    <div className="deactivated-page">
      <div className="deactivated-topbar">
        <Suspense fallback={<></>}>
          <Logo isIcon />
        </Suspense>
      </div>

      <main className="deactivated-main">
        <ErrorComponent code={code} status={status} />
      </main>
    </div>
  );
}

export default DeactivatedAccountPage;
