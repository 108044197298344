import { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { LARGE_SCREEN_WIDTH, SMALL_SCREEN_WIDTH } from "services/constants";
import { useSelector } from "react-redux";
import { selectIsOverdataTheme } from "store/slices/appSlice";

export const useAccessTokenHooks = (redirectToDeckPage) => {
  const [accessToken, setAccessToken] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      const accessToken = await getAccessTokenSilently().catch((e) => {
        if (e.error === "login_required" || e.error === "consent_required") {
          redirectToDeckPage();
        }
      });
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      setAccessToken(accessToken);
    };
    getAccessToken();
  }, [getAccessTokenSilently]);

  return accessToken;
};

export const useOnClickOutside = (ref, callBack) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      callBack(event);
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, callBack]);
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

// #region window resize hooks
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
};

export const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowHeight;
};

export const useScreenBreakpoints = () => {
  const [isLargeScr, setIsLargeScr] = useState(
    window.innerWidth > LARGE_SCREEN_WIDTH
  );
  const [isSmallScr, setIsSmallScr] = useState(
    window.innerWidth <= SMALL_SCREEN_WIDTH
  );

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;

      setIsLargeScr(width > LARGE_SCREEN_WIDTH);
      setIsSmallScr(width <= SMALL_SCREEN_WIDTH);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { isLargeScr, isSmallScr };
};

export const useVisualViewport = () => {
  const [visualViewport, setVisualViewport] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    offsetTop: 0,
    pageTop: 0,
  });
  useEffect(() => {
    if (!window.visualViewport) return;

    window.visualViewport.addEventListener("resize", handleViewPort);

    function handleViewPort() {
      setVisualViewport({
        width: window.visualViewport.width,
        height: window.visualViewport.height,
        offsetTop: window.visualViewport.offsetTop,
        pageTop: window.visualViewport.pageTop,
      });
    }

    window.visualViewport.addEventListener("scroll", handleViewPort);

    return () => {
      window.visualViewport.removeEventListener("resize", handleViewPort);
      window.visualViewport.removeEventListener("scroll", handleViewPort);
    };
  }, []);

  return visualViewport;
};
// #endregion window resize hooks

export function useIsInit() {
  const isInit = useRef(false);
  useEffect(() => {
    if (!isInit.current) {
      isInit.current = true;
    }
  });

  return isInit;
}

export function useAppCaption() {
  const isOverdataTheme = useSelector(selectIsOverdataTheme);

  return isOverdataTheme ? "Overdata" : "qrunchin";
}

const DEFAULT_CONFIG = {
  timeout: 0,
  ignoreInitialCall: true,
};
export function useDebouncedEffect(callback, config, deps = []) {
  let currentConfig;
  if (typeof config === "object") {
    currentConfig = {
      ...DEFAULT_CONFIG,
      ...config,
    };
  } else {
    currentConfig = {
      ...DEFAULT_CONFIG,
      timeout: config,
    };
  }
  const { timeout, ignoreInitialCall } = currentConfig;
  const data = useRef({ firstTime: true });
  useEffect(() => {
    const { firstTime, clearFunc } = data.current;

    if (firstTime && ignoreInitialCall) {
      data.current.firstTime = false;
      return;
    }

    const handler = setTimeout(() => {
      if (clearFunc && typeof clearFunc === "function") {
        clearFunc();
      }
      data.current.clearFunc = callback();
    }, timeout);

    return () => {
      clearTimeout(handler);
    };
  }, [timeout, ...deps]);
}

const hooks = {
  useAccessTokenHooks,
  useOnClickOutside,
  useInterval,
  useWindowSize,
  useWindowHeight,
  useScreenBreakpoints,
  useVisualViewport,
  useIsInit,
  useAppCaption,
  useDebouncedEffect,
};

export default hooks;
