// All history states ends with "HS"

export const APP_SIDEBAR_HS = "APP_SIDEBAR_HS";

export const ONBOARDING_SCRHS = "ONBOARDING_SCRHS";

export const COMP_SIZE_DROPDOWN_HS = "COMP_SIZE_DROPDOWN_HS";

export const SUBSCR_LIMIT_STATE_HS = "SUBSCR_LIMIT_STATE_HS";

export const FILE_SELECTION_HS = "FILE_SELECTION_HS";

// #region Dataset Information Component
// All history states in Dataset Information Component ends with "DIHS"
export const SPLIT_BTN_OPTS_DIHS = "SPLIT_BTN_OPTS_DIHS";

export const CONFIRMATION_DIHS = "CONFIRMATION_DIHS";

export const SHEETS_DIHS = "SHEETS_DIHS";

export const DELETE_DIHS = "DELETE_DIHS";

export const ERROR_DIHS = "ERROR_DIHS";

export const REMOVE_DIHS = "REMOVE_DIHS";
// #endregion Dataset Information Component

// #region Deck Page
// All history states in Deck Page ends with "DEHS"
export const DELET_SCR_DEHS = "DELET_SCR_DEHS";

export const EXPORT_OPTS_DEHS = "EXPORT_OPTS_DEHS";

export const TURNINTO_OPTS_DEHS = "TURNINTO_OPTS_DEHS";

export const EDIT_MENU_DEHS = "EDIT_MENU_DEHS";

export const ENLARGED_CHART_DEHS = "ENLARGED_CHART_DEHS";

export const DECK_ITEM_OPTS_DEHS = "DECK_ITEM_OPTS_DEHS";

export const DECK_EXPORT_SCR_DEHS = "DECK_EXPORT_SCR_DEHS";

export const TRASHED_DATASET_SCR_DEHS = "TRASHED_DATASET_SCR_DEHS";

export const TRASHED_METRICS_SCR_DEHS = "TRASHED_METRICS_SCR_DEHS";

export const ADD_ITEM_OPT_DEHS = "ADD_ITEM_OPT_DEHS";

export const VALUE_AUDIT_DEHS = "VALUE_AUDIT_DEHS";

export const METHOD_SELECT_DEHS = "METHOD_SELECT_DEHS";

export const TURNOFF_MFA_DEHS = "TURNOFF_MFA_DEHS";

export const CHAT_ITEM_OPTS_DEHS = "CHAT_ITEM_OPTS_DEHS";
// #endregion Deck Page

// #region Chat Page
// All history states in Chat Page ends with "CHHS"
export const ADD_DATA_CHHS = "ADD_DATA_CHHS";

export const BEST_PROMP_CHHS = "BEST_PROMP_CHHS";

export const DELET_SCR_CHHS = "DELET_SCR_CHHS";
// #endregion Chat Page

// #region Settings Page
// All history states in Settings Page ends with "SETHS"
export const COMP_SIZE_DROPDOWN_SETHS = "COMP_SIZE_DROPDOWN_SETHS";

export const APPEAR_OPTS_SETHS = "APPEAR_OPTS_SETHS";

export const MEMB_OPTS_SETHS = "MEMB_OPTS_SETHS";
// #endregion Settings Page

// #region Favorites Page
// All history states in Favorites Page ends with "FAVHS"
export const FILE_DELET_FAVHS = "FILE_DELET_FAVHS";

export const FILE_OPTS_FAVHS = "FILE_OPTS_FAVHS";

export const FILE_RENAME_FAVHS = "FILE_RENAME_FAVHS";
// #endregion Favorites Page

// #region files Page
// All history states in files Page ends with "FIHS"
export const FILE_DELET_FIHS = "FILE_DELET_FIHS";

export const FILE_OPTS_FIHS = "FILE_OPTS_FIHS";

export const ROOT_OPTS_FIHS = "ROOT_OPTS_FIHS";

export const FILE_RENAME_FIHS = "FILE_RENAME_FIHS";

export const FILE_MOVE_FIHS = "FILE_MOVE_FIHS";
// #endregion files Page

// #region Trash Page
// All history states in Trash Page ends with "TRHS"
export const FILE_DELET_TRHS = "FILE_DELET_TRHS";

export const FILE_OPTS_TRHS = "FILE_OPTS_TRHS";

export const FILE_RESTORE_TRHS = "FILE_RESTORE_TRHS";
// #endregion Trash Page

// #region Settings Page
// All history states in Settings Page ends with "SEHS"
export const PASS_CONFIRM_SEHS = "showPassConfirmSEHS";

export const ACCEPT_INVITE_SEHS = "ACCEPT_INVITE_SEHS";

export const ACTIVE_SCR_SEHS = "ACTIVE_SCR_SEHS";

export const DELETE_SCR_SEHS = "DELETE_SCR_SEHS";

export const PLAN_CONFIRM_SEHS = "PLAN_CONFIRM_SEHS";

export const ORG_COLLECT_SEHS = "ORG_COLLECT_SEHS";
// #endregion Settings Page
