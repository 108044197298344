import { createAsyncThunk } from "@reduxjs/toolkit";

import apis from "services/APIs";

export const getTrashedFilesThunk = createAsyncThunk(
  "trash/getTrashedFiles",
  async () => {
    const response = await apis.getTrashedFiles();

    return response.data;
  },
  { serializeError }
);

export const deleteFilePermenantlyThunk = createAsyncThunk(
  "trash/deleteFilePermenantly",
  async (data) => {
    const response = await apis.deleteFilePermenantly(data);

    return response.data;
  },
  { serializeError }
);

export const restoreFilesThunk = createAsyncThunk(
  "trash/restoreFiles",
  async (data) => {
    const response = await apis.restoreFiles(data);

    return response.data;
  },
  { serializeError }
);

function serializeError(e) {
  if (!e.response) {
    return { response: { status: 400 } };
  }
  const { data, status } = e.response;
  return { response: { data, status } };
}
