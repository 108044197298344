import { apiSlice } from "store/api/apiSlice";

export const chatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChat: builder.query({
      queryFn: () => ({ data: null }),
    }),

    addDatasetToChat: builder.mutation({
      query: (arg) => {
        const { chatId, datasetId } = arg;

        const url = `/files/${chatId}/deck`;

        const body = {
          op: "add",
          path: "/deck_dataset_files",
          dataset_file_id: datasetId,
          replacement_check: false,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = chatApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getChat", undefined, (draft) => {
              Object.assign(draft, data);
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    removeDatasetFromChat: builder.mutation({
      query: (arg) => {
        const { fileId, datasetId } = arg;

        const url = `/files/${fileId}/deck`;

        const body = {
          op: "remove",
          path: "/deck_dataset_files",
          dataset_file_id: datasetId,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { datasetId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = chatApiSlice.util;
        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getChat", undefined, (draft) => {
              const { date_last_modified, user_last_modified } = data;
              draft.date_last_modified = date_last_modified;
              draft.user_last_modified = user_last_modified;

              draft.file_deck.deck_dataset_file_count -= 1;

              draft.file_deck.deck_dataset_files =
                draft.file_deck.deck_dataset_files.filter((dataset) => {
                  const { dataset_file_id } = dataset;
                  return dataset_file_id !== datasetId;
                });
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),

    addToFavoritesCP: builder.mutation({
      query: (arg) => {
        const { fileId } = arg;
        return { url: `/files/${fileId}/stars`, method: "PUT" };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = chatApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        // TODO: update the response of API in backend
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getChat", undefined, (draft) => {
              const { file_date_last_modified, file_user_last_modified } = data;

              draft.date_last_modified = file_date_last_modified;
              draft.user_last_modified = file_user_last_modified;
              draft.starred_by_user_requesting = true;
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    removeFromFavoritesCP: builder.mutation({
      query: (arg) => {
        const { fileId } = arg;
        const body = { op: "replace", path: "/trashed", boolean_value: true };

        return { url: `/files/${fileId}/stars`, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = chatApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        // TODO: update the response of API in backend, return last_modified_date
        function pessimisticUpdate(res) {
          const { data } = res;

          dispatch(
            updateQueryData("getChat", undefined, (draft) => {
              const { file_date_last_modified, file_user_last_modified } = data;

              draft.date_last_modified = file_date_last_modified;
              draft.user_last_modified = file_user_last_modified;
              draft.starred_by_user_requesting = false;
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    lockChat: builder.mutation({
      query: (arg) => {
        const { fileId, boolean_value } = arg;

        const url = `/files/${fileId}`;

        const body = { op: "replace", path: "/locked", boolean_value };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = chatApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        // TODO: update the response of API in backend, return last_modified_date
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getChat", undefined, (draft) => {
              draft.date_last_modified = data.date_last_modified;
              draft.user_last_modified = data.user_last_modified;

              draft.user_locked = data.user_locked;

              draft.locked = data.locked;
              draft.user_requesting_capabilities =
                data.user_requesting_capabilities;
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateChatPrivacy: builder.mutation({
      query: (arg) => {
        const { fileId, value } = arg;

        const url = `/files/${fileId}`;

        const body = { op: "replace", path: "/private", boolean_value: value };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = chatApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        // TODO: update the response of API in backend, return last_modified_date
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getChat", undefined, (draft) => {
              draft.date_last_modified = data.date_last_modified;
              draft.user_last_modified = data.user_last_modified;
              draft.private = data.private;
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),

    // #region permissions
    updatePermissionCP: builder.mutation({
      query: (arg) => {
        const { permissionId, role } = arg;

        const url = `/permissions/${permissionId}`;

        const body = {
          op: "replace",
          path: "/permission_role_name",
          value: role,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = chatApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;

          const {
            permission_role_name,
            permission_user,
            permission_id,
            date_last_modified,
          } = data;

          dispatch(
            updateQueryData("getChat", undefined, (draft) => {
              draft.date_last_modified = date_last_modified;
              draft.file_permissions = draft.file_permissions.map(
                (permission) => {
                  if (permission_id === permission.permission_id) {
                    return {
                      permission_role_name,
                      permission_user,
                      permission_id,
                    };
                  } else {
                    return permission;
                  }
                }
              );
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    deletePermissionCP: builder.mutation({
      query: (arg) => {
        const { permissionId, params } = arg;

        const url = `/permissions/${permissionId}`;

        return { url, method: "DELETE", params };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { permissionId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = chatApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;

          const {
            permission_file_date_last_modified,
            permission_file_user_last_modified,
          } = data;

          dispatch(
            updateQueryData("getChat", undefined, (draft) => {
              draft.date_last_modified = permission_file_date_last_modified;
              draft.user_last_modified = permission_file_user_last_modified;
              draft.file_permission_count -= 1;
              draft.file_permissions = draft.file_permissions.filter(
                (permission) => permission.permission_id !== permissionId
              );
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    // #endregion permissions
  }),
});

export const {
  useGetChatQuery,
  useAddDatasetToChatMutation,
  useRemoveDatasetFromChatMutation,
  useAddToFavoritesCPMutation,
  useRemoveFromFavoritesCPMutation,
  useLockChatMutation,
  useUpdateChatPrivacyMutation,
  useDeletePermissionCPMutation,
  useUpdatePermissionCPMutation,
} = chatApiSlice;
