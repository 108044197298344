import { createSlice } from "@reduxjs/toolkit";

// Actions
import actions from "store/slices/filesSlice/actions";

const initialState = {
  pageLoader: false,
  isSharedView: false,
  fileMove: {
    show: false,
  },
  selectedFilesState: {
    files: [],
    lastSelectedFile: null,
  },
  fileDeletionScrState: { show: false },
  drag: {
    isDraggingActive: false,
  },

  select: {
    filesIds: [],
    lastSelectedFileId: null,
  },
  rename: { show: false, fileId: null },
};

const fileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    resetFilesSlice: () => initialState,
    ...actions,
  },
});

export const {
  resetFilesSlice,
  handleFileMoveScreen,
  updatePageLoader,
  updateSelectedFiles,
  updateIsSharedView,
  handleFileDeletionScr,
  updateFileRename,
  onDragStart,
  updateIsDraggingActive,
  updateSelect,
} = fileSlice.actions;

export default fileSlice.reducer;
