const initialState = {
  fileSelect: false,
  showChatEditionMenu: false,
  showChatDeletion: false,
  instantEdit: {
    show: false,
    deckItemId: null,
    biqaDeckItemId: null,
    datasetId: null,
  },
};

export default initialState;
