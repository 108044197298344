import { createSlice } from "@reduxjs/toolkit";

import initialState from "store/slices/datasetSlice/initialState";
import reducers from "store/slices/datasetSlice/reducers";

const datasetSlice = createSlice({
  name: "dataset",
  initialState,
  reducers,
});

export const {
  updateDataset,
  updateDatasetProcess,
  updateDatasetUpload,
  updateUploadedFile,
  updateDatasetError,
  updateDatasetConfirm,
  updateDatasetSourceType,
  updateDatasetSheets,
  updateDatasetDelete,
} = datasetSlice.actions;

export default datasetSlice.reducer;
