function updateFileSelect(state, action) {
  state.fileSelect = action.payload;
}

function updateShowChatEditionMenu(state, action) {
  state.showChatEditionMenu = action.payload;
}

function updateInstEdit(state, action) {
  state.instantEdit = action.payload;
}

function updateInstEditDeckItemId(state, action) {
  state.instantEdit.deckItemId = action.payload;
}

export function updateShowChatDeletion(state, action) {
  state.showChatDeletion = action.payload;
}

const reducers = {
  updateFileSelect,
  updateShowChatEditionMenu,
  updateInstEditDeckItemId,
  updateInstEdit,
  updateShowChatDeletion,
};

export default reducers;
