import SegmentPageTracking from "components/SegmentRegister/SegmentPageTracking";
import SegmentUserIdentify from "components/SegmentRegister/SegmentUserIdentify";
import SegmentGroupAssociation from "components/SegmentRegister/SegmentGroupAssociation";

function SegmentRegister() {
  return (
    <>
      <SegmentUserIdentify />
      <SegmentPageTracking />
      <SegmentGroupAssociation />
    </>
  );
}

export default SegmentRegister;
