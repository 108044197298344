import { createSelector } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";
import { DELET_SCR_CHHS } from "services/history_states";
import { chatApiSlice } from "store/api/chatApiSlice";
import { selectHistoryStateProp } from "../appSlice";

// Get data from cache
const selectChatResult = chatApiSlice.endpoints.getChat.select();
export const selectChatData = createSelector(
  selectChatResult,
  (chat) => chat.data
);

export const selectChatPrivacy = createSelector(
  selectChatData,
  (chat) => chat.private
);

export const selectChatIsStarred = createSelector(selectChatData, (chat) => {
  return chat.starred_by_user_requesting;
});

export const selectChatLocked = createSelector(selectChatData, (chat) => {
  return chat.locked;
});

export const selectChatIsSystem = createSelector(selectChatData, (chat) => {
  return chat.is_system;
});

export const selectChatFileId = createSelector(selectChatData, (chat) => {
  return chat.file_id;
});

export const selectChatName = createSelector(selectChatData, (chat) => {
  return chat?.file_name;
});

export const selectChatDatasetCount = createSelector(selectChatData, (chat) => {
  return chat?.file_deck.deck_dataset_file_count;
});

export const selectChatDatasets = createSelector(selectChatData, (chat) => {
  return chat.file_deck.deck_dataset_files;
});

export const selectIsChatHasDataset = createSelector(selectChatData, (chat) => {
  return chat?.file_deck.deck_dataset_file_count > 0;
});

export const selectChatItemCount = createSelector(selectChatData, (chat) => {
  return chat?.file_deck.deck_item_count;
});

// The chat page is empty if there are no datasets or items.
export const selectIsEmpty = createSelector(
  selectChatDatasetCount,
  selectChatItemCount,
  (d, i) => d === 0 && i === 0
);

// The chat page is mid-empty if there are datasets and no items.
export const selectIsMidEmpty = createSelector(
  selectChatDatasetCount,
  selectChatItemCount,
  (d, i) => d > 0 && i === 0
);

// The chat page is mid-empty if there are datasets and items.
export const selectIsFull = createSelector(
  selectChatDatasetCount,
  selectChatItemCount,
  (d, i) => d > 0 && i > 0
);

// Select all chat items ids
export const selectItemsIds = createSelector(selectChatData, (chat) => {
  return chat.deck_items.ids;
});
export const selectItemsEntities = createSelector(selectChatData, (chat) => {
  return chat.deck_items.entities;
});

// Select chat embedded facts by Id
export const selectEmbeddFactsEntities = createSelector(
  selectChatData,
  (chat) => chat.embedded_facts.entities
);

export const selectChatDateLastModified = createSelector(
  selectChatData,
  (chat) => chat.date_last_modified
);

export const selectChatUserLastModified = createSelector(
  selectChatData,
  (chat) => chat.user_last_modified
);

export const selectChatDateCreated = createSelector(
  selectChatData,
  (chat) => chat.date_created
);

export const selectChatUserCreated = createSelector(
  selectChatData,
  (chat) => chat.user_created
);

export const selectFileMostRecentInteractions = createSelector(
  selectChatData,
  (chat) => chat.file_most_recent_interactions
);

// Select chat item by Id
export const selectItemById = createSelector(
  selectChatData,
  (_, id) => id,
  (chat, id) => chat.deck_items.entities[id]
);

// Select chat item type by Id
export const selectItemIsCompleted = createSelector(selectItemById, (item) => {
  return item.is_completed;
});

export const selectItemQuestion = createSelector(selectItemById, (item) => {
  return item.bi_qa.question;
});

export const selectItemAnswer = createSelector(selectItemById, (item) => {
  const { answers, display_index } = item.bi_qa;

  return answers[display_index];
});

const selectUserRequestCaps = createSelector(
  selectChatData,
  (chat) => chat?.user_requesting_capabilities || chat?.public_capabilities
);

export const selectUserReqCapabCanEdit = createSelector(
  selectUserRequestCaps,
  (caps) => caps?.can_edit
);
export const selectUserReqCapabCanLockUnlock = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_lock_unlock
);
export const selectUserReqCapabCanTrashUntrash = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_trash_untrash
);
export const selectUserReqCapabCanRename = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_rename
);
export const selectUserReqCapabCanShare = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_share
);

// #region instant edition analysis
export const selectInstantEditShow = ({ chat }) => chat.instantEdit.show;
export const selectInstantEditBiqaDeckItemId = ({ chat }) =>
  chat.instantEdit.biqaDeckItemId;
export const selectInstantEditDeckItemId = ({ chat }) =>
  chat.instantEdit.deckItemId;
export const selectInstantEditDatasetId = ({ chat }) =>
  chat.instantEdit.datasetId;
export const selectIsInstantEditItemId = createSelector(
  selectInstantEditDeckItemId,
  selectInstantEditBiqaDeckItemId,
  (_, id) => id,
  (_, __, biqaDeckItemId) => biqaDeckItemId,
  (instId, instBiqaDeckItemId, id, biqaDeckItemId) => {
    return instId === id && instBiqaDeckItemId === biqaDeckItemId;
  }
);

//
export function selectFileSelect(state) {
  return state.chat.fileSelect;
}

export const selectIsChatDeletionOpened = (state) => {
  // ALGORITHM: Check "components/ChatsPage/DeckDeletion/DeckDeletion"

  const { chat } = state;

  // ALGORITHM.1.a.
  if (isMobile) {
    const histState = selectHistoryStateProp(state, DELET_SCR_CHHS);

    return histState;
  }

  // ALGORITHM.2.a.
  return chat.showChatDeletion;
};

export const selectShowChatEditionMenu = ({ chat }) => chat.showChatEditionMenu;
