import { apiSlice } from "store/api/apiSlice";

export const homeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    recentlyUserInfo: builder.query({
      query: (arg) => {
        let params = arg?.params;

        if (!params) {
          params = { create_on_sign_up: true };
        }

        return { url: "/users/auth", params };
      },
      transformResponse: (respData) => {
        const data = respData;

        data.groups = { entities: {}, ids: [] };
        data.items = { entities: {}, ids: [] };

        const files = respData.user_requesting_featured_files;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const items = files[i].items;

          const groupId = file.group_title;
          data.groups.entities[groupId] = file;
          data.groups.ids.push(groupId);

          for (let j = 0; j < items.length; j++) {
            const item = items[j];
            const itemId = item.file_id;

            data.groups.entities[groupId].items[j] = itemId;
            data.items.entities[itemId] = item;
            data.items.ids.push(itemId);
          }
        }

        return data;
      },
    }),
    deleteFile: builder.mutation({
      query: (arg) => {
        const { files_list, value } = arg;

        const url = `/files`;

        const body = {
          op: "replace",
          path: "/multiple/trashed",
          boolean_value: value,
          files_list,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = homeApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { count, files } = res.data.success;
          if (!count) return;

          dispatch(
            updateQueryData("recentlyUserInfo", undefined, (draft) => {
              const groups = draft.groups;
              let groupsIds = draft.groups.ids;
              const items = draft.items;

              files.forEach((file) => {
                const { file_id } = file;

                for (let i = 0; i < groupsIds.length; i++) {
                  let grpId = groupsIds[i];
                  const grp = groups.entities[grpId];

                  // 1- Remove the file from group
                  if (grp.items.includes(file_id)) {
                    grp.items = grp.items.filter((id) => id !== file_id);

                    // 2- If there is no more files in the group, delete the group
                    if (!grp.items.length) {
                      groups.ids = groupsIds.filter((id) => id !== grpId);
                      delete groups.entities[grpId];
                    }

                    // 3- Remove file from items
                    delete items.entities[file_id];
                    items.ids = items.ids.filter((id) => id !== file_id);
                    break;
                  }
                }
              });
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    patchFileName: builder.mutation({
      query: (arg) => {
        const { fileId, value } = arg;

        const body = {
          op: "replace",
          path: "/file_name",
          value,
        };

        return { url: `/files/${fileId}`, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId, value } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = homeApiSlice.util;

        // Optimistic Update
        // Update file name immediately
        const patch = dispatch(
          updateQueryData("recentlyUserInfo", undefined, draft)
        );

        function draft(draft) {
          const item = draft.items.entities[fileId];
          if (!item) return;
          item.file_name = value;
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("recentlyUserInfo", undefined, (draft) => {
              const item = draft.items.entities[fileId];
              Object.assign(item, data);
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    validateDataset: builder.mutation({
      query: (arg) => {
        const { fileId, boolean_value } = arg;

        const body = {
          op: "replace",
          path: "/validated",
          boolean_value,
        };

        return { url: `/files/${fileId}/dataset`, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = homeApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("recentlyUserInfo", undefined, (draft) => {
              const item = draft.items.entities[fileId];
              if (!item) return;
              Object.assign(item.file_dataset, data.dataset);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    changeItemPriority: builder.mutation({
      query: (arg) => {
        const { fileId, detail_id, aggregation_id, integer_value } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          integer_value,
        };

        if (aggregation_id) {
          body.aggregation_id = aggregation_id;
          body.path = "/dataset_aggregations/display_priority";
        }

        if (detail_id) {
          body.detail_id = detail_id;
          body.path = "/dataset_details/display_priority";
        }

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = homeApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("recentlyUserInfo", undefined, (draft) => {
              const item = draft.items.entities[fileId];
              if (!item) return;
              Object.assign(item.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    changeItemHide: builder.mutation({
      query: (arg) => {
        const { fileId, detail_id, aggregation_id, boolean_value } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          boolean_value,
        };

        if (aggregation_id) {
          body.aggregation_id = aggregation_id;
          body.path = "/dataset_aggregations/hidden";
        }

        if (detail_id) {
          body.detail_id = detail_id;
          body.path = "/dataset_details/hidden";
        }

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = homeApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("recentlyUserInfo", undefined, (draft) => {
              const item = draft.items.entities[fileId];
              if (!item) return;
              Object.assign(item.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateDatasetItemName: builder.mutation({
      query: (arg) => {
        const { fileId, value, aggregation_id, detail_id } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          value,
        };

        if (aggregation_id) {
          body.aggregation_id = aggregation_id;
          body.path = "/dataset_aggregations/aggregation_display_name_value";
        }

        if (detail_id) {
          body.detail_id = detail_id;
          body.path = "/dataset_details/display_name";
        }

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = homeApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("recentlyUserInfo", undefined, (draft) => {
              const item = draft.items.entities[fileId];
              if (!item) return;
              Object.assign(item.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    createDatasetAggregation: builder.mutation({
      query: (arg) => {
        const {
          fileId,
          components,
          aggregation_display_name,
          aggregation_filters,
        } = arg;

        const url = `/files/${fileId}/dataset/dataset_aggregations`;

        const body = {
          aggregation_type: "formula",
          components,
          aggregation_display_name,
        };

        if (aggregation_filters) {
          body.aggregation_filters = aggregation_filters;
        }

        return { url, method: "POST", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = homeApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("recentlyUserInfo", undefined, (draft) => {
              const item = draft.items.entities[fileId];
              if (!item) return;
              Object.assign(item.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    deleteMetric: builder.mutation({
      query: (arg) => {
        const { fileId, boolean_value, aggregations_list } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          path: "/dataset_aggregations/multiple/trashed",
          boolean_value,
          aggregations_list,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = homeApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("recentlyUserInfo", undefined, (draft) => {
              const item = draft.items.entities[fileId];
              if (!item) return;
              Object.assign(item.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateDatasetItemDef: builder.mutation({
      query: (arg) => {
        const { fileId, req_body } = arg;

        const url = `/files/${fileId}/dataset`;

        return { url, method: "PATCH", body: req_body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = homeApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("recentlyUserInfo", undefined, (draft) => {
              const item = draft.items.entities[fileId];
              if (!item) return;
              Object.assign(item.file_dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
  }),
});

export const {
  useRecentlyUserInfoQuery,
  useDeleteFileMutation,
  usePatchFileNameMutation,
  useValidateDatasetMutation,
  useChangeItemPriorityMutation,
  useChangeItemHideMutation,
  useUpdateDatasetItemNameMutation,
  useCreateDatasetAggregationMutation,
  useDeleteMetricMutation,
  useUpdateDatasetItemDefMutation,
} = homeApiSlice;
