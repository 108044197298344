import { v4 as uuid } from "uuid";

import { serialize } from "services/editor";

import { apiSlice } from "store/api/apiSlice";
import store from "store/store";

// TODO:  update item orders

// NOTE: Endpoints definitions will be ended with DP to avoid overriding
export const deckApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDeck: builder.query({
      query: (arg) => {
        const { fileId, isPublic } = arg;

        let url = `/files/${fileId}`;
        if (isPublic) {
          url = `/public/files/${fileId}`;
        }

        const params = { extended: true };

        return { url, params };
      },
      transformResponse: (respData) => {
        const data = {
          ...respData,
          file_deck: {
            ...respData.file_deck,
            deck_items: {
              entities: {},
              ids: [],
            },
            deck_dataset_files: {
              entities: {},
              ids: [],
            },
          },
          editors: {},
        };

        const { deck_dataset_files, deck_items } = respData.file_deck;

        // Normalized data
        const normalized = normalizeData(deck_items, deck_dataset_files);
        data.file_deck.deck_items = normalized.deck_items;
        data.file_deck.deck_dataset_files = normalized.deck_dataset_files;

        return data;
      },
      serializeQueryArgs: () => "getDeck",
    }),
    getPollingDeck: builder.query({
      query: (arg) => {
        const { fileId, deckLastModified } = arg;

        let url = `/files/${fileId}`;

        const params = {
          extended: true,
          poll_last_modified_date: deckLastModified,
        };

        return { url, params };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const data = { ...res.data };
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              if (data.file_deck) {
                data.file_deck = { ...data.file_deck };
                const { deck_dataset_files, deck_items } = data.file_deck;

                // Normalized data
                const normalized = normalizeData(
                  deck_items,
                  deck_dataset_files
                );
                data.file_deck.deck_items = normalized.deck_items;
                data.file_deck.deck_dataset_files =
                  normalized.deck_dataset_files;
              }
              Object.assign(draft, data);
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    getAttrValuesOpts: builder.query({
      query: (arg) => {
        const { fileId, params } = arg;

        let url = `/files/${fileId}/dataset/attribute_value_options`;

        return { url, params };
      },
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        const { attribute_detail_id, time_dimension } = queryArgs.params;

        let name = `${endpointName}_${attribute_detail_id}`;

        if (time_dimension) {
          name += `_${time_dimension}`;
        }

        return name;
      },
      keepUnusedDataFor: 60 * 60 * 3, // TODO: check again
    }),
    patchFileNameDP: builder.mutation({
      query: (arg) => {
        const { fileId, value } = arg;

        const body = {
          op: "replace",
          path: "/file_name",
          value,
        };

        return { url: `/files/${fileId}`, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              draft.file_name = data.file_name;
              draft.date_last_modified = data.date_last_modified;
              draft.user_last_modified = data.user_last_modified;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateDeckDescription: builder.mutation({
      query: (arg) => {
        const { fileId, value } = arg;

        const url = `/files/${fileId}/deck`;

        const body = {
          op: "replace",
          path: "/deck_description/deck_description_active",
          value,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { value } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          draft.file_deck.deck_description.deck_description_active = value;
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                user_last_modified,
                date_last_modified,
                deck_description,
              } = data;

              draft.file_deck.deck_description.deck_description_active =
                deck_description.deck_description_active;
              draft.date_last_modified = date_last_modified;
              draft.user_last_modified = user_last_modified;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    updateTextItemContent: builder.mutation({
      query: (arg) => {
        const { deckItemId, value } = arg;

        const url = `/deck_items/${deckItemId}`;

        const body = {
          op: "replace",
          path: "/text/text_active",
          value,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                text,
              } = data;

              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_items.entities[deckItemId].text = text;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateSuggested: builder.mutation({
      query: (arg) => {
        const { deckItemId, value } = arg;

        const url = `/deck_items/${deckItemId}`;

        const body = {
          op: "replace",
          path: "/show_as_suggestion",
          boolean_value: value,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId, value } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          draft.file_deck.deck_items.entities[deckItemId].show_as_suggestion =
            value;
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                show_as_suggestion,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_items.entities[
                deckItemId
              ].show_as_suggestion = show_as_suggestion;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    updateAnalysisName: builder.mutation({
      query: (arg) => {
        const { id, value } = arg;

        const url = `/analyses/${id}`;

        const body = [
          {
            op: "replace",
            path: "/analysis_title/title_active",
            value,
          },
        ];

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId, value } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          draft.file_deck.deck_items.entities[
            deckItemId
          ].analysis.analysis_title.title_active = value;
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    updateTextItemFormat: builder.mutation({
      query: (arg) => {
        const { deckItemId, value } = arg;

        const url = `/deck_items/${deckItemId}`;

        const body = {
          op: "replace",
          path: "/text/format",
          value,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId, value } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          draft.file_deck.deck_items.entities[deckItemId].text.format = value;
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                text,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_items.entities[deckItemId].text = text;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    // #region comment
    createComment: builder.mutation({
      query: (arg) => {
        const { deckItemId, comment_content } = arg;

        const url = `/deck_items/${deckItemId}/comments`;

        const body = {
          comment_content,
        };

        return { url, method: "POST", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                new_comment,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_items.entities[
                deckItemId
              ].comments.active_comments.push(new_comment);
            })
          );
        }

        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    createCommentReply: builder.mutation({
      query: (arg) => {
        const { commentId, comment_content } = arg;

        const url = `/comments/${commentId}/replies`;

        const body = { comment_content };

        return { url, method: "POST", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                deck_item_id,
                comments,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_items.entities[deck_item_id].comments =
                comments;
            })
          );
        }

        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    archiveCommentFeed: builder.mutation({
      query: (arg) => {
        const { commentId, boolean_value } = arg;

        const url = `/comments/${commentId}`;

        const body = { op: "replace", path: "/archived", boolean_value };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                deck_item_id,
                comments,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_items.entities[deck_item_id].comments =
                comments;
            })
          );
        }

        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    editCommentFeed: builder.mutation({
      query: (arg) => {
        const { commentId, value } = arg;

        const url = `/comments/${commentId}`;

        const body = { op: "replace", path: "/comment_content", value };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                deck_item_id,
                comments,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_items.entities[deck_item_id].comments =
                comments;
            })
          );
        }

        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    deleteComment: builder.mutation({
      query: (arg) => {
        const { commentId } = arg;

        const url = `/comments/${commentId}`;

        const body = { op: "replace", path: "/trashed", boolean_value: true };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                deck_item_id,
                comments,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_items.entities[deck_item_id].comments =
                comments;
            })
          );
        }

        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    // #endregion comments
    addToFavoritesDP: builder.mutation({
      query: (arg) => {
        const { fileId } = arg;
        return { url: `/files/${fileId}/stars`, method: "PUT" };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        // TODO: update the response of API in backend
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const { file_date_last_modified, file_user_last_modified } = data;

              draft.date_last_modified = file_date_last_modified;
              draft.user_last_modified = file_user_last_modified;
              draft.starred_by_user_requesting = true;
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    removeFromFavoritesDP: builder.mutation({
      query: (arg) => {
        const { fileId } = arg;
        const body = { op: "replace", path: "/trashed", boolean_value: true };

        return { url: `/files/${fileId}/stars`, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        // TODO: update the response of API in backend, return last_modified_date
        function pessimisticUpdate(res) {
          const { data } = res;

          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const { file_date_last_modified, file_user_last_modified } = data;

              draft.date_last_modified = file_date_last_modified;
              draft.user_last_modified = file_user_last_modified;

              draft.starred_by_user_requesting = false;
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    lockDeck: builder.mutation({
      query: (arg) => {
        const { fileId, boolean_value } = arg;

        const url = `/files/${fileId}`;

        const body = { op: "replace", path: "/locked", boolean_value };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        // TODO: update the response of API in backend, return last_modified_date
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              draft.date_last_modified = data.date_last_modified;
              draft.user_last_modified = data.user_last_modified;

              draft.user_locked = data.user_locked;

              draft.locked = data.locked;
              draft.user_requesting_capabilities =
                data.user_requesting_capabilities;
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateDeckPrivacy: builder.mutation({
      query: (arg) => {
        const { fileId, value } = arg;

        const url = `/files/${fileId}`;

        const body = { op: "replace", path: "/private", boolean_value: value };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        // TODO: update the response of API in backend, return last_modified_date
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              draft.date_last_modified = data.date_last_modified;
              draft.user_last_modified = data.user_last_modified;
              draft.private = data.private;
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    // #region datasets
    patchDatasetNameDP: builder.mutation({
      query: (arg) => {
        const { fileId, value } = arg;

        const body = {
          op: "replace",
          path: "/file_name",
          value,
        };

        return { url: `/files/${fileId}`, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const { date_last_modified, file_name, user_last_modified } =
                data;

              const dataset =
                draft.file_deck.deck_dataset_files.entities[fileId];

              if (!dataset) return;
              dataset.date_last_modified = date_last_modified;
              dataset.user_last_modified = user_last_modified;
              dataset.dataset_file_name = file_name;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    validateDatasetDP: builder.mutation({
      query: (arg) => {
        const { fileId, boolean_value } = arg;

        const body = {
          op: "replace",
          path: "/validated",
          boolean_value,
        };

        return { url: `/files/${fileId}/dataset`, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;

          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const dataset =
                draft.file_deck.deck_dataset_files.entities[fileId];
              if (!dataset) return;
              Object.assign(dataset, data.dataset);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    changeItemPriorityDP: builder.mutation({
      query: (arg) => {
        const { fileId, detail_id, aggregation_id, integer_value } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          integer_value,
        };

        if (aggregation_id) {
          body.aggregation_id = aggregation_id;
          body.path = "/dataset_aggregations/display_priority";
        }

        if (detail_id) {
          body.detail_id = detail_id;
          body.path = "/dataset_details/display_priority";
        }

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const dataset =
                draft.file_deck.deck_dataset_files.entities[fileId];
              if (!dataset) return;
              Object.assign(dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    changeItemHideDP: builder.mutation({
      query: (arg) => {
        const { fileId, detail_id, aggregation_id, boolean_value } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          boolean_value,
        };

        if (aggregation_id) {
          body.aggregation_id = aggregation_id;
          body.path = "/dataset_aggregations/hidden";
        }

        if (detail_id) {
          body.detail_id = detail_id;
          body.path = "/dataset_details/hidden";
        }

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const dataset =
                draft.file_deck.deck_dataset_files.entities[fileId];
              if (!dataset) return;
              Object.assign(dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateDatasetItemNameDP: builder.mutation({
      query: (arg) => {
        const { fileId, value, aggregation_id, detail_id } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          value,
        };

        if (aggregation_id) {
          body.aggregation_id = aggregation_id;
          body.path = "/dataset_aggregations/aggregation_display_name_value";
        }

        if (detail_id) {
          body.detail_id = detail_id;
          body.path = "/dataset_details/display_name";
        }

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const dataset =
                draft.file_deck.deck_dataset_files.entities[fileId];
              if (!dataset) return;
              Object.assign(dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    createDatasetAggregationDP: builder.mutation({
      query: (arg) => {
        const {
          fileId,
          components,
          aggregation_display_name,
          aggregation_filters,
        } = arg;

        const url = `/files/${fileId}/dataset/dataset_aggregations`;

        const body = {
          aggregation_type: "formula",
          components,
          aggregation_display_name,
        };

        if (aggregation_filters) {
          body.aggregation_filters = aggregation_filters;
        }

        return { url, method: "POST", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const dataset =
                draft.file_deck.deck_dataset_files.entities[fileId];
              if (!dataset) return;
              Object.assign(dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    deleteMetricDP: builder.mutation({
      query: (arg) => {
        const { fileId, boolean_value, aggregations_list } = arg;

        const url = `/files/${fileId}/dataset`;

        const body = {
          op: "replace",
          path: "/dataset_aggregations/multiple/trashed",
          boolean_value,
          aggregations_list,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const dataset =
                draft.file_deck.deck_dataset_files.entities[fileId];
              if (!dataset) return;
              Object.assign(dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    updateDatasetItemDefDP: builder.mutation({
      query: (arg) => {
        const { fileId, req_body } = arg;

        const url = `/files/${fileId}/dataset`;

        return { url, method: "PATCH", body: req_body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { fileId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getFiles", undefined, (draft) => {
              const dataset =
                draft.file_deck.deck_dataset_files.entities[fileId];
              if (!dataset) return;
              Object.assign(dataset, data);
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    removeDatasetFromDeck: builder.mutation({
      query: (arg) => {
        const { deckId, datasetId } = arg;

        const url = `/files/${deckId}/deck`;

        const body = {
          op: "remove",
          path: "/deck_dataset_files",
          dataset_file_id: datasetId,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { datasetId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const { date_last_modified, user_last_modified } = data;
              draft.date_last_modified = date_last_modified;
              draft.user_last_modified = user_last_modified;

              draft.file_deck.deck_dataset_file_count -= 1;

              delete draft.file_deck.deck_dataset_files.entities[datasetId];
              const ids = draft.file_deck.deck_dataset_files.ids;
              draft.file_deck.deck_dataset_files.ids = ids.filter(
                (id) => id !== datasetId
              );
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    addDatasetToDeck: builder.mutation({
      query: (arg) => {
        const { deckId, datasetId, replacement_check } = arg;

        const url = `/files/${deckId}/deck`;

        const body = {
          op: "add",
          path: "/deck_dataset_files",
          dataset_file_id: datasetId,
        };

        if (replacement_check) {
          body.replacement_check = replacement_check;
        }

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              normalizeDeck(draft, data);
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    replaceDatasetInDeck: builder.mutation({
      query: (arg) => {
        const { deckId, current_dataset_file_id, new_dataset_file_id } = arg;

        const url = `/files/${deckId}/deck`;

        const body = {
          op: "replace",
          path: "/deck_dataset_files",
          current_dataset_file_id,
          new_dataset_file_id,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              normalizeDeck(draft, data);
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    // #endregion datasets

    // #region permissions
    updatePermissionDP: builder.mutation({
      query: (arg) => {
        const { permissionId, role } = arg;

        const url = `/permissions/${permissionId}`;

        const body = {
          op: "replace",
          path: "/permission_role_name",
          value: role,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (_, lifecycleApis) => {
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;

          const {
            permission_role_name,
            permission_user,
            permission_id,
            date_last_modified,
          } = data;

          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              draft.date_last_modified = date_last_modified;
              draft.file_permissions = draft.file_permissions.map(
                (permission) => {
                  if (permission_id === permission.permission_id) {
                    return {
                      permission_role_name,
                      permission_user,
                      permission_id,
                    };
                  } else {
                    return permission;
                  }
                }
              );
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    deletePermissionDP: builder.mutation({
      query: (arg) => {
        const { permissionId, params } = arg;

        const url = `/permissions/${permissionId}`;

        return { url, method: "DELETE", params };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { permissionId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta}
         */
        function pessimisticUpdate(res) {
          const { data } = res;

          const {
            permission_file_date_last_modified,
            permission_file_user_last_modified,
          } = data;

          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              draft.date_last_modified = permission_file_date_last_modified;
              draft.user_last_modified = permission_file_user_last_modified;
              draft.file_permission_count -= 1;
              draft.file_permissions = draft.file_permissions.filter(
                (permission) => permission.permission_id !== permissionId
              );
            })
          );
        }

        // If there is any error, undo the update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    // #endregion permissions

    createDeckItem: builder.mutation({
      query: (arg) => {
        const { fileId, req_body } = arg;

        const url = `/files/${fileId}/deck/deck_items`;

        const body = req_body;

        return { url, method: "POST", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { item_placeholder, req_body } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        const { relative_position } = req_body[0];
        const { deck_item_order } = item_placeholder;

        // Get index
        const startIndex =
          relative_position === "after" ? deck_item_order : deck_item_order - 1;

        const patch = dispatch(updateQueryData("getDeck", undefined, draft));
        function draft(draft) {
          const { deck_item_id } = item_placeholder;

          draft.file_deck.deck_item_count += 1;
          draft.file_deck.deck_items.entities[deck_item_id] = item_placeholder;

          draft.file_deck.deck_items.ids.splice(startIndex, 0, deck_item_id);
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const {
            date_last_modified,
            user_last_modified,
            deck_item_responses_list,
          } = res.data;

          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const item = deck_item_responses_list[0];
              const deckItemId = item.deck_item_id;

              draft.date_last_modified = date_last_modified;
              draft.user_last_modified = user_last_modified;

              draft.file_deck.deck_items.ids.splice(startIndex, 1, deckItemId);

              delete draft.file_deck.deck_items.entities[
                item_placeholder.deck_item_id
              ];
              draft.file_deck.deck_items.entities[deckItemId] = item;
            })
          );

          updateItemsOrder();
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    createAnalyses: builder.mutation({
      query: (arg) => {
        const { fileId, analyses, params } = arg;

        const url = `/files/${fileId}/deck/deck_items`;

        const body = analyses;

        return { url, method: "POST", body, params };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { analyses } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          analyses.forEach((_, index) => {
            const placeholder = {
              deck_item_id: `${index}`,
              deck_item_type: "analysis",
              deck_item_order: index,
              is_placeholder: true,
            };
            draft.file_deck.deck_items.ids.push(index);
            draft.file_deck.deck_items.entities[index] = placeholder;
          });
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                date_last_modified,
                user_last_modified,
                deck_item_responses_list,
              } = data;

              const newItems = deck_item_responses_list;

              draft.date_last_modified = date_last_modified;
              draft.user_last_modified = user_last_modified;
              draft.file_deck.deck_item_count += newItems.length;

              analyses.forEach((_, index) => {
                draft.file_deck.deck_items.ids.pop();
                delete draft.file_deck.deck_items.entities[index];
              });

              newItems.forEach((item) => {
                const { deck_item_id } = item;
                draft.file_deck.deck_items.entities[deck_item_id] = item;
                draft.file_deck.deck_items.ids.push(deck_item_id);
              });
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    createDerivedAnalysis: builder.mutation({
      query: (arg) => {
        const { deck_item_id, short_body, params } = arg;

        const url = `/deck_items/${deck_item_id}/derived`;

        const body = short_body;

        return { url, method: "POST", body, params };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { relativeDeckItemIndex } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        const placeholder = createItemPlaceholder(
          "analysis",
          relativeDeckItemIndex + 1
        );

        const patch = dispatch(updateQueryData("getDeck", undefined, draft));
        function draft(draft) {
          draft.file_deck.deck_items.ids.splice(
            placeholder.deck_item_order,
            0,
            placeholder.deck_item_id
          );
          draft.file_deck.deck_items.entities[placeholder.deck_item_id] =
            placeholder;
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const item = res.data;

          const { deck_file_date_last_modified, deck_file_user_last_modified } =
            item;

          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const deckItemId = item.deck_item_id;

              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_item_count += 1;

              draft.file_deck.deck_items.ids.splice(
                placeholder.deck_item_order,
                1,
                deckItemId
              );

              delete draft.file_deck.deck_items.entities[
                placeholder.deck_item_id
              ];
              draft.file_deck.deck_items.entities[deckItemId] = item;
            })
          );
          updateItemsOrder();
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    createDriversAnalysis: builder.mutation({
      query: (arg) => {
        const { deck_item_id, valueOrder, metricOrder } = arg;

        const url = `/deck_items/${deck_item_id}/derived/drivers`;

        const params = {
          metric_order: metricOrder,
          value_order: valueOrder,
        };

        return { url, method: "POST", params };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemOrder } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        const itemOrder = deckItemOrder + 1;
        const placeholder = createItemPlaceholder("analysis", itemOrder);

        const patch = dispatch(updateQueryData("getDeck", undefined, draft));
        function draft(draft) {
          draft.file_deck.deck_items.ids.splice(
            placeholder.deck_item_order,
            0,
            placeholder.deck_item_id
          );
          draft.file_deck.deck_items.entities[placeholder.deck_item_id] =
            placeholder;
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const item = res.data;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                deck_item_id,
              } = item;

              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_item_count += 1;

              draft.file_deck.deck_items.ids.splice(
                placeholder.deck_item_order,
                1,
                deck_item_id
              );

              delete draft.file_deck.deck_items.entities[
                placeholder.deck_item_id
              ];
              draft.file_deck.deck_items.entities[deck_item_id] = item;
            })
          );
          updateItemsOrder();
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    reorderItems: builder.mutation({
      query: (arg) => {
        const { fileId, items_list } = arg;

        const url = `/files/${fileId}/deck`;

        const body = {
          op: "reorder",
          path: "/deck_items",
          items_list,
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { items_list } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          draft.file_deck.deck_items.ids = items_list.map(
            ({ deck_item_id }) => deck_item_id
          );
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const { date_last_modified, user_last_modified } = data;
              draft.date_last_modified = date_last_modified;
              draft.user_last_modified = user_last_modified;
            })
          );

          updateItemsOrder();
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    duplicateDeckItem: builder.mutation({
      query: (arg) => {
        const { deckItemId } = arg;

        const url = `/deck_items/${deckItemId}/copy`;

        return { url, method: "POST" };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { item_placeholder } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        // Create instant item
        function draft(draft) {
          const { deck_item_order, deck_item_id } = item_placeholder;
          draft.file_deck.deck_items.entities[deck_item_id] = item_placeholder;
          draft.file_deck.deck_items.ids.splice(
            deck_item_order,
            0,
            deck_item_id
          );
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const item = res.data;

          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                deck_item_id,
              } = item;

              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
              draft.file_deck.deck_item_count += 1;

              draft.file_deck.deck_items.ids.splice(
                item_placeholder.deck_item_order,
                1,
                deck_item_id
              );

              delete draft.file_deck.deck_items.entities[
                item_placeholder.deck_item_id
              ];
              draft.file_deck.deck_items.entities[deck_item_id] = item;
            })
          );

          updateItemsOrder();
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    updateAnalysisContent: builder.mutation({
      query: (arg) => {
        const { deckItemId, content } = arg;

        const url = `/deck_items/${deckItemId}`;

        const body = content;

        return { url, method: "PUT", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
              } = data;

              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;

              draft.file_deck.deck_items.entities[deckItemId] = data;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    undoRedoAnalysisContent: builder.mutation({
      query: (arg) => {
        const { deckItemId, history } = arg;

        const url = `/deck_items/${deckItemId}?history=${history}`;

        return { url, method: "PUT", body: {} };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
              } = data;

              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;

              draft.file_deck.deck_items.entities[deckItemId] = data;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
    removeDeckItems: builder.mutation({
      query: (arg) => {
        const { fileId, value, items } = arg;

        const url = `/files/${fileId}/deck`;

        const body = {
          op: "replace",
          path: "/deck_items/multiple/trashed",
          boolean_value: value,
          items_list: items.map(({ deck_item_id }) => ({ deck_item_id })),
        };

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { items } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          items.forEach((item) => {
            const deckItemId = item.deck_item_id;

            draft.file_deck.deck_item_count -= 1;
            delete draft.file_deck.deck_items.entities[deckItemId];

            let ids = draft.file_deck.deck_items.ids;
            draft.file_deck.deck_items.ids = ids.filter(
              (id) => id !== deckItemId
            );
          });
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate() {
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              items.forEach((item) => {
                delete draft.file_deck.deck_items.entities[item.deck_item_id];
              });
            })
          );
          updateItemsOrder();
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),

    // #region charts
    updateStoryActive: builder.mutation({
      query: (arg) => {
        const { analysisId, value } = arg;

        const url = `/analyses/${analysisId}`;

        const body = [
          {
            op: "replace",
            path: "/story/story_active",
            value,
          },
        ];

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId, value } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          const item = draft.file_deck.deck_items.entities[deckItemId];
          item.analysis.story.story_active = value;
          item.analysis.story.story_active_serialized = serialize(value);
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                analysis_update_responses_list,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;

              const { analysis_update_success, response } =
                analysis_update_responses_list[0];

              if (analysis_update_success) {
                const item = draft.file_deck.deck_items.entities[deckItemId];
                item.analysis.story = response.story;
              }
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    updateStackFormat: builder.mutation({
      query: (arg) => {
        const { analysisId, value, metricOrder } = arg;

        const url = `/analyses/${analysisId}`;

        const body = [
          {
            op: "replace",
            path: `/chart/chart_data/metrics/${metricOrder}/metric_styling/stacks_format`,
            value,
          },
        ];

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId, value, metricOrder } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          const item = draft.file_deck.deck_items.entities[deckItemId];
          const metrics = item.analysis.chart.chart_data.metrics;

          for (let i = 0; i < metrics.length; i++) {
            const metric = metrics[i];

            if (metric.metric_order === metricOrder) {
              metric.metric_styling.stacks_format = value;
              break;
            }
          }
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    updateChartStyling: builder.mutation({
      query: (arg) => {
        const { analysisId, value, path } = arg;

        const url = `/analyses/${analysisId}`;

        const body = [
          {
            op: "replace",
            path: `/chart/chart_styling/${path}`,
            boolean_value: value,
          },
        ];

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId, value, path } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          const item = draft.file_deck.deck_items.entities[deckItemId];
          item.analysis.chart.chart_styling[path] = value;
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    updateChartCaption: builder.mutation({
      query: (arg) => {
        const { analysisId, value } = arg;

        const url = `/analyses/${analysisId}`;

        const body = [
          {
            op: "replace",
            path: "/chart/chart_styling/custom_caption",
            value,
          },
        ];

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId, value } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          const item = draft.file_deck.deck_items.entities[deckItemId];
          item.analysis.chart.chart_styling.custom_caption = value;
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    updateStackColor: builder.mutation({
      query: (arg) => {
        const { analysisId, metricOrder, stackOrder, value } = arg;

        const url = `/analyses/${analysisId}`;

        const body = [
          {
            op: "replace",
            path: `/chart/chart_data/metrics/${metricOrder}/stacks/${stackOrder}/color`,
            value,
          },
        ];

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId, metricOrder, stackOrder, value } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          const item = draft.file_deck.deck_items.entities[deckItemId];
          const metrics = item.analysis.chart.chart_data.metrics;

          for (let i = 0; i < metrics.length; i++) {
            const metric = metrics[i];

            if (metric.metric_order === metricOrder) {
              for (let j = 0; j < metric.stacks.length; j++) {
                const stack = metric.stacks[j];

                if (stack.stack_order === stackOrder) {
                  stack.color = value;
                  break;
                }
              }
              break;
            }
          }
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    updateMetricStyling: builder.mutation({
      query: (arg) => {
        const { analysisId, value, path, metricOrder } = arg;

        const url = `/analyses/${analysisId}`;

        const body = [
          {
            op: "replace",
            path: `/chart/chart_data/metrics/${metricOrder}/metric_styling/${path}`,
            boolean_value: value,
          },
        ];

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId, value, path, metricOrder } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          if (path === "separate_y_axis") {
            return;
          }

          const item = draft.file_deck.deck_items.entities[deckItemId];
          const metrics = item.analysis.chart.chart_data.metrics;

          for (let i = 0; i < metrics.length; i++) {
            const metric = metrics[i];

            if (metric.metric_order === metricOrder) {
              metric.metric_styling[path] = value;
              break;
            }
          }
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
                analysis_update_responses_list,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;

              if (path === "separate_y_axis") {
                const item = draft.file_deck.deck_items.entities[deckItemId];

                item.analysis.chart.chart_data =
                  analysis_update_responses_list[0].response.chart.chart_data;
              }
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    updateMetricShape: builder.mutation({
      query: (arg) => {
        const { analysisId, value, metricOrder } = arg;

        const url = `/analyses/${analysisId}`;

        const body = [
          {
            op: "replace",
            path: `/chart/chart_data/metrics/${metricOrder}/metric_styling/shape`,
            value,
          },
        ];

        return { url, method: "PATCH", body };
      },
      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId, value, metricOrder } = arg;
        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        // Optimistic Update
        const patch = dispatch(updateQueryData("getDeck", undefined, draft));

        function draft(draft) {
          const item = draft.file_deck.deck_items.entities[deckItemId];
          const metrics = item.analysis.chart.chart_data.metrics;

          for (let i = 0; i < metrics.length; i++) {
            const metric = metrics[i];

            if (metric.metric_order === metricOrder) {
              metric.metric_styling.shape = value;
              break;
            }
          }
        }

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
              } = data;
              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate).catch(patch.undo);
      },
    }),
    // #endregion charts

    convertToText: builder.mutation({
      query: (arg) => {
        const { deckItemId } = arg;

        const url = `/deck_items/${deckItemId}/convert/text`;

        return { url, method: "PUT" };
      },

      onQueryStarted: (arg, lifecycleApis) => {
        const { deckItemId } = arg;

        const { dispatch, queryFulfilled } = lifecycleApis;
        const { updateQueryData } = deckApiSlice.util;

        /**
         * Update file after success response
         *
         * @param {object} res api response { data, meta }
         */
        function pessimisticUpdate(res) {
          const { data } = res;
          dispatch(
            updateQueryData("getDeck", undefined, (draft) => {
              const {
                deck_file_date_last_modified,
                deck_file_user_last_modified,
              } = data;

              draft.date_last_modified = deck_file_date_last_modified;
              draft.user_last_modified = deck_file_user_last_modified;

              draft.file_deck.deck_items.entities[deckItemId] = data;
            })
          );
        }

        // If there is any error, undo the optimistic update
        queryFulfilled.then(pessimisticUpdate);
      },
    }),
  }),
});

function normalizeDeck(draft, deck) {
  const {
    deck_combinations,
    deck_dataset_file_count,
    deck_dataset_files,
    deck_description,
    deck_id,
    deck_item_count,
    deck_items,
    deck_suggestions,
    dependent_on_trashed_aggregations_in_untrashed_datasets,
    dependent_on_trashed_dataset_files,
  } = deck.file_deck;

  draft.file_deck.deck_combinations = deck_combinations;
  draft.file_deck.deck_suggestions = deck_suggestions;
  draft.file_deck.deck_dataset_file_count = deck_dataset_file_count;
  draft.file_deck.deck_description = deck_description;
  draft.file_deck.deck_id = deck_id;
  draft.file_deck.deck_item_count = deck_item_count;
  draft.file_deck.dependent_on_trashed_aggregations_in_untrashed_datasets =
    dependent_on_trashed_aggregations_in_untrashed_datasets;
  draft.file_deck.dependent_on_trashed_dataset_files =
    dependent_on_trashed_dataset_files;

  // Normalized data
  const normalized = normalizeData(deck_items, deck_dataset_files);
  draft.file_deck.deck_items = normalized.deck_items;
  draft.file_deck.deck_dataset_files = normalized.deck_dataset_files;
}

function normalizeData(items, datasets) {
  const data = {
    deck_items: {
      entities: {},
      ids: [],
    },
    deck_dataset_files: {
      entities: {},
      ids: [],
    },
  };

  datasets.forEach((dataset) => {
    const { dataset_file_id } = dataset;
    data.deck_dataset_files.entities[dataset_file_id] = dataset;
    data.deck_dataset_files.ids.push(dataset_file_id);
  });

  items.forEach((item) => {
    const { deck_item_id } = item;

    data.deck_items.entities[deck_item_id] = item;
    data.deck_items.ids.push(deck_item_id);
  });

  return data;
}

export function createItemPlaceholder(type, order) {
  const placeholder_id = uuid().toString();
  const placeholder = {
    deck_item_id: placeholder_id,
    deck_item_type: type,
    deck_item_order: order,
    is_placeholder: true,
  };

  return placeholder;
}

export function addAnalysesToDeck(item) {
  const { updateQueryData } = deckApiSlice.util;

  function draft(draft) {
    const id = item.deck_item_id;
    draft.file_deck.deck_items.ids.push(id);
    draft.file_deck.deck_items.entities[id] = item;

    draft.file_deck.deck_dataset_file_count += 1;
  }

  return updateQueryData("getDeck", undefined, draft);
}

export const {
  useGetDeckQuery,
  usePatchFileNameDPMutation,
  useRemoveDeckItemsMutation,
  useUpdateTextItemContentMutation,
  useUpdateSuggestedMutation,
  useUpdateAnalysisNameMutation,
  useAddToFavoritesDPMutation,
  useRemoveFromFavoritesDPMutation,
  useLockDeckMutation,
  useUpdateDeckPrivacyMutation,
  usePatchDatasetNameDPMutation,
  useValidateDatasetDPMutation,
  useChangeItemHideDPMutation,
  useChangeItemPriorityDPMutation,
  useUpdateDatasetItemNameDPMutation,
  useCreateDatasetAggregationDPMutation,
  useDeleteMetricDPMutation,
  useUpdateTextItemFormatMutation,
  useCreateCommentMutation,
  useCreateCommentReplyMutation,
  useArchiveCommentFeedMutation,
  useEditCommentFeedMutation,
  useDeleteCommentMutation,
  useDeletePermissionDPMutation,
  useUpdatePermissionDPMutation,
  useUpdateStackColorMutation,
  useUpdateStoryActiveMutation,
  useUpdateDeckDescriptionMutation,
  useUpdateChartCaptionMutation,
  useUpdateChartStylingMutation,
  useReorderItemsMutation,
  useUpdateMetricShapeMutation,
  useUpdateMetricStylingMutation,
  useUpdateStackFormatMutation,
  useUpdateDatasetItemDefDPMutation,
  useRemoveDatasetFromDeckMutation,
  useAddDatasetToDeckMutation,
  useDuplicateDeckItemMutation,
  useCreateAnalysesMutation,
  useReplaceDatasetInDeckMutation,
  useCreateDerivedAnalysisMutation,
  useCreateDriversAnalysisMutation,
  useGetPollingDeckQuery,
  useUpdateAnalysisContentMutation,
  useGetAttrValuesOptsQuery,
  useCreateDeckItemMutation,
  useUndoRedoAnalysisContentMutation,
  useConvertToTextMutation,
} = deckApiSlice;

//
function updateItemsOrder() {
  const dispatch = store.dispatch;
  const updateQueryData = deckApiSlice.util.updateQueryData;

  dispatch(
    updateQueryData("getDeck", undefined, (draft) => {
      draft.file_deck.deck_items.ids.forEach((id, index) => {
        draft.file_deck.deck_items.entities[id].deck_item_order = index;
      });
    })
  );
}
