import { useEffect } from "react";
import { SEGMENT_WRITE_KEY } from "services/env";

const methods = [
  "trackSubmit",
  "trackClick",
  "trackLink",
  "trackForm",
  "pageview",
  "identify",
  "reset",
  "group",
  "track",
  "ready",
  "alias",
  "debug",
  "page",
  "once",
  "off",
  "on",
  "addSourceMiddleware",
  "addIntegrationMiddleware",
  "setAnonymousId",
  "addDestinationMiddleware",
];

export function useSegment() {
  useEffect(() => {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize) {
      if (analytics.invoked) {
        handleInvoketAnaly();
      } else {
        analytics.invoked = true;
        analytics.methods = methods;
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
          var t = document.createElement("script");
          t.type = "text/javascript";
          t.async = true;
          t.src =
            "https://cdn.segment.com/analytics.js/v1/" +
            key +
            "/analytics.min.js";
          var n = document.getElementsByTagName("script")[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics._writeKey = SEGMENT_WRITE_KEY;
        analytics.SNIPPET_VERSION = "4.15.2";
        analytics.load(SEGMENT_WRITE_KEY);
      }
    }
  }, []);

  function handleInvoketAnaly() {
    if (window.console && console.error) {
      console.error("Segment snippet included twice.");
    }
  }

  return null;
}
