import { useEffect } from "react";
import moment from "moment";

import { activeHelpScout, helpScoutBeaconId } from "services/constants";

export const largeScrBeaconConfig = {
  enableFabAnimation: false,
  display: { style: "icon" },
};
export const nonLargeScrBeaconConfig = {
  enableFabAnimation: false,
  display: { style: "manual" },
};

export function initBeacon() {
  if (activeHelpScout !== "true") return;
  window.Beacon("init", helpScoutBeaconId);
}

export function updateBeaconConfig(config) {
  if (activeHelpScout !== "true") return;
  window.Beacon("config", config);
}

export function updateBeaconIdentify(user) {
  if (activeHelpScout !== "true") return;

  const userObj = {};

  const {
    user_name,
    user_email,
    user_picture_secure_url,
    active,
    date_created,
    user_support_signature,
    user_plan,
    user_company,
    user_company_role,
  } = user;

  userObj["name"] = user_name;
  userObj["email"] = user_email;
  userObj["user-active"] = `${active}`;
  userObj["user-since"] = moment(date_created).format("YYYY-MM-DD");
  userObj["signature"] = user_support_signature;

  if (user_plan) {
    const { plan_name } = user_plan;

    userObj["plan-name"] = plan_name;
  }

  if (user_picture_secure_url) {
    userObj["avatar"] = user_picture_secure_url;
  }

  if (user_company) {
    const { company_id, company_name, company_logo_secure_url } = user_company;
    const { role_name } = user_company_role;

    userObj["company"] = company_name;
    userObj["company-id"] = company_id;
    userObj["company-logo"] = company_logo_secure_url;
    userObj["user-company-role"] = role_name;
  } else {
    userObj["company"] = "";
    userObj["company-id"] = "";
    userObj["user-company-role"] = "";
    userObj["company-logo"] = "";
  }

  window.Beacon("identify", userObj);
}

export function handleBeaconMenu(value) {
  if (activeHelpScout !== "true") return;

  // value is one of:
  // "open"
  // "close"
  // "toggle"
  window.Beacon(value);
}

export function useHelpScout() {
  useEffect(() => {
    if (activeHelpScout !== "true") return;
    const loadScript = async () => {
      let e = window;
      let t = document;
      let n = e.Beacon || function () {};

      function createScript() {
        const e = t.getElementsByTagName("script")[0];
        const n = t.createElement("script");
        n.type = "text/javascript";
        n.async = true;
        n.src = "https://beacon-v2.helpscout.net";
        return new Promise((resolve, reject) => {
          n.onload = resolve;
          n.onerror = reject;
          e.parentNode.insertBefore(n, e);
        });
      }

      if (!e.Beacon) {
        e.Beacon = n = function (t, n, a) {
          e.Beacon.readyQueue.push({
            method: t,
            options: n,
            data: a,
          });
        };
        n.readyQueue = [];
      }

      if (t.readyState === "complete") {
        await createScript();
      } else {
        e.addEventListener("load", createScript, false);
      }
    };

    loadScript();
  }, []);

  return null;
}
