function QrunchinLogoIcon(props) {
  return (
    <svg
      className="svg-logo-icon"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64.4838 13.7346V68.1672C64.4842 68.6703 64.3857 69.1685 64.194 69.6333C64.0024 70.0982 63.7212 70.5207 63.3667 70.8766C63.0122 71.2325 62.5912 71.5149 62.1278 71.7077C61.6644 71.9004 61.1677 71.9998 60.666 72H21.0834C20.5817 71.9998 20.085 71.9004 19.6216 71.7077C19.1582 71.5149 18.7372 71.2325 18.3827 70.8766C18.0282 70.5207 17.7471 70.0982 17.5554 69.6333C17.3637 69.1685 17.2653 68.6703 17.2656 68.1672V13.7346C17.2659 12.7194 17.6682 11.7459 18.3841 11.028C19.1 10.3102 20.0709 9.9068 21.0834 9.90656H60.666C61.6785 9.9068 62.6494 10.3102 63.3653 11.028C64.0812 11.7459 64.4835 12.7194 64.4838 13.7346Z"
        fill="var(--logo-primary-2, #deebf7)"
      />
      <path
        d="M54.7182 3.82902V58.2616C54.7184 58.7646 54.6199 59.2627 54.4281 59.7274C54.2364 60.1922 53.9553 60.6145 53.6007 60.9704C53.2462 61.3262 52.8253 61.6085 52.362 61.8012C51.8987 61.9939 51.402 62.0932 50.9004 62.0935H11.3177C10.8161 62.0932 10.3195 61.9939 9.85618 61.8012C9.39286 61.6085 8.97193 61.3262 8.61742 60.9704C8.26291 60.6145 7.98176 60.1922 7.79004 59.7274C7.59831 59.2627 7.49975 58.7646 7.5 58.2616V3.82902C7.5 2.81366 7.9022 1.83988 8.61813 1.12183C9.33407 0.403772 10.3051 0.000248436 11.3177 0H50.9004C51.913 0.000248436 52.8841 0.403772 53.6 1.12183C54.316 1.83988 54.7182 2.81366 54.7182 3.82902Z"
        fill="var(--logo-primary-1, #3d96d7)"
      />

      <path
        d="M42.8919 41.9609H30.7706C30.4507 41.9626 30.1444 42.0908 29.9182 42.3176C29.6921 42.5444 29.5642 42.8515 29.5625 43.1723V48.029C29.5642 48.3498 29.6921 48.6569 29.9182 48.8837C30.1444 49.1105 30.4507 49.2387 30.7706 49.2404H42.8919C43.2119 49.2389 43.5185 49.1109 43.7449 48.884C43.9713 48.6572 44.0992 48.3499 44.1009 48.029V43.1723C44.0992 42.8513 43.9713 42.5441 43.7449 42.3173C43.5185 42.0904 43.2119 41.9624 42.8919 41.9609Z"
        fill="var(--logo-accent, #f9cf00)"
      />
      <path
        d="M42.907 27.3746H18.9419C18.7837 27.375 18.6272 27.4066 18.4813 27.4678C18.3353 27.5289 18.2029 27.6183 18.0914 27.7308C17.98 27.8433 17.8918 27.9768 17.8319 28.1235C17.7719 28.2703 17.7415 28.4274 17.7422 28.586V33.4428C17.7415 33.6014 17.7719 33.7585 17.8319 33.9053C17.8918 34.0521 17.98 34.1855 18.0914 34.298C18.2029 34.4106 18.3353 34.4999 18.4813 34.561C18.6272 34.6222 18.7837 34.6538 18.9419 34.6542H42.907C43.0652 34.6538 43.2217 34.6222 43.3677 34.5611C43.5137 34.5 43.6463 34.4106 43.7578 34.2981C43.8693 34.1856 43.9576 34.0521 44.0176 33.9054C44.0776 33.7586 44.1082 33.6014 44.1076 33.4428V28.586C44.1082 28.4274 44.0776 28.2702 44.0176 28.1234C43.9576 27.9767 43.8693 27.8432 43.7578 27.7307C43.6463 27.6182 43.5137 27.5288 43.3677 27.4677C43.2217 27.4066 43.0652 27.375 42.907 27.3746Z"
        fill="var(--logo-item-content, #fff)"
      />
      <path
        d="M42.907 12.7968H18.9419C18.7837 12.7972 18.6272 12.8288 18.4813 12.8899C18.3353 12.9511 18.2029 13.0404 18.0914 13.153C17.98 13.2655 17.8918 13.3989 17.8319 13.5457C17.7719 13.6925 17.7415 13.8496 17.7422 14.0082V18.865C17.7418 19.0233 17.7726 19.1802 17.8327 19.3266C17.8928 19.473 17.981 19.6062 18.0924 19.7184C18.2038 19.8306 18.3362 19.9198 18.4819 19.9807C18.6277 20.0416 18.784 20.0732 18.9419 20.0735H42.907C43.0652 20.0732 43.2217 20.0415 43.3677 19.9804C43.5137 19.9193 43.6463 19.83 43.7578 19.7175C43.8693 19.6049 43.9576 19.4715 44.0176 19.3247C44.0776 19.178 44.1082 19.0208 44.1076 18.8622V14.0082C44.1082 13.8496 44.0776 13.6924 44.0176 13.5456C43.9576 13.3989 43.8693 13.2654 43.7578 13.1529C43.6463 13.0404 43.5137 12.951 43.3677 12.8899C43.2217 12.8288 43.0652 12.7972 42.907 12.7968Z"
        fill="var(--logo-item-content, #fff)"
      />
    </svg>
  );
}

export default QrunchinLogoIcon;
