function QrunchinLogo(props) {
  return (
    <svg
      className="svg-logo"
      width="280"
      height="80"
      viewBox="0 0 280 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M263.504 24.8583C260.287 24.8583 257.412 26.2667 255.429 28.4917L255.162 25.5H249.695V50.0917H255.637V36.65C255.637 33.1583 258.47 30.325 261.962 30.325C265.454 30.325 268.287 33.1583 268.287 36.65V50.0833H274.329V35.6917C274.337 29.7083 269.487 24.8583 263.504 24.8583Z"
        fill="var(--logo-text, #3d96d7)"
      />
      <path
        d="M241.259 21.7917C243.27 21.7917 244.901 20.1613 244.901 18.15C244.901 16.1388 243.27 14.5084 241.259 14.5084C239.248 14.5084 237.617 16.1388 237.617 18.15C237.617 20.1613 239.248 21.7917 241.259 21.7917Z"
        fill="var(--logo-text, #3d96d7)"
      />
      <path
        d="M244.381 25.15H238.289V49.9334H244.381V25.15Z"
        fill="var(--logo-text, #3d96d7)"
      />
      <path
        d="M223.621 24.8584C218.755 24.7167 216.013 27.2584 214.846 28.7001V14.8417H208.805V49.9334H214.846V36.7084C215.546 33.3001 217.271 30.4917 221.221 30.4167C223.788 30.3667 225.196 31.5334 225.971 32.7084C226.596 33.6501 226.88 34.7751 226.88 35.9084V49.9334H232.821C232.821 49.9334 232.821 37.5667 232.821 34.5001C232.821 31.4251 230.38 25.0501 223.621 24.8584Z"
        fill="var(--logo-text, #3d96d7)"
      />
      <path
        d="M196.297 45.2334C192.089 45.2334 189.156 42.0584 189.156 37.85C189.156 33.6417 192.331 30.225 196.539 30.225C198.422 30.225 200.156 30.9084 201.489 32.05L205.556 28.1C203.181 25.85 199.981 24.475 196.447 24.475C187.964 24.475 183.164 30.4167 183.164 37.75C183.164 45.0834 188.006 51.025 196.447 51.025C200.139 51.025 203.481 49.525 205.872 47.0917L202.047 43.1084C200.656 44.5667 198.464 45.2334 196.297 45.2334Z"
        fill="var(--logo-text, #3d96d7)"
      />
      <path
        d="M168.574 24.8583C165.357 24.8583 162.482 26.2667 160.499 28.4917L160.232 25.5H154.766V50.0917H160.707V36.65C160.707 33.1583 163.541 30.325 167.032 30.325C170.524 30.325 173.357 33.1583 173.357 36.65V50.0833H179.399V35.6917C179.416 29.7083 174.557 24.8583 168.574 24.8583Z"
        fill="var(--logo-text, #3d96d7)"
      />
      <path
        d="M143.92 39.1084C143.92 42.4917 141.178 45.2417 137.786 45.2417C134.403 45.2417 131.653 42.5 131.653 39.1084V25.4917H125.578V38.9917C125.578 40.9584 125.936 42.925 126.728 44.7334C127.87 47.3334 130.12 50.2334 134.528 50.775C134.528 50.775 140.82 51.275 143.553 50.0917C146.286 48.9084 149.845 45.1334 149.845 39.9917C149.845 34.85 149.845 25.5 149.845 25.5H143.92V39.1084Z"
        fill="var(--logo-text, #3d96d7)"
      />
      <path
        d="M118.743 32.2583C118.743 32.2583 116.551 29.8167 112.343 30.925C108.134 32.0333 108.134 36.6083 108.134 36.6083V50.0917H102.234V25.425H107.809L108.168 28.375C108.168 28.375 110.468 24.35 114.818 24.4583C119.168 24.5667 121.543 26.9 121.543 26.9L118.743 32.2583Z"
        fill="var(--logo-text, #3d96d7)"
      />
      <path
        d="M82.9771 24.1583C75.5187 24.1583 69.4688 30.2083 69.4688 37.6667C69.4688 45.125 75.5187 51.175 82.9771 51.175C85.8021 51.175 88.4188 50.3083 90.5854 48.825V64.9417H96.4854V37.6667C96.4854 30.2167 90.4271 24.1583 82.9771 24.1583ZM83.0438 45.2083C78.8938 45.2083 75.5271 41.8417 75.5271 37.6917C75.5271 33.5417 78.8938 30.175 83.0438 30.175C87.1938 30.175 90.5604 33.5417 90.5604 37.6917C90.5604 41.85 87.1938 45.2083 83.0438 45.2083Z"
        fill="var(--logo-text, #3d96d7)"
      />

      <path
        d="M59.0704 19.0326V70.6831C59.0708 71.1605 58.9773 71.6332 58.7955 72.0743C58.6136 72.5154 58.3468 72.9163 58.0104 73.254C57.674 73.5917 57.2746 73.8597 56.8349 74.0426C56.3952 74.2255 55.9238 74.3198 55.4478 74.32H17.8882C17.4122 74.3198 16.9409 74.2255 16.5012 74.0426C16.0615 73.8597 15.662 73.5917 15.3256 73.254C14.9892 72.9163 14.7225 72.5154 14.5406 72.0743C14.3587 71.6332 14.2653 71.1605 14.2656 70.6831V19.0326C14.2659 18.0693 14.6476 17.1455 15.3269 16.4644C16.0062 15.7832 16.9275 15.4004 17.8882 15.4002H55.4478C56.4085 15.4004 57.3298 15.7832 58.0091 16.4644C58.6884 17.1455 59.0702 18.0693 59.0704 19.0326Z"
        fill="var(--logo-primary-2, #deebf7)"
      />
      <path
        d="M49.8048 9.63331V61.2838C49.805 61.7611 49.7115 62.2337 49.5296 62.6747C49.3477 63.1157 49.0809 63.5165 48.7445 63.8541C48.4081 64.1917 48.0087 64.4596 47.569 64.6425C47.1294 64.8253 46.6581 64.9196 46.1822 64.9198H8.62261C8.14665 64.9196 7.6754 64.8253 7.23576 64.6425C6.79611 64.4596 6.3967 64.1917 6.0603 63.8541C5.72391 63.5165 5.45714 63.1157 5.27521 62.6747C5.09329 62.2337 4.99977 61.7611 5 61.2838V9.63331C5 8.66985 5.38164 7.74584 6.06099 7.06449C6.74033 6.38313 7.66176 6.00024 8.62261 6H46.1822C47.143 6.00024 48.0645 6.38313 48.7438 7.06449C49.4232 7.74584 49.8048 8.66985 49.8048 9.63331Z"
        fill="var(--logo-primary-1, #3d96d7)"
      />

      <path
        d="M38.5856 45.8162H27.0839C26.7803 45.8179 26.4897 45.9395 26.2751 46.1547C26.0604 46.3699 25.9391 46.6613 25.9375 46.9657V51.5742C25.9391 51.8786 26.0604 52.17 26.2751 52.3852C26.4897 52.6004 26.7803 52.7221 27.0839 52.7237H38.5856C38.8893 52.7223 39.1802 52.6008 39.395 52.3855C39.6098 52.1703 39.7312 51.8787 39.7329 51.5742V46.9657C39.7312 46.6612 39.6098 46.3696 39.395 46.1544C39.1802 45.9392 38.8893 45.8176 38.5856 45.8162Z"
        fill="var(--logo-accent, #f9cf00)"
      />
      <path
        d="M38.5973 31.9755H15.8571C15.7071 31.9758 15.5586 32.0059 15.4201 32.0639C15.2816 32.1219 15.1559 32.2067 15.0501 32.3134C14.9444 32.4202 14.8607 32.5468 14.8038 32.6861C14.747 32.8254 14.7181 32.9745 14.7188 33.125V37.7335C14.7181 37.884 14.747 38.0331 14.8038 38.1724C14.8607 38.3116 14.9444 38.4383 15.0501 38.545C15.1559 38.6518 15.2816 38.7366 15.4201 38.7946C15.5586 38.8526 15.7071 38.8826 15.8571 38.883H38.5973C38.7474 38.8826 38.896 38.8526 39.0345 38.7946C39.1731 38.7366 39.2988 38.6518 39.4046 38.5451C39.5105 38.4383 39.5942 38.3117 39.6512 38.1724C39.7082 38.0332 39.7372 37.884 39.7366 37.7335V33.125C39.7372 32.9744 39.7082 32.8253 39.6512 32.686C39.5942 32.5468 39.5105 32.4201 39.4046 32.3134C39.2988 32.2066 39.1731 32.1218 39.0345 32.0638C38.896 32.0059 38.7474 31.9758 38.5973 31.9755Z"
        fill="var(--logo-item-content, #fff)"
      />
      <path
        d="M38.5973 18.1428H15.8571C15.7071 18.1431 15.5586 18.1731 15.4201 18.2311C15.2816 18.2891 15.1559 18.3739 15.0501 18.4807C14.9444 18.5875 14.8607 18.7141 14.8038 18.8534C14.747 18.9926 14.7181 19.1418 14.7188 19.2922V23.9008C14.7184 24.051 14.7476 24.1999 14.8046 24.3388C14.8616 24.4778 14.9454 24.6041 15.0511 24.7106C15.1568 24.8171 15.2824 24.9016 15.4207 24.9595C15.559 25.0173 15.7073 25.0472 15.8571 25.0476H38.5973C38.7474 25.0472 38.896 25.0172 39.0345 24.9592C39.1731 24.9012 39.2988 24.8164 39.4046 24.7097C39.5105 24.6029 39.5942 24.4763 39.6512 24.337C39.7082 24.1978 39.7372 24.0486 39.7366 23.8981V19.2922C39.7372 19.1417 39.7082 18.9926 39.6512 18.8533C39.5942 18.714 39.5105 18.5874 39.4046 18.4806C39.2988 18.3739 39.1731 18.2891 39.0345 18.2311C38.896 18.1731 38.7474 18.1431 38.5973 18.1428Z"
        fill="var(--logo-item-content, #fff)"
      />
    </svg>
  );
}

export default QrunchinLogo;
