function updateDataset(state, action) {
  state.dataset = action.payload;
}

function updateDatasetUpload(state, action) {
  state.isUploading = action.payload;
}

function updateDatasetProcess(state, action) {
  state.isProcessing = action.payload;
}

function updateUploadedFile(state, action) {
  state.file = action.payload;
}

function updateDatasetError(state, action) {
  state.error = action.payload;
}

function updateDatasetConfirm(state, action) {
  state.showConfirm = action.payload;
}

function updateDatasetSourceType(state, action) {
  state.sourceType = action.payload;
}

function updateDatasetSheets(state, action) {
  state.showSheets = action.payload;
}

function updateDatasetDelete(state, action) {
  state.showDelete = action.payload;
}

const actions = {
  updateDatasetUpload,
  updateDatasetProcess,
  updateUploadedFile,
  updateDatasetError,
  updateDatasetConfirm,
  updateDataset,
  updateDatasetSourceType,
  updateDatasetSheets,
  updateDatasetDelete,
};

export default actions;
