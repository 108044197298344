import { useSelector } from "react-redux";

import InitPageLoader from "components/shared/Loaders/InitPageLoader/InitPageLoader";
import DeactivatedAccountPage from "pages/DeactivatedAccountPage/DeactivatedAccountPage";

import { useGetUserInfo } from "components/shared/UserInfoProvider/hooks";

// TODO: handle the public deck page
function UserInfoProvider({ children }) {
  const isUserDeactivated = useSelector(({ app }) => app.isUserDeactivated);

  const { loading } = useGetUserInfo();

  if (isUserDeactivated) {
    return <DeactivatedAccountPage />;
  }

  if (loading) {
    return <InitPageLoader isPage={false} />;
  }

  return <>{children}</>;
}

export default UserInfoProvider;
