import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";

import apis from "services/APIs";
import {
  FILE_DELET_FAVHS,
  FILE_OPTS_FAVHS,
  FILE_RENAME_FAVHS,
} from "services/history_states";
import { selectHistoryStateProp } from "store/slices/appSlice";

// #region create thunk
export const getStarredFilesThunk = createAsyncThunk(
  "favorites/getStarredFiles",
  async () => {
    const response = await apis.getStarredFiles();

    return response.data;
  },
  { serializeError }
);

export const addToFavoritesThunk = createAsyncThunk(
  "favorites/addToFavorites",
  async (data) => {
    const response = await apis.addToFavorites(data);

    return response.data;
  },
  { serializeError }
);

export const removeFromFavoritesThunk = createAsyncThunk(
  "favorites/removeFromFavorites",
  async (data) => {
    const response = await apis.removeFromFavorites(data);

    return response.data;
  },
  { serializeError }
);

export const patchFileNameThunk = createAsyncThunk(
  "favorites/patchFileName",
  async (data) => {
    const response = await apis.patchFileName(data);

    return response.data;
  },
  { serializeError }
);

export const deleteFileThunk = createAsyncThunk(
  "favorites/deleteFile",
  async (data) => {
    const response = await apis.deleteMultiFiles(data);

    return response.data;
  },
  { serializeError }
);

export const restoreFilesThunk = createAsyncThunk(
  "favorites/restoreFiles",
  async (data) => {
    const response = await apis.restoreFiles(data);

    return response.data;
  },
  { serializeError }
);

export const changeItemPriorityThunk = createAsyncThunk(
  "favorites/changeItemPriority",
  async (data) => {
    const response = await apis.changeItemPriority(data);

    return response.data;
  },
  { serializeError }
);

export const changeItemHideThunk = createAsyncThunk(
  "favorites/changeItemHide",
  async (data) => {
    const response = await apis.changeItemHide(data);

    return response.data;
  },
  { serializeError }
);

export const updateDatasetItemNameThunk = createAsyncThunk(
  "favorites/updateDatasetItemNameThunk",
  async (data) => {
    const response = await apis.updateDatasetItemName(data);

    return response.data;
  },
  { serializeError }
);

export const deleteMetricThunk = createAsyncThunk(
  "favorites/deleteMetricThunk",
  async (data) => {
    const response = await apis.deleteMetric(data);

    return response.data;
  },
  { serializeError }
);

export const validateDatasetThunk = createAsyncThunk(
  "favorites/validateDatasetThunk",
  async (data) => {
    const response = await apis.validateDataset(data);

    return response.data;
  },
  { serializeError }
);

export const updateDatasetItemDefThunk = createAsyncThunk(
  "favorites/updateDatasetItemDefThunk",
  async (data) => {
    const response = await apis.updateDatasetItemDef(data);

    return response.data;
  },
  { serializeError }
);

// #region Permission
export const createPermissionsThunk = createAsyncThunk(
  "favorites/createPermissions",
  async (data) => {
    const response = await apis.createPermissions(data);

    return response.data;
  },
  { serializeError }
);

export const updatePermissionThunk = createAsyncThunk(
  "favorites/updatePermission",
  async (data) => {
    const response = await apis.updatePermission(data);

    return response.data;
  },
  { serializeError }
);

export const deletePermissionThunk = createAsyncThunk(
  "favorites/deletePermission",
  async (data) => {
    await apis.deletePermission(data);

    return data.permissionId;
  },
  { serializeError }
);
// #endregion Permission

export const createDatasetAggregationThunk = createAsyncThunk(
  "favorites/createDatasetAggregation",
  async (data) => {
    const response = await apis.createDatasetAggregation(data);

    return response.data;
  },
  { serializeError }
);

export const updateFilePrivacyThunk = createAsyncThunk(
  "favorites/updateFilePrivacy",
  async (data) => {
    const response = await apis.updateFilePrivacy(data);

    return response.data;
  },
  { serializeError }
);
// #endregion create thunk

function serializeError(e) {
  if (!e.response) {
    return { response: { status: 400 } };
  }
  const { data, status } = e.response;
  return { response: { data, status } };
}

const initialState = {
  files: { data: null, loading: true, error: false },
  selectedFilesState: {
    files: [],
    lastSelectedFile: null,
  },
  fileDeletionScrState: { show: false },
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    resetFavoritesSlice: () => initialState,
    updateSelectedFiles: (state, action) => {
      state.selectedFilesState.files = action.payload;
    },
    onSelectFile: (state, action) => {
      // ALGORITHM: To handle select many files:
      // 1- Many files selection is enabled in large screen only.
      // 2- When user clicks on file and there are no selected files, add the new file.
      // 3- When user clicks on file and there are selected files:
      //    3.a. If shift, ctrl and meta keys are not clicked,
      //         update the selected files to have only the new file
      //    3.b. If ctrl key or meta key is clicked:
      //         3.b.i.  If file is already selected,
      //                 Remove the file and update the last selected file
      //         3.b.ii. If file is not already selected, add the file to selected files
      //    3.c. If shift key is clicked, select all files in between

      const { event, file, isLargeScr } = action.payload;

      const fileToSelect = parseFileToSelect(file);

      const files = state.files.data;
      const selectedFiles = state.selectedFilesState.files;
      const selectedFilesIds = state.selectedFilesState.files.map(
        ({ file_id }) => file_id
      );
      const lastSelectedFile = state.selectedFilesState.lastSelectedFile;

      state.selectedFilesState.lastSelectedFile = fileToSelect;

      // ALGORITHM 1.
      if (!isLargeScr) {
        state.selectedFilesState.files = [fileToSelect];
        return;
      }

      // ALGORITHM 2.
      if (!selectedFiles.length) {
        state.selectedFilesState.files = [fileToSelect];
        return;
      }

      const { ctrlKey, shiftKey, metaKey } = event;

      // ALGORITHM 3.a.
      if (!shiftKey && !ctrlKey && !metaKey) {
        state.selectedFilesState.files = [fileToSelect];
        return;
      }

      // ALGORITHM 3.b.
      if (ctrlKey || metaKey) {
        // ALGORITHM 3.b.i.
        const isSelected = selectedFilesIds.includes(fileToSelect.file_id);

        if (isSelected) {
          state.selectedFilesState.files = selectedFiles.filter(
            ({ file_id }) => file_id !== fileToSelect.file_id
          );
          state.selectedFilesState.lastSelectedFile =
            selectedFiles[selectedFiles.length - 2];

          return;
        }

        // ALGORITHM 3.b.ii.
        state.selectedFilesState.files.push(fileToSelect);
      }

      if (shiftKey) {
        const firstSelectedElement = lastSelectedFile
          ? lastSelectedFile
          : selectedFiles[0];
        const indexFirstSelectedElement = files.findIndex(
          ({ file_id }) => file_id === firstSelectedElement.file_id
        );

        const clickedElement = fileToSelect;
        const indexClickedElement = files.findIndex(
          ({ file_id }) => file_id === clickedElement.file_id
        );

        let startInd = Math.min(indexClickedElement, indexFirstSelectedElement);
        let endInd = Math.max(indexClickedElement, indexFirstSelectedElement);

        const newSelectedFiles = [...selectedFiles];

        files.forEach((file, index) => {
          const { file_id } = file;
          if (index >= startInd && index <= endInd) {
            const isSelected = selectedFilesIds.includes(file_id);

            if (isSelected) return;

            newSelectedFiles.push(file);
          }
        });

        state.selectedFilesState.files = newSelectedFiles;

        return;
      }
    },
    handleFileDeletionScr: (state, action) => {
      state.fileDeletionScrState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStarredFilesThunk.pending, (state) => {
      state.files = { data: null, loading: true, error: false };
    });

    builder.addCase(getStarredFilesThunk.fulfilled, (state, action) => {
      state.files = { data: action.payload, loading: false, error: false };
    });

    builder.addCase(getStarredFilesThunk.rejected, (state, action) => {
      state.files = {
        data: null,
        loading: false,
        error: action.error,
      };
    });

    builder.addCase(addToFavoritesThunk.fulfilled, (state, action) => {
      const { star_file } = action.payload;
      const { file } = action.meta.arg;

      if (star_file) {
        const newFiles = [...state.files.data, file];
        state.files.data = newFiles;
      }
    });

    builder.addCase(removeFromFavoritesThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;

      state.files.data = state.files.data.filter((file) => {
        return file.file_id !== fileId;
      });
    });

    builder.addCase(patchFileNameThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== fileId) return file;

        file.file_name = action.payload.file_name;
        return file;
      });
    });

    builder.addCase(deleteFileThunk.fulfilled, (state, action) => {
      const { value } = action.meta.arg;
      const { success } = action.payload;

      const ids = success.files.map(({ file_id }) => file_id);
      if (value) {
        state.files.data = state.files.data.filter(
          (file) => !ids.includes(file.file_id)
        );
        return;
      }
    });

    builder.addCase(restoreFilesThunk.fulfilled, (state, action) => {
      const { files } = action.meta.arg;
      const { success } = action.payload;

      const successFilesIds = success.files.map(({ file_id }) => file_id);

      const newFiles = [...state.files.data];
      files.forEach((file) => {
        if (successFilesIds.includes(file.file_id)) {
          newFiles.push(file);
        }
      });

      state.files.data = newFiles;
    });

    builder.addCase(changeItemPriorityThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;
      const { dataset_aggregations, dataset_details } = action.payload;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== fileId) return file;

        file.file_dataset.dataset_aggregations = dataset_aggregations;
        file.file_dataset.dataset_details = dataset_details;
        return file;
      });
    });

    builder.addCase(changeItemHideThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;
      const { dataset_aggregations, dataset_details } = action.payload;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== fileId) return file;

        file.file_dataset.dataset_aggregations = dataset_aggregations;
        file.file_dataset.dataset_details = dataset_details;
        return file;
      });
    });

    builder.addCase(updateDatasetItemNameThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;
      const { dataset_aggregations, dataset_details } = action.payload;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== fileId) return file;

        file.file_dataset.dataset_aggregations = dataset_aggregations;
        file.file_dataset.dataset_details = dataset_details;
        return file;
      });
    });

    builder.addCase(deleteMetricThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;
      const { dataset_aggregations, dataset_details } = action.payload;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== fileId) return file;

        file.file_dataset.dataset_aggregations = dataset_aggregations;
        file.file_dataset.dataset_details = dataset_details;
        return file;
      });
    });

    builder.addCase(validateDatasetThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;
      const { dataset } = action.payload;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== fileId) return file;

        file.file_dataset = { ...file.file_dataset, ...dataset };

        return file;
      });
    });

    builder.addCase(
      createDatasetAggregationThunk.fulfilled,
      (state, action) => {
        const { fileId } = action.meta.arg;
        const { dataset_aggregations, dataset_details } = action.payload;

        state.files.data = state.files.data.map((file) => {
          if (file.file_id !== fileId) return file;

          file.file_dataset.dataset_aggregations = dataset_aggregations;
          file.file_dataset.dataset_details = dataset_details;
          return file;
        });
      }
    );

    builder.addCase(updateDatasetItemDefThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;
      const { dataset_aggregations, dataset_details } = action.payload;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== fileId) return file;

        file.file_dataset.dataset_aggregations = dataset_aggregations;
        file.file_dataset.dataset_details = dataset_details;
        return file;
      });
    });

    // #region permissions
    builder.addCase(createPermissionsThunk.fulfilled, (state, action) => {
      const { permission_file_id } = action.meta.arg;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== permission_file_id) return file;

        const { file_permission_count } = file;
        const { file_permissions } = action.payload;

        file.file_permission_count = file_permission_count + 1;
        file.file_permissions = file_permissions;

        return file;
      });
    });

    builder.addCase(updatePermissionThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== fileId) return file;

        const { file_permissions } = file;
        const { permission_role_name, permission_user, permission_id } =
          action.payload;

        file.file_permissions = file_permissions.map((permission) => {
          if (permission_id === permission.permission_id) {
            return { permission_role_name, permission_user, permission_id };
          } else {
            return permission;
          }
        });

        return file;
      });
    });

    builder.addCase(deletePermissionThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== fileId) return file;

        const { file_permissions, file_permission_count } = file;
        const permission_id = action.payload;

        file.file_permission_count = file_permission_count - 1;
        file.file_permissions = file_permissions.filter(
          (permission) => permission.permission_id !== permission_id
        );

        return file;
      });
    });
    // #endregion permissions

    builder.addCase(updateFilePrivacyThunk.fulfilled, (state, action) => {
      const { fileId } = action.meta.arg;

      state.files.data = state.files.data.map((file) => {
        if (file.file_id !== fileId) return file;

        const { user_last_modified, date_last_modified } = action.payload;

        file.private = action.payload.private;
        file.date_last_modified = date_last_modified;
        file.user_last_modified = user_last_modified;
        return file;
      });
    });
  },
});

export const {
  resetFavoritesSlice,
  onSelectFile,
  updateSelectedFiles,
  handleFileDeletionScr,
} = favoritesSlice.actions;

export default favoritesSlice.reducer;

// Selectors
export const selectSelectedFilesState = ({ favorites }) =>
  favorites.selectedFilesState.files;

export const selectIsFileDeletionScrOpened = (state) => {
  // Check "pages/FavoritesPage/FileDeletion/FileDeletion"

  const { favorites } = state;

  const selectedFiles = favorites.selectedFilesState.files;
  const showFileDeletion = favorites.fileDeletionScrState.show;
  // ALGORITHM 1.a. and 2.a.
  if (!selectedFiles.length) return;

  if (isMobile) {
    const histState = selectHistoryStateProp(state, FILE_DELET_FAVHS);
    // ALGORITHM 1.b.
    return histState;
  }

  // ALGORITHM 2.b.
  return showFileDeletion;
};

export const selectIsFileOptsOpened = (state, showFileOptions, fileId) => {
  // Check "pages/FavoritesPage/FileOptions/FileOptions"

  if (isMobile) {
    const histState = selectHistoryStateProp(state, FILE_OPTS_FAVHS);
    // ALGORITHM 1.a.
    return histState === fileId;
  }

  // ALGORITHM 2.a.
  return showFileOptions;
};

export const selectIsFileRenameScrOpened = (state, showFileRename, fileId) => {
  // Check "pages/FavoritesPage/FileRename/FileRename"

  if (isMobile) {
    const histState = selectHistoryStateProp(state, FILE_RENAME_FAVHS);
    // ALGORITHM 1.a.
    return histState === fileId;
  }

  // ALGORITHM 2.a.
  return showFileRename;
};

// helpers
export function parseFileToSelect(file) {
  // Used to select the mandatory props only

  return file;
}
