import { useSelector } from "react-redux";

import OverdataLogo from "assets/logo/OverdataLogo";
import OverdataLogoIcon from "assets/logo/OverdataLogoIcon";
import QrunchinLogo from "assets/logo/QrunchinLogo";
import QrunchinLogoIcon from "assets/logo/QrunchinLogoIcon";

import { selectIsOverdataTheme } from "store/slices/appSlice";

// CSS prefix: .logo-
import "./style.css";

function Logo({ isIcon }) {
  const isOverdataTheme = useSelector(selectIsOverdataTheme);

  if (isOverdataTheme) {
    return isIcon ? <OverdataLogoIcon /> : <OverdataLogo />;
  }

  return isIcon ? <QrunchinLogoIcon /> : <QrunchinLogo />;
}

export default Logo;
