import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { qrunchinapiBaseUrl } from "services/constants";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: qrunchinapiBaseUrl,
    prepareHeaders,
  }),
  endpoints: () => ({}),
  keepUnusedDataFor: 0,
});

function prepareHeaders(headers, { getState }) {
  const token = getState().app.authToken;

  if (token) {
    headers.set("authorization", token);
  }
  return headers;
}

export const { updateQueryData } = apiSlice.util;
