import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
// React speech recognition
import "regenerator-runtime/runtime";

import store from "store/store";

import "overlayscrollbars/overlayscrollbars.css";
import "src/style";

import { router } from "services/react_router";

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
