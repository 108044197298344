import ReactDOM from "react-dom/client";
import App from "src/App";

import "i18n/i18n";

import {
  sentryDSN,
  sentryTracesSampleRate,
  sentryEnvironment,
  activeSentry,
} from "services/constants";

if (activeSentry === "true") {
  const { init, browserTracingIntegration } = await import("@sentry/react");
  init({
    dsn: sentryDSN,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: sentryTracesSampleRate,
    environment: sentryEnvironment,
  });
}

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(<App />);

// import * as serviceWorker from "serviceWorker";

// serviceWorker.register();

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.register("/service-worker.js").then(() => {
//     console.log("Service worker registered");
//   });
// }

// window.addEventListener("beforeinstallprompt", (event) => {
//   console.log("beforeinstallprompt");
//   event.preventDefault();
//   // window.deferredPrompt = event;

//   return false;
// });
