import { createSlice } from "@reduxjs/toolkit";

import actions from "store/slices/deckSlice/actions";

import initialState from "store/slices/deckSlice/initialState";

const deckSlice = createSlice({
  name: "deck",
  initialState,
  reducers: actions,
});

export const {
  resetDeckSlice,
  handleDeckDescEdit,
  showPageLoader,
  handleInstantEditionItem,
  handleRelatedAnalyses,
  updateNewDeckSuggestMsg,
  updateShowDeckDeletion,
  updateShowDeckEditionMenu,
  updateDeckExportScr,
  updateDeckAddItemOpts,
  updateDeckItemOpts,
  updateUndoRedoStack,
  updateUndoRedoPointer,
  decrementUndoRedoPointerBy,
  updateMetricCreationScr,
  updateCheckedSuggs,
  resetCheckedSuggs,
  updateValueAuditState,
  updateExportedItems,

  // Editors
  toggleEditorED,

  addNewMetricED,
  removeMetricED,
  updateMetricED,

  removeSplitED,
  updateSplitValuesED,
  updateSplitAttributeED,

  updateComparAttrED,
  updateComparAttrValueED,
  updateComparPercentED,
  updateComparPeriodED,
  removeComparED,

  updateRangeAttrED,
  addRangeED,
  removeRangeED,

  updateDimenAttrED,
  updateDimenAttrTimeFormatED,
  updateDimenAttrValuesED,

  addRankingED,
  updateRankingMaxTypeED,
  updateRankingMaxShowOtherED,
  updateRankingMaxDispItemsED,
  removeRankingED,

  updateDatasetED,

  updateExplanationsED,

  addFilterED,
  removeFilterED,
  updateFilterAttrED,
  updateFilterAttrValuesED,

  updateSingleInpMode,
  updateInstantEditId,
  updateInstantEditLoad,
  updateAnalyPlaceId,
  updateAnalyPlaceLoad,

  updateChatData,
  updateChatStreaming,
  addChatItem,
  updateBiqaAnswer,
  replaceBiqaItemPlaceholder,
  updatBiqaItem,
  updatBiqaItemIsComp,
  updateBiqaAnswerById,
  updateBiItemStreaming,
  resetChat,
  updateIsResetStreaming,
  removeNewBiqaItem,
  updateEditFact,
  updateEditFactBiqaId,
  updateEditFactDatasetId,
  updateEditFactId,
  updateEditFactLoad,
  updateBiqaEmbeddItem,
} = deckSlice.actions;

export default deckSlice.reducer;

// Helpers
