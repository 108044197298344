import { createSlice } from "@reduxjs/toolkit";

// Initial State
import initialState from "store/slices/trashSlice/initialState";

// Actions
import actions from "store/slices/trashSlice/actions";

// ExtraReducers
import extraReducers from "store/slices/trashSlice/extraReducers";

const trashSlice = createSlice({
  name: "trash",
  initialState,
  reducers: actions,
  extraReducers,
});

export const {
  resetTrashSlice,
  showPageLoader,
  handleFileDeletionScr,
  updateSelectedFiles,
  onSelectFile,
  updateFileToRestore,
} = trashSlice.actions;

export default trashSlice.reducer;
