import { useSelector } from "react-redux";
import { useScreenBreakpoints, useWindowHeight } from "services/hooks";
import {
  selectDeckLeftSidebarUSERSET,
  selectIsChatsPage,
  selectIsDecksPage,
  selectIsProfilePage,
} from "store/slices/appSlice";

export function useIsSidebarDisplayed() {
  const isProfilePage = useSelector(selectIsProfilePage);
  const isDeckPage = useSelector(selectIsDecksPage);
  const isChatPage = useSelector(selectIsChatsPage);
  const isSettingDeckLeftSidebar = useSelector(selectDeckLeftSidebarUSERSET);

  const windowHeight = useWindowHeight();
  const { isLargeScr, isSmallScr } = useScreenBreakpoints();

  if (isProfilePage) {
    return false;
  }

  if (isDeckPage || isChatPage) {
    if (isLargeScr) return isSettingDeckLeftSidebar;

    return !isSmallScr && windowHeight > 512 && isSettingDeckLeftSidebar;
  }

  if (isLargeScr) return true;
  return !isSmallScr && windowHeight > 512;
}
