import { createSelector, createSlice } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";
import { matchPath } from "react-router-dom";

import { getSearchParam } from "services/helpers";
import { APP_SIDEBAR_HS, SUBSCR_LIMIT_STATE_HS } from "services/history_states";
import store from "store/store";

const initialState = {
  showAppSidebar: false,
  isUserDeactivated: false,
  isInitAppLoading: true,
  subscribeLimitState: { show: false, status: null, code: null },
  newlyCreatedDataset: null,
  userInfo: null,
  historyArray: [{ location: {} }],
  authToken: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    handleAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    handleIsInitAppLoading: (state, action) => {
      state.isInitAppLoading = action.payload;
    },
    handleAppSidebar: (state, action) => {
      state.showAppSidebar = action.payload;
    },
    handleIsUserDeactivated: (state, action) => {
      state.isUserDeactivated = action.payload;
    },
    handleSubscribeLimitState: (state, action) => {
      state.subscribeLimitState = action.payload;
    },
    updateNewlyCreatedDataset: (state, action) => {
      state.newlyCreatedDataset = action.payload;
    },
    updateUserInfo: (state, action) => {
      const { user } = action.payload;

      state.userInfo = { ...state.userInfo, ...user };
    },
    updateUserCompany: (state, action) => {
      const { company } = action.payload;

      state.userInfo.user_company = company;
    },
    updateUserPlan: (state, action) => {
      const { plan } = action.payload;

      state.userInfo.user_plan = {
        ...plan,
        user_subscription: { ...plan.subscription },
      };
    },
    updateUserTheme: (state, action) => {
      state.userInfo.user_settings.display_theme = action.payload;
    },
    updateUserLandingPage: (state, action) => {
      state.userInfo.user_settings.landing_page = action.payload;
    },
    updateHistoryArray: (state, action) => {
      // const { location, historyAction } = action.payload;
      // TODO: handle POP, PUSH, REPLACE

      state.historyArray.push(action.payload);
    },
  },
});

export const {
  handleAppSidebar,
  handleIsUserDeactivated,
  handleSubscribeLimitState,
  updateNewlyCreatedDataset,
  updateUserInfo,
  updateUserTheme,
  updateUserLandingPage,
  updateUserCompany,
  updateUserPlan,
  updateHistoryArray,
  handleAuthToken,
  handleIsInitAppLoading,
} = appSlice.actions;

export default appSlice.reducer;

// Selectors
export const selectIsInitAppLoading = ({ app }) => {
  return app.isInitAppLoading;
};

export const selectIsAppSidebarOpened = ({ app }) => {
  const showAppSidebar = app.showAppSidebar;

  if (isMobile) {
    const historyArray = app.historyArray;
    const history = historyArray[historyArray.length - 1];
    const state = history.location.state || {};

    return state[APP_SIDEBAR_HS] && showAppSidebar;
  }

  return showAppSidebar;
};

export const selectSubscribeLimitState = ({ app }) => {
  return app.subscribeLimitState;
};

export const selectIsSubscribeScreenOpened = ({ app }) => {
  if (isMobile) {
    const historyArray = app.historyArray;
    const history = historyArray[historyArray.length - 1];
    const state = history.location.state || {};

    return state[SUBSCR_LIMIT_STATE_HS]?.show && app.subscribeLimitState.show;
  }

  return app.subscribeLimitState.show;
};

export const selectNewlyCreatedDataset = ({ app }) => {
  return app.newlyCreatedDataset;
};

export const selectNewlyCreatedDatasetId = ({ app }) => {
  return app.newlyCreatedDataset?.file_id;
};

// #region user information
export const selectUserInfo = ({ app }) => app.userInfo;

export const selectLogUserId = ({ app }) => app.userInfo.user_id;

export const selectLogUserName = ({ app }) => {
  return app.userInfo.user_name;
};

export const selectLogUserPictureURL = ({ app }) => {
  return app.userInfo.user_picture_secure_url;
};

export const selectIsUserOnboardRequired = ({ app }) => {
  return app.userInfo?.onboarding.onboarding_required;
};

export const selectOnboardingFields = ({ app }) => {
  return app.userInfo.onboarding.onboarding_fields;
};

export const selectUserId = ({ app }) => {
  return app.userInfo.user_id;
};

export const selectUserName = ({ app }) => {
  return app.userInfo.user_name;
};

export const selectUserEmail = ({ app }) => {
  return app.userInfo.user_email;
};

export const selectUserPwdChangeEnabled = ({ app }) => {
  return app.userInfo.pwd_change_enabled;
};

export const selectUserMfaEnabled = ({ app }) => {
  return app.userInfo.user_mfa.mfa_enabled;
};

export const selectUserPlanName = ({ app }) => {
  const userInfo = app.userInfo;

  const planName = userInfo.user_plan?.plan_name;
  return planName;
};

export const selectUserPlanStatus = ({ app }) => {
  const userInfo = app.userInfo;

  const userSubscription = userInfo.user_plan?.user_subscription;
  const status = userSubscription?.status;
  return status;
};

export const selectUserPlanTrialFrom = ({ app }) => {
  const userInfo = app.userInfo;

  const userSubscription = userInfo.user_plan?.user_subscription;
  const trialFrom = userSubscription?.trial_from;
  return trialFrom;
};

export const selectUserPlanTrialTo = ({ app }) => {
  const userInfo = app.userInfo;

  const userSubscription = userInfo.user_plan?.user_subscription;
  const trialTo = userSubscription?.trial_to;
  return trialTo;
};

export const selectUserInvitesCount = ({ app }) => {
  const userInfo = app.userInfo;
  return userInfo.user_requesting_company_additions.length;
};

// All user settings selectors names will end with 'USERSET'
export const selectDeckLeftSidebarUSERSET = ({ app }) => {
  return app.userInfo.user_settings.deck_display_left_side_bar;
};

export const selectDisplayThemeUSERSET = ({ app }) => {
  const userInfo = app.userInfo;
  if (!userInfo) return null;

  return app.userInfo.user_settings.display_theme;
};

export const selectDisplayLandPageUSERSET = ({ app }) => {
  const userInfo = app.userInfo;
  if (!userInfo) return null;

  return app.userInfo.user_settings.landing_page;
};

export const selectIsOverdataTheme = createSelector(
  selectDisplayThemeUSERSET,
  (theme) => theme === "overdata_light" || theme === "overdata_dark"
);

export const selectTipsDisplayUSERSET = ({ app }) => {
  return app.userInfo.user_settings.tips_display;
};

export const selectNewDeckAppendDateUSERSET = ({ app }) => {
  return app.userInfo.user_settings.new_deck_append_date_to_name;
};

export const selectNewDeckSuggesMarkedKeptUSERSET = ({ app }) => {
  return app.userInfo.user_settings.new_deck_suggestions_marked_as_kept;
};

export const selecNotebooktNewDeckAnalySummUSERSET = ({ app }) => {
  return app.userInfo.user_settings.new_notebook_deck_analyses_summarized;
};

export const selectDatasetValidCreationUSERSET = ({ app }) => {
  return app.userInfo.user_settings.dataset_validation_on_creation;
};

export const selectNewDeckDefaultNameUSERSET = ({ app }) => {
  return app.userInfo.user_settings.new_notebook_deck_default_name;
};

export const selectNewChatDefaultNameUSERSET = ({ app }) => {
  return app.userInfo.user_settings.new_chat_deck_default_name;
};

export const selectNewDeckSuggesCountUSERSET = ({ app }) => {
  return app.userInfo.user_settings.new_deck_suggestions_count;
};

export const selectUserReqCompAdds = ({ app }) => {
  const userInfo = app.userInfo;
  return userInfo.user_requesting_company_additions;
};

export const selectIsPlanCompany = ({ app }) => {
  const userInfo = app.userInfo;
  return userInfo.user_plan.is_company_plan;
};

// All user communications selectors names will end with 'USERCOM'
export const selectEmailNotificUSERCOM = ({ app }) => {
  return app.userInfo.user_communications.email_notifications;
};

export const selectMonthlyNewsletterUSERCOM = ({ app }) => {
  return app.userInfo.user_communications.monthly_newsletter;
};

// All user company selectors names will end with 'UCOMP'
export const selectCompanyIdUCOMP = ({ app }) => {
  const { user_company } = app.userInfo;

  if (!user_company) return null;
  return user_company.company_id;
};

export const selectCompanyNameUCOMP = ({ app }) => {
  const { user_company } = app.userInfo;

  if (!user_company) return null;
  return user_company.company_name;
};

export const selectCompanySizeUCOMP = ({ app }) => {
  const { user_company } = app.userInfo;

  if (!user_company) return null;
  return user_company.company_size;
};

export const selectCompanyLogoURLUCOMP = ({ app }) => {
  const { user_company } = app.userInfo;

  if (!user_company) return null;
  return user_company.company_logo_secure_url;
};

// All user plan capabilities will end with 'UPCAP'
export const selectCanLockUnlockUPCAP = ({ app }) => {
  const userInfo = app.userInfo;
  return userInfo.user_plan.plan_capabilities.can_lock_unlock_files;
};

// All user company selectors names will end with 'UCROLECAP'
export const selectCanActivDeactivUsersUCROLECAP = ({ app }) => {
  const { role_capabilities } = app.userInfo.user_company_role;
  return role_capabilities.can_activate_deactivate_users;
};

export const selectCanTrashUntrashUsersUCROLECAP = ({ app }) => {
  const { role_capabilities } = app.userInfo.user_company_role;
  return role_capabilities.can_trash_untrash_users;
};

export const selectCanChangeUsersCompRolesUCROLECAP = ({ app }) => {
  const { role_capabilities } = app.userInfo.user_company_role;
  return role_capabilities.can_change_users_company_roles;
};

export const selectCanChangeCompInfoUCROLECAP = ({ app }) => {
  const { role_capabilities } = app.userInfo.user_company_role;
  return role_capabilities.can_change_company_information;
};

export const selectCanInviteUsers = ({ app }) => {
  const userInfo = app.userInfo;
  const { user_company_role, user_plan } = userInfo;

  const can_invite_users_to_app =
    user_plan.plan_capabilities.can_invite_users_to_app;
  const can_add_users_to_company =
    user_company_role.role_capabilities.can_add_users_to_company;

  return can_invite_users_to_app && can_add_users_to_company;
};

// #region settings page
export const selectThirdPartyModelValue = ({ app }) => {
  const userInfo = app.userInfo;
  // If user is not in company:
  // [user_settings][third_party_llm_enabled]
  // If user is company:
  // [user_company][company_settings][third_party_llm_enabled]

  if (!userInfo) return;

  let value;

  const isUserInComp = !!userInfo.user_company;

  if (isUserInComp) {
    value = userInfo.user_company.company_settings.third_party_llm_enabled;
  }

  if (!isUserInComp) {
    value = userInfo.user_settings.third_party_llm_enabled;
  }

  return value;
};

// Used to check if the user can access 'third party model' option in privacy tab
export const selectIsThirdPartyModelAccess = ({ app }) => {
  const userInfo = app.userInfo;
  // ALGORITHM: The algorithm to access  'Third-party models' section
  // 1- If the user is not in company
  // 2- If user is in company AND
  //    [user_company_role][role_capabilities][can_change_company_third_party_llm_settings]

  let isThirdPartyModelAccess = false;

  const isUserInComp = !!userInfo.user_company;

  if (isUserInComp) {
    const { can_change_company_third_party_llm_settings } =
      userInfo.user_company_role.role_capabilities;

    isThirdPartyModelAccess = can_change_company_third_party_llm_settings;
  }

  if (!isUserInComp) {
    isThirdPartyModelAccess = true;
  }

  return isThirdPartyModelAccess;
};

export const selectPublicFileSharingValue = ({ app }) => {
  // [user_company][company_settings][public_file_sharing_enabled]

  // Check is there user information because this selector
  // will be used in public page
  const userInfo = app.userInfo;
  if (!userInfo) return null;

  const { user_company } = app.userInfo;
  if (!user_company) return null;

  return user_company.company_settings.public_file_sharing_enabled;
};

// Used to check if the user can access 'public file sharing' option in privacy tab
export const selectIsPublicFileSharingAccess = ({ app }) => {
  const userInfo = app.userInfo;
  // ALGORITHM: The algorithm to access  'Public file sharing' section
  // 1- If user is in company AND
  //    [user_company_role][role_capabilities][can_change_company_public_file_sharing]

  let isPublicFileSharingAccess = false;

  const isUserInComp = !!userInfo.user_company;

  if (isUserInComp) {
    const { can_change_company_public_file_sharing } =
      userInfo.user_company_role.role_capabilities;

    isPublicFileSharingAccess = can_change_company_public_file_sharing;
  }

  return isPublicFileSharingAccess;
};

// Used to check if the user can access 'Organization' tab in settings page
export const selectIsCompanyTabAccessible = ({ app }) => {
  const userInfo = app.userInfo;
  return userInfo.user_plan.is_company_plan;
};

// Used to check if the user can access 'Audit logs' tab in settings page
export const selectIsAuditLogsTabAccessible = ({ app }) => {
  const userInfo = app.userInfo;
  // ALGORITHM: The algorithm to access audit logs
  // 1- If user is not in company, he can access audit logs
  //    by [user_plan][plan_capabilities][can_access_auditlog]
  // 2- If user is in company, he can access audit logs
  //    by [user_plan][plan_capabilities][can_access_auditlog] and [user_company_role][role_capabilities][can_view_company_auditlog]

  const { user_company, user_plan, user_company_role } = userInfo;
  const { can_access_auditlog } = user_plan.plan_capabilities;

  // ALGORITHM.1
  if (!user_company) {
    return can_access_auditlog;
  }

  const { can_view_company_auditlog } = user_company_role.role_capabilities;
  // ALGORITHM.2
  return can_access_auditlog && can_view_company_auditlog;
};
// #endregion settings page

// Used to get user data for Segment group association
export const selectUserInfoSegmentGroupAssoc = createSelector(
  selectUserInfo,
  (userInfo) => {
    const { user_plan, user_company } = userInfo;

    const groupId = user_company?.company_id;
    const name = user_company?.company_name;
    const avatar = user_company?.company_logo_secure_url;
    const createdAt = user_company?.date_created;
    const plan = user_plan?.plan_name;

    return { groupId, name, plan, avatar, createdAt };
  }
);

// Used to get user data for Segment user identify
export const selectSegmentUserIdentify = createSelector(
  selectUserInfo,
  (userInfo) => {
    const { onboarding_required } = userInfo.onboarding;
    const {
      user_id,
      user_name,
      user_email,
      user_picture_secure_url,
      active,
      date_created,
      user_plan,
      user_company,
      user_company_role,
    } = userInfo;

    const plan = user_plan?.plan_name;
    const groupId = user_company?.company_id;
    const userCompanyRole = user_company_role?.role_name;

    return {
      onboarding_required,
      plan,
      groupId,
      userCompanyRole,
      user_id,
      user_name,
      user_email,
      user_picture_secure_url,
      active,
      date_created,
    };
  }
);

// Used to get user data for Pendo user information
export const selectPendoRegisterUserInfo = createSelector(
  selectUserInfo,
  (userInfo) => {
    const {
      user_id,
      user_name,
      user_email,
      user_picture_secure_url,
      date_created,
      user_plan,
      user_company,
      user_company_role,
    } = userInfo;

    const plan = user_plan?.plan_name;
    const companyId = user_company?.company_id;
    const companyName = user_company?.company_name;
    const companyCreatedAt = user_company?.date_created;
    const userCompanyRole = user_company_role?.role_name;

    return {
      user_id,
      user_name,
      user_email,
      user_picture_secure_url,
      date_created,
      plan,
      companyId,
      companyName,
      companyCreatedAt,
      userCompanyRole,
    };
  }
);
// #endregion user information

// #region history selectors
export const selectHistoryArray = ({ app }) => app.historyArray;

const selectCurrHistory = createSelector(selectHistoryArray, (historyArray) => {
  return historyArray[historyArray.length - 1];
});

export const selectHistoryPathname = createSelector(
  selectCurrHistory,
  (history) => history.location.pathname
);

export const selectHistorySearch = createSelector(
  selectCurrHistory,
  (history) => history.location.search
);

export const selectHistoryHash = createSelector(
  selectCurrHistory,
  (history) => history.location.hash
);

export const selectHistoryState = createSelector(
  selectCurrHistory,
  (history) => history.location.state || {}
);

export const selectHistoryStateProp = ({ app }, prop) => {
  const historyArray = app.historyArray;
  const history = historyArray[historyArray.length - 1];
  const state = history.location.state;

  if (!state) return;

  return state[prop];
};

export const selectHistorySearchParams = ({ app }) => {
  const historyArray = app.historyArray;
  const history = historyArray[historyArray.length - 1];

  return new URLSearchParams(history.location.search);
};

export const selectHistorySearchParam = ({ app }, param) => {
  const historyArray = app.historyArray;
  const history = historyArray[historyArray.length - 1];
  const search = history.location.search;

  return getSearchParam(search, param);
};
// #endregion history selectors

export const selectIsHomePage = createSelector(
  selectHistoryPathname,
  (pathname) => matchPath("/", pathname)
);

export const selectIsProfilePage = createSelector(
  selectHistoryPathname,
  (pathname) => matchPath("/profile", pathname)
);

export const selectIsDecksPage = createSelector(
  selectHistoryPathname,
  (pathname) => matchPath("/decks/*", pathname)
);

export const selectIsChatPage = createSelector(
  selectHistoryPathname,
  (pathname) => matchPath("/chat", pathname)
);

export const selectIsChatsPage = createSelector(
  selectHistoryPathname,
  (pathname) => matchPath("/chats/*", pathname)
);

export const selectIsFilesPage = createSelector(
  selectHistoryPathname,
  (pathname) => matchPath("/files/*", pathname)
);

export const selectChatsParamId = createSelector(
  selectHistoryPathname,
  (pathname) => matchPath("/chats/:file_id", pathname)?.params["file_id"]
);

// TODO: Add comments
// Return error status code
export function handleUpdateUserRespFailure(res) {
  const isAllRespFailed = res.user_update_responses_list.every(
    ({ user_update_success }) => !user_update_success
  );

  // If all responses are failed, return the status code of first response
  if (isAllRespFailed) {
    return res.user_update_responses_list[0].status_code;
  }

  return null;
}

export function handleUpdateCompRespFailure(res) {
  const isAllRespFailed = res.company_update_responses_list.every(
    ({ company_update_success }) => !company_update_success
  );

  // If all responses are failed, return the status code of first response
  if (isAllRespFailed) {
    return res.company_update_responses_list[0].status_code;
  }

  return null;
}

// TODO: create isPrivacyTabAccess

export function getAuthToken() {
  const storeState = store.getState();

  return storeState.app.authToken;
}

export function getHistoryState() {
  const storeState = store.getState();

  return selectHistoryState(storeState);
}
