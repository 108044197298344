import { Editor, Transforms, Text, Node, Element as SlateElement } from "slate";

const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const emptySlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

export const toggleFormat = (editor, format) => {
  const isActive = isFormatActive(editor, format);
  Transforms.setNodes(
    editor,
    { [format]: isActive ? null : true },
    { match: Text.isText, split: true }
  );
};

export const isFormatActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const isBlockActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

export function serialize(nodes) {
  return nodes.map((n) => Node.string(n)).join("\n");
}

export function clearEditor(editor) {
  editor.children.forEach(() => {
    Transforms.removeNodes(editor);
  });

  if (!editor.children.length) {
    Transforms.insertNodes(editor, emptySlateValue);
  }
}

export function resetEditorWithValue(editor, value) {
  if (!editor) {
    return;
  }

  let newValue = value;

  if (!value?.length) {
    newValue = emptySlateValue;
  }

  Transforms.select(editor, {
    anchor: Editor.start(editor, []),
    focus: Editor.end(editor, []),
  });
  Transforms.removeNodes(editor);

  Transforms.insertNodes(editor, newValue);
}
