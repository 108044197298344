import { useAuth0 } from "@auth0/auth0-react";
import { matchPath } from "react-router-dom";

import { getSearchParam } from "services/helpers";

export function useLogin() {
  const { pathname, search } = window.location;
  let isSignupPage = matchPath("/signup", pathname);
  const searParamPlan = getSearchParam(search, "plan");

  const { loginWithRedirect } = useAuth0();

  function login() {
    // Send params to login/signup page as appState
    const appState = { plan: searParamPlan };

    if (isSignupPage) {
      loginWithRedirect({ screen_hint: "signup", appState });
      return;
    }

    loginWithRedirect({ appState });
  }

  return [login];
}
