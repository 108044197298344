import { getAttributeValuesOpts } from "store/slices/deckSlice/helpers";
import initialState from "store/slices/deckSlice/initialState";

function resetDeckSlice() {
  return initialState;
}

function updateExportedItems(state, action) {
  state.exportedItems = action.payload;
}

function handleDeckDescEdit(state, action) {
  state.deckDescEdit = action.payload;
}

function showPageLoader(state) {
  state.deck.loading = true;
  state.deck.error = false;
}

function handleRelatedAnalyses(state, action) {
  state.relatedAnalyses = action.payload;
}

function updateNewDeckSuggestMsg(state, action) {
  state.newDeckSuggestMsg = action.payload;
}

function updateShowDeckDeletion(state, action) {
  state.showDeckDeletion = action.payload;
}

function updateShowDeckEditionMenu(state, action) {
  state.showDeckEditionMenu = action.payload;
}

function updateMetricCreationScr(state, action) {
  state.metricCreationScr = action.payload;
}

function updateDeckExportScr(state, action) {
  state.deckExportScr = action.payload;
}

function updateDeckItemOpts(state, action) {
  state.deckItemOpts = action.payload;
}

function updateValueAuditState(state, action) {
  state.valueAuditState = action.payload;
}

function updateDeckAddItemOpts(state, action) {
  state.deckAddItemOpts = action.payload;
}

function updateUndoRedoPointer(state, action) {
  state.undoPointer = action.payload.pointer;
}

function decrementUndoRedoPointerBy(state, action) {
  state.undoPointer -= action.payload;
}

function updateUndoRedoStack(state, action) {
  if (state.undoPointer !== state.undoStack.length - 1) {
    state.undoStack.splice(state.undoPointer + 1);
  }

  state.undoStack.push(action.payload);

  state.undoPointer = state.undoStack.length - 1;
}

// #region AllSuggestionsMenu
function updateCheckedSuggs(state, action) {
  const { isChecked, index } = action.payload;

  if (isChecked) {
    state.allSuggesMenuState.checked.push(index);
    return;
  }

  state.allSuggesMenuState.checked = state.allSuggesMenuState.checked.filter(
    (checkId) => index !== checkId
  );
}

function resetCheckedSuggs(state) {
  state.allSuggesMenuState.checked = [];
}
// #endregion AllSuggestionsMenu

// #region editor
function toggleEditorED(state, action) {
  const { item, show } = action.payload;

  const deckItemId = item.deck_item_id;
  if (!state.editors[deckItemId]) {
    state.editors[deckItemId] = createNewEditor(item);
  }
  state.editors[deckItemId].show = show;
}

function addNewMetricED(state, action) {
  const { deckItemId, newMetric } = action.payload;

  const editorState = state.editors[deckItemId];
  const { metrics } = editorState;

  editorState.metrics = [...metrics, newMetric];
  // 'Ranking' is only enabled with one metric.
  // So it is deactivated when add new metric
  editorState.ranking = { max_active: false };
  editorState.isChanged = true;
}

function removeMetricED(state, action) {
  const { deckItemId, index } = action.payload;

  const editorState = state.editors[deckItemId];
  const { metrics } = editorState;

  const newMetrics = [...metrics];
  newMetrics.splice(index, 1);

  editorState.metrics = newMetrics;
  editorState.isChanged = true;
}

function updateMetricED(state, action) {
  const { deckItemId, index, metric } = action.payload;

  const editor = state.editors[deckItemId];
  const { metrics } = editor;

  const { aggregation_display_name, aggregation_id } = metric;
  const updatedMetric = {
    metric_order: metrics.length,
    metric_components: [
      {
        aggregation_display_name,
        aggregation_id,
        component_type: "aggregation",
      },
    ],
    comparison: { comparison_active: false },
    stacks: { stacks_active: false },
  };

  const newMetrics = [...metrics];
  newMetrics[index] = updatedMetric;

  editor.metrics = newMetrics;
  editor.isChanged = true;
}

function removeSplitED(state, action) {
  const { deckItemId, metricIndex } = action.payload;
  const editorState = state.editors[deckItemId];
  const { metrics, filters } = editorState;

  const metric = metrics[metricIndex];
  const stacksAttrId = metric.stacks.attribute_detail_id;

  // Remove stacks attribute
  const newMetrics = [...metrics];
  newMetrics[metricIndex] = {
    ...metric,
    stacks: { stacks_active: false },
  };

  // Remove stacks filter values
  const newFilters = filters.filter((filter) => {
    return filter.attribute_detail_id !== stacksAttrId;
  });

  editorState.metrics = newMetrics;
  editorState.filters = newFilters;
  editorState.isChanged = true;
}

function updateSplitValuesED(state, action) {
  const { deckItemId, attribute, values, skipChanges } = action.payload;

  const editorState = state.editors[deckItemId];
  const { filters } = editorState;

  let isInFilter = false;
  const newFilters = filters.map((filter) => {
    if (filter.attribute_detail_id === attribute.attribute_detail_id) {
      isInFilter = true;
      const newFilter = { ...filter };
      newFilter.filter_values = values;

      return newFilter;
    }

    return filter;
  });

  if (!isInFilter) {
    const newFilter = {
      attribute_detail_id: attribute.attribute_detail_id,
      attribute_display_name: attribute.attribute_display_name,
      filter_values: values,
    };

    newFilters.push(newFilter);
  }

  editorState.filters = newFilters;
  // TODO
  if (!skipChanges) {
    editorState.isChanged = true;
  }
}

function updateSplitAttributeED(state, action) {
  const { deckItemId, metricIndex, attribute, format } = action.payload;

  const editorState = state.editors[deckItemId];
  const { metrics, filters } = editorState;

  // 1- Add stacks for metric
  const stacks = {
    stacks_active: true,
    attribute_detail_id: attribute.attribute_detail_id,
    attribute_display_name: attribute.attribute_display_name,
    is_time_attribute: attribute.is_time_attribute,
  };

  // If there is no new format is passed use the default format
  // if the attribute is time attribute
  if (format || attribute.is_time_attribute) {
    stacks.format = format || attribute.format;
  }

  // 2-
  // The split attribute can't be used as additional filters
  // So, the new attribute must be removed from filters
  // Note: [analysis_filters_additional] contains filters for
  //       both stacks and comparison also.
  let newFilters = [];
  if (filters.length) {
    newFilters = filters.reduce((acc, filter) => {
      if (attribute.attribute_detail_id !== filter.attribute_detail_id) {
        acc.push(filter);
      } else {
        const newFilter = { ...filter, filter_values: [] };
        if (format) {
          newFilter.format = format;
        }
        acc.push(newFilter);
      }
      return acc;
    }, []);
  } else {
    const newFilter = {
      attribute_detail_id: attribute.attribute_detail_id,
      attribute_display_name: attribute.attribute_display_name,
      filter_values: [],
    };

    if (format) {
      newFilter.format = format;
    }

    newFilters.push(newFilter);
  }

  // 3- update metric
  const newMetrics = [...metrics];
  const updatedMetric = {
    ...metrics[metricIndex],
    stacks,
  };
  newMetrics[metricIndex] = updatedMetric;

  // 4- Update editor state
  editorState.metrics = newMetrics;
  editorState.filters = newFilters;
  editorState.isChanged = true;
}

function updateComparAttrED(state, action) {
  const { deckItemId, metricIndex, attribute, format, periods, dataset } =
    action.payload;

  const editorState = state.editors[deckItemId];
  const { metrics, filters } = editorState;

  // 1- update comparison for metric
  const {
    attribute_display_name,
    attribute_detail_id,
    time_attribute_details,
  } = attribute;

  // Get new period

  let period;

  if (!periods) {
    const { available_time_dimensions } = time_attribute_details;
    const defaultTimeDime = available_time_dimensions.find(
      ({ is_default_time_dimension }) => is_default_time_dimension
    );

    period = defaultTimeDime.period_comparison_types[0];
  } else {
    period = periods[0];
  }

  const { comparison_type, shift_forward_offset, shift_period } = period;

  const comparison_value_to = getAttributeValuesOpts(
    dataset,
    attribute_detail_id
  )[0];

  const comparison = {
    attribute_display_name,
    attribute_detail_id,
    comparison_active: true,
    comparison_value_to,
    comparison_type,
    shift_forward_offset,
    shift_period,
    percentage: false,
  };

  // If there is no new format is passed use the default format
  comparison.format = format || attribute.format;

  const updatedMetric = {
    ...metrics[metricIndex],
    comparison,
  };

  const newMetrics = [...metrics];
  newMetrics[metricIndex] = updatedMetric;

  // 2-
  // The comparison attribute can't be used as additional filters or stacks attribute
  // So, the new attribute must be removed from filters
  // Note: [analysis_filters_additional] contains filters for
  //       both stacks and comparison also.
  // Two different comparisons for two metrics can have the same attribute
  let newFilters = [];
  if (filters.length) {
    newFilters = filters.filter((filter) => {
      return attribute.attribute_detail_id !== filter.attribute_detail_id;
    }, []);
  }

  // 4- Update editor state
  editorState.metrics = newMetrics;
  editorState.filters = newFilters;
  editorState.isChanged = true;
}

function updateComparAttrValueED(state, action) {
  const { deckItemId, metricIndex, value } = action.payload;

  const editorState = state.editors[deckItemId];
  const { metrics } = editorState;

  const metric = metrics[metricIndex];
  const comparison = metric.comparison;

  const newMetrics = [...metrics];
  const updatedMetric = {
    ...metric,
    comparison: { ...comparison, comparison_value_to: value.label },
  };
  newMetrics[metricIndex] = updatedMetric;

  editorState.metrics = newMetrics;
  editorState.isChanged = true;
}

function updateDimenAttrED(state, action) {
  const { deckItemId, attribute, values } = action.payload;

  const editorState = state.editors[deckItemId];
  const { metrics, filters } = editorState;

  // 1- Create new dimension
  const newDimension = {
    attribute_detail_id: attribute.attribute_detail_id,
    attribute_display_name: attribute.attribute_display_name,
    is_time_attribute: attribute.is_time_attribute,
    max_items: { max_active: false },
    filter_values: values,
  };

  if (attribute.is_time_attribute) {
    newDimension.format = attribute.format;
  }

  // 2-
  // The dimension attribute can't be used as additional filters
  // So, the new attribute must be removed from filters
  // Note: analysis_filters_additional contains filters for
  //       both stacks and comparison also.
  let newFilters = filters;
  if (filters.length) {
    const stacksCompAttrs = metrics.reduce((acc, curr) => {
      const { stacks, comparison } = curr;
      if (stacks.attribute_detail_id) {
        acc.push(stacks.attribute_detail_id);
      }
      if (comparison.attribute_detail_id) {
        acc.push(comparison.attribute_detail_id);
      }
      return acc;
    }, []);

    newFilters = filters.filter((filter) => {
      const { attribute_detail_id } = filter;
      const isStacksCompFilter = stacksCompAttrs.includes(attribute_detail_id);
      if (isStacksCompFilter) {
        return true;
      }

      return attribute.attribute_detail_id !== filter.attribute_detail_id;
    });
  }

  // 3- update editor state
  editorState.dimension = newDimension;
  editorState.filters = newFilters;
  editorState.isChanged = true;
}

function updateDimenAttrValuesED(state, action) {
  const { deckItemId, values, skipChanges } = action.payload;

  const editorState = state.editors[deckItemId];
  const { dimension } = editorState;

  editorState.dimension = { ...dimension, filter_values: values };

  if (!skipChanges) {
    editorState.isChanged = true;
  }
}

function updateDimenAttrTimeFormatED(state, action) {
  const { deckItemId, attribute, format, values } = action.payload;

  const editorState = state.editors[deckItemId];

  const newDimension = {
    attribute_detail_id: attribute.attribute_detail_id,
    attribute_display_name: attribute.attribute_display_name,
    is_time_attribute: attribute.is_time_attribute,
    max_items: { max_active: false },
    format,
    filter_values: values,
  };

  editorState.dimension = newDimension;
  editorState.isChanged = true;
}

function updateComparPercentED(state, action) {
  const { deckItemId, metricIndex, percentage } = action.payload;

  const editorState = state.editors[deckItemId];
  const { metrics } = editorState;

  const metric = metrics[metricIndex];
  const comparison = metric.comparison;

  const updatedMetric = {
    ...metric,
    comparison: {
      ...comparison,
      percentage,
    },
  };

  const newMetrics = [...metrics];
  newMetrics[metricIndex] = updatedMetric;
  editorState.metrics = newMetrics;
  editorState.isChanged = true;
}

function updateComparPeriodED(state, action) {
  const { deckItemId, metricIndex, period } = action.payload;

  const editorState = state.editors[deckItemId];
  const { metrics } = editorState;

  const metric = metrics[metricIndex];
  const comparison = metric.comparison;

  const { comparison_type, shift_forward_offset, shift_period } = period;

  const updatedMetric = {
    ...metric,
    comparison: {
      ...comparison,
      comparison_type,
      shift_forward_offset,
      shift_period,
    },
  };

  const newMetrics = [...metrics];
  newMetrics[metricIndex] = updatedMetric;

  editorState.metrics = newMetrics;
  editorState.isChanged = true;
}

function removeComparED(state, action) {
  const { deckItemId, metricIndex } = action.payload;
  const editorState = state.editors[deckItemId];
  const { metrics, filters } = editorState;

  const metric = metrics[metricIndex];
  const stacksAttrId = metric.comparison.attribute_detail_id;

  // Remove comparison attribute
  const newMetrics = [...metrics];
  newMetrics[metricIndex] = {
    ...metric,
    comparison: { comparison_active: false },
  };

  // Remove comparison filter values
  const newFilters = filters.filter((filter) => {
    return filter.attribute_detail_id !== stacksAttrId;
  });

  editorState.metrics = newMetrics;
  editorState.filters = newFilters;
  editorState.isChanged = true;
}

function updateRangeAttrED(state, action) {
  const {
    deckItemId,
    format,
    attribute_detail_id,
    attribute_display_name,
    range_lower_value,
    range_upper_value,
  } = action.payload;
  const editorState = state.editors[deckItemId];

  const newRange = {
    attribute_detail_id,
    attribute_display_name,
    range_active: true,
    range_lower_value,
    range_upper_value,
  };

  if (format) {
    newRange.format = format;
  }

  editorState.range = newRange;
  editorState.isChanged = true;
}

function addRangeED(state, action) {
  const { deckItemId, newRange } = action.payload;

  const editorState = state.editors[deckItemId];

  editorState.range = newRange;
  editorState.isChanged = true;
}

function removeRangeED(state, action) {
  const { deckItemId } = action.payload;

  const editorState = state.editors[deckItemId];

  const newRange = {
    range_active: false,
  };

  editorState.range = newRange;
  editorState.isChanged = true;
}

function addRankingED(state, action) {
  const { deckItemId } = action.payload;

  const editorState = state.editors[deckItemId];

  const ranking = {
    max_active: true,
    max_type: "top items",
    max_displayed_items: 10,
    max_show_other: true,
  };

  editorState.ranking = ranking;
  editorState.isChanged = true;
}

function updateRankingMaxTypeED(state, action) {
  const { deckItemId, max_type } = action.payload;

  const editorState = state.editors[deckItemId];
  const { ranking } = editorState;

  const updatedRanking = { ...ranking, max_type };
  editorState.ranking = updatedRanking;
  editorState.isChanged = true;
}

function updateRankingMaxShowOtherED(state, action) {
  const { deckItemId, max_show_other } = action.payload;

  const editorState = state.editors[deckItemId];
  const { ranking } = editorState;

  const updatedRanking = { ...ranking, max_show_other };
  editorState.ranking = updatedRanking;
  editorState.isChanged = true;
}

function updateRankingMaxDispItemsED(state, action) {
  const { deckItemId, max_displayed_items } = action.payload;

  const editorState = state.editors[deckItemId];
  const { ranking } = editorState;

  const updatedRanking = { ...ranking, max_displayed_items };
  editorState.ranking = updatedRanking;
  editorState.isChanged = true;
}

function removeRankingED(state, action) {
  const { deckItemId } = action.payload;

  const editorState = state.editors[deckItemId];

  const ranking = { max_active: false };

  editorState.ranking = ranking;
  editorState.isChanged = true;
}

function updateDatasetED(state, action) {
  const { deckItemId, dataset } = action.payload;

  const { dataset_aggregations, dataset_details } = dataset;

  const firstMetric = dataset_aggregations[0].items[0];

  const metrics = [
    {
      metric_components: [
        {
          aggregation_display_name: firstMetric.aggregation_display_name,
          aggregation_id: firstMetric.aggregation_id,
          component_type: "aggregation",
        },
      ],

      comparison: {
        comparison_active: false,
      },
      stacks: {
        stacks_active: undefined,
      },
    },
  ];

  const dimensions = dataset_details.filter(({ is_attribute }) => is_attribute);

  const dimension = {
    attribute_display_name: dimensions[0].display_name,
    attribute_detail_id: dimensions[0].detail_id,
    filter_values: [],
    format: null,
    ...dimensions[0],
  };

  const editorState = {
    ...state.editors[deckItemId],
    datasetFileId: dataset.dataset_file_id,
    metrics,
    dimension,
    filters: [],
    range: { range_active: false },
    explanations: [],
    ranking: { max_active: false },
  };

  state.editors[deckItemId] = {
    show: true,
    isChanged: true,
    ...editorState,
  };
}

function updateExplanationsED(state, action) {
  const { deckItemId, index } = action.payload;

  const editorState = state.editors[deckItemId];
  const { explanations } = editorState;

  const newExplanations = [...explanations];
  newExplanations.splice(index, 1);

  editorState.explanations = newExplanations;
  editorState.isChanged = true;
}

function addFilterED(state, action) {
  const { deckItemId } = action.payload;

  const editorState = state.editors[deckItemId];
  const { dataset, dimension, metrics, filters } = editorState;

  // 1-
  // Get disabled options for filters
  // Dimension attribute
  // Stacks and Comparison attributes
  // Other filters
  const disabledAttrs = [dimension.attribute_display_name];

  filters.forEach(({ attribute_display_name }) => {
    disabledAttrs.push(attribute_display_name);
  });

  metrics.forEach((metric) => {
    const { stacks, comparison } = metric;
    disabledAttrs.push(stacks.attribute_display_name);
    disabledAttrs.push(comparison.attribute_display_name);
  });

  // 2-
  // Get attribute for new filter
  const attribute = dataset.dataset_details.find(
    ({ is_attribute, display_name }) => {
      return is_attribute && !disabledAttrs.includes(display_name);
    }
  );

  // 3-
  // Update filters
  const newFilter = {
    attribute_detail_id: attribute.detail_id,
    attribute_display_name: attribute.display_name,
    filter_values: [],
  };

  // 4-
  // Update editor state
  editorState.filters = [...filters, newFilter];
  editorState.isChanged = true;
}

function removeFilterED(state, action) {
  const { deckItemId, attributeId } = action.payload;

  const editorState = state.editors[deckItemId];
  const { filters } = editorState;

  const newFilters = filters.filter(
    ({ attribute_detail_id }) => attribute_detail_id !== attributeId
  );

  editorState.filters = newFilters;
  editorState.isChanged = true;
}

function updateFilterAttrED(state, action) {
  const { deckItemId, attribute, prevAttrId, format } = action.payload;

  const editorState = state.editors[deckItemId];
  const { filters } = editorState;

  const newFilters = filters.map((f) => {
    if (f.attribute_detail_id === prevAttrId) {
      const newFilter = {
        attribute_detail_id: attribute.attribute_detail_id,
        attribute_display_name: attribute.attribute_display_name,
        filter_values: [],
      };

      if (format) {
        newFilter.format = format;
      }

      return newFilter;
    }

    return f;
  });

  editorState.filters = newFilters;
  editorState.isChanged = true;
}

function updateFilterAttrValuesED(state, action) {
  const { deckItemId, attributeId, values, skipChanges } = action.payload;

  const editorState = state.editors[deckItemId];
  const { filters } = editorState;

  const newFilters = filters.map((f) => {
    if (f.attribute_detail_id === attributeId) {
      const newFilter = { ...f };
      newFilter.filter_values = values;

      return newFilter;
    }

    return f;
  });
  editorState.filters = newFilters;
  if (!skipChanges) {
    editorState.isChanged = true;
  }
}

function createNewEditor(item) {
  const { analysis } = item;

  return {
    show: false,
    isChanged: false,
    datasetFileId: analysis.dataset_file.dataset_file_id,
    ...parseAnalysisToEditor(analysis),
  };
}

function parseAnalysisToEditor(analysis) {
  const editorState = {};

  const {
    analysis_metrics,
    analysis_attribute,
    analysis_range,
    analysis_filters_additional = [],
  } = analysis;

  editorState.analysis = analysis;
  editorState.metrics = analysis_metrics;
  editorState.range = analysis_range;
  editorState.filters = analysis_filters_additional;
  editorState.ranking = analysis_attribute.max_items;

  // #region dimension state
  const dimension = { ...analysis_attribute };

  dimension.filter_values = analysis_attribute.filter_values || [];

  editorState.dimension = dimension;
  // #endregion dimension state

  // #region explanations state
  let explanations = [];
  if (analysis.analysis_type === "drivers") {
    explanations = analysis.analysis_drivers.explanation_levels;
  }

  editorState.explanations = explanations;
  // #endregion explanations state

  return editorState;
}
// #endregion editor

// #region single input frame
function updateSingleInpMode(state, action) {
  state.singleInput.mode = action.payload;
}

function updateInstantEditId(state, action) {
  state.singleInput.instantEdit.deckItemId = action.payload;
}

function updateInstantEditLoad(state, action) {
  state.singleInput.instantEdit.loading = action.payload;
}

function updateAnalyPlaceId(state, action) {
  state.singleInput.analyPlace.deckItemId = action.payload;
}

function updateAnalyPlaceLoad(state, action) {
  state.singleInput.analyPlace.loading = action.payload;
}

function updateEditFact(state, action) {
  state.singleInput.editChatFact = action.payload;
}

function updateEditFactId(state, action) {
  state.singleInput.editChatFact.id = action.payload;
}

function updateEditFactLoad(state, action) {
  state.singleInput.editChatFact.loading = action.payload;
}

function updateEditFactBiqaId(state, action) {
  state.singleInput.editChatFact.biqaId = action.payload;
}

function updateEditFactDatasetId(state, action) {
  state.singleInput.editChatFact.datasetId = action.payload;
}
// #endregion single input frame

// #region Chat actions
function resetChat(state) {
  state.chat = {
    isStreaming: true,
    isBiItemStreaming: false,
    data: null,
    deck_items: {
      entities: {},
      ids: [],
    },
  };
}

function updateChatStreaming(state, action) {
  state.chat.isStreaming = action.payload;
}

function updateBiItemStreaming(state, action) {
  state.chat.isBiItemStreaming = action.payload;
}

function updateIsResetStreaming(state, action) {
  state.chat.isResetStreaming = action.payload;
}

function updateChatData(state, action) {
  const items = action.payload.chat.deck_items;

  const deck_items = {
    entities: {},
    ids: [],
  };

  items?.forEach((item) => {
    const id = item.deck_item_id;
    item.is_completed = true;

    deck_items.ids.push(id);
    deck_items.entities[id] = item;
  });

  state.chat.file = action.payload.chat;
  state.chat.deck_items = deck_items;
}

function addChatItem(state, action) {
  const newItem = action.payload;

  const { entities, ids } = state.chat.deck_items;

  ids.push(newItem.deck_item_id);
  entities[newItem.deck_item_id] = newItem;
}

export function removeNewBiqaItem(state) {
  const { entities, ids } = state.chat.deck_items;

  const index = ids.length - 1;
  const id = ids[index];

  ids.pop();
  delete entities[id];
}

function updateBiqaAnswer(state, action) {
  const { entities, ids } = state.chat.deck_items;

  // Get last item id
  const index = ids.length - 1;
  const id = ids[index];

  entities[id].isLoading = false;
  entities[id].bi_qa.answers[0].text_active = action.payload;
}

function replaceBiqaItemPlaceholder(state, action) {
  const item = action.payload;
  const { entities, ids } = state.chat.deck_items;

  state.chat.file.date_last_modified = item.date_last_modified;
  state.chat.file.user_last_modified = item.user_last_modified;

  item.is_completed = true;
  entities[item.deck_item_id] = item;

  // Get last item id
  const index = ids.length - 1;
  const id = ids[index];

  ids[index] = item.deck_item_id;
  delete entities[id];
}

/**
 * updateBiqaEmbeddItem: used to update embedded deck item placeholder with
 * newly created item.
 *
 * @param {Object} item
 */
function updateBiqaEmbeddItem(state, action) {
  const { entities } = state.chat.deck_items;
  const { item, factBiqaId } = action.payload;
  const {
    new_deck_item_created,
    deck_item_id,
    original_embedded_deck_item_id,
  } = item;

  const biqaItem = entities[factBiqaId];

  state.chat.file.date_last_modified = item.date_last_modified;
  state.chat.file.user_last_modified = item.user_last_modified;

  // Update embedded deck item
  if (!new_deck_item_created) {
    biqaItem.bi_qa.embedded_facts = biqaItem.bi_qa.embedded_facts.map(
      (fact) => {
        if (fact.deck_item_id !== item.deck_item_id) return fact;

        return item;
      }
    );
    return;
  }

  biqaItem.bi_qa.embedded_facts.push(item);

  const { answers, display_index } = biqaItem.bi_qa;
  answers[display_index].text_active.forEach((answer) => {
    const { children } = answer;
    children.forEach((i) => {
      if (i.url === original_embedded_deck_item_id) {
        i.url = deck_item_id;
      }
    });
  });
}

function updatBiqaItem(state, action) {
  const item = action.payload;
  const { entities } = state.chat.deck_items;

  state.chat.file.date_last_modified = item.date_last_modified;
  state.chat.file.user_last_modified = item.user_last_modified;

  item.is_completed = true;
  entities[item.deck_item_id] = item;
}

function updatBiqaItemIsComp(state, action) {
  const deckItemId = action.payload;
  const { entities } = state.chat.deck_items;

  entities[deckItemId].is_completed = false;
}

function updateBiqaAnswerById(state, action) {
  const { answer, deckItemId } = action.payload;
  const { entities } = state.chat.deck_items;

  entities[deckItemId].isLoading = false;
  entities[deckItemId].bi_qa.answers[0].text_active = answer;
}
// #endregion Chat actions

const actions = {
  updateShowDeckEditionMenu,
  updateShowDeckDeletion,
  handleDeckDescEdit,
  showPageLoader,
  updateDeckAddItemOpts,
  updateUndoRedoPointer,
  decrementUndoRedoPointerBy,
  updateDeckItemOpts,
  updateDeckExportScr,
  updateMetricCreationScr,
  handleRelatedAnalyses,
  updateNewDeckSuggestMsg,
  updateUndoRedoStack,

  resetDeckSlice,
  updateCheckedSuggs,
  resetCheckedSuggs,
  updateValueAuditState,
  updateExportedItems,

  // Editors
  toggleEditorED,
  addNewMetricED,
  removeMetricED,
  updateMetricED,

  removeSplitED,
  updateSplitValuesED,
  updateSplitAttributeED,

  updateComparAttrED,
  updateComparAttrValueED,
  updateComparPercentED,
  updateComparPeriodED,
  removeComparED,

  updateRangeAttrED,
  addRangeED,
  removeRangeED,

  updateDimenAttrED,
  updateDimenAttrTimeFormatED,
  updateDimenAttrValuesED,

  removeRankingED,
  updateRankingMaxDispItemsED,
  addRankingED,
  updateRankingMaxTypeED,
  updateRankingMaxShowOtherED,

  updateFilterAttrValuesED,
  updateFilterAttrED,
  removeFilterED,
  addFilterED,

  updateExplanationsED,

  updateDatasetED,

  updateSingleInpMode,
  updateInstantEditId,
  updateInstantEditLoad,
  updateAnalyPlaceId,
  updateAnalyPlaceLoad,

  // Chat actions
  updateChatStreaming,
  updateChatData,
  addChatItem,
  updateBiqaAnswer,
  replaceBiqaItemPlaceholder,
  updatBiqaItem,
  updatBiqaItemIsComp,
  updateBiqaAnswerById,
  updateBiItemStreaming,
  resetChat,
  updateIsResetStreaming,
  removeNewBiqaItem,
  updateEditFact,
  updateEditFactId,
  updateEditFactLoad,
  updateEditFactDatasetId,
  updateEditFactBiqaId,
  updateBiqaEmbeddItem,
};

export default actions;
