import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { selectSegmentUserIdentify } from "store/slices/appSlice";

// Identify the authenticated user
function SegmentUserIdentify() {
  const user = useSelector(selectSegmentUserIdentify);

  const {
    onboarding_required,
    user_id,
    user_name,
    user_email,
    user_picture_secure_url,
    active,
    date_created,
    plan,
    groupId,
    userCompanyRole,
  } = user;

  useEffect(() => {
    const analytics = window.analytics;

    const traits = {
      email: user_email,
      avatar: user_picture_secure_url,
      userActive: `${active}`,
      createdAt: moment(date_created).toISOString(),
    };

    if (onboarding_required) {
      analytics.identify(user_id, traits);
      return;
    }

    traits["name"] = user_name;
    traits["plan"] = plan;

    if (groupId) {
      traits["groupId"] = groupId;
      traits["userCompanyRole"] = userCompanyRole;
    } else {
      traits["groupId"] = "";
      traits["userCompanyRole"] = "";
    }

    analytics.identify(user_id, traits);

    // With any changes in user_name, user_picture_secure_url or plan,
    // The user will be re-identify
  }, [
    onboarding_required,
    user_name,
    userCompanyRole,
    user_picture_secure_url,
    plan,
    groupId,
  ]);

  return <></>;
}

export default SegmentUserIdentify;
