import { createSelector } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";
import {
  ANALYSIS_PLACEHOLDER_MODE,
  CHAT_MODE,
  INSTANT_EDIT_MODE,
  SEARCH_MODE,
} from "services/constants";
import {
  ADD_ITEM_OPT_DEHS,
  DECK_ITEM_OPTS_DEHS,
  DELET_SCR_DEHS,
  ENLARGED_CHART_DEHS,
  TRASHED_DATASET_SCR_DEHS,
  VALUE_AUDIT_DEHS,
} from "services/history_states";
import { deckApiSlice } from "store/api/deckApiSlice";
import { selectHistoryStateProp } from "store/slices/appSlice";

// Get data from cache
const selectDeckResult = deckApiSlice.endpoints.getDeck.select();
export const selectDeckData = createSelector(
  selectDeckResult,
  (deck) => deck.data
);

// Selectors
export const selectDeckId = createSelector(selectDeckData, (deck) => {
  return deck?.file_id;
});

export const selectDeckName = createSelector(selectDeckData, (deck) => {
  return deck?.file_name;
});

export const selectDeckIsStarred = createSelector(selectDeckData, (deck) => {
  return deck.starred_by_user_requesting;
});

export const selectDeckLocked = createSelector(selectDeckData, (deck) => {
  return deck?.locked;
});

export const selectDeckIsSystem = createSelector(selectDeckData, (deck) => {
  return deck?.is_system;
});

export const selectFileMostRecentInteractions = createSelector(
  selectDeckData,
  (deck) => deck.file_most_recent_interactions
);

export const selectDeckDateLastModified = createSelector(
  selectDeckData,
  (deck) => deck.date_last_modified
);

export const selectDeckUserLastModified = createSelector(
  selectDeckData,
  (deck) => deck.user_last_modified
);

export const selectDeckDateCreated = createSelector(
  selectDeckData,
  (deck) => deck.date_created
);

export const selectDeckUserCreated = createSelector(
  selectDeckData,
  (deck) => deck.user_created
);

export const selectDeckPrivacy = createSelector(
  selectDeckData,
  (deck) => deck?.private
);

export const selectDeckExportSettings = createSelector(
  selectDeckData,
  (deck) => deck.deck_export_settings
);

export const selectAnalysesItems = createSelector(selectDeckData, (deck) => {
  const entities = deck.file_deck.deck_items.entities;
  const analyses = Object.values(entities).filter(
    (item) => item.deck_item_type === "analysis"
  );
  return analyses;
});

export const selectAnalysesItemsCount = createSelector(
  selectAnalysesItems,
  (analyses) => analyses.length
);

export const selectDeckItemsIds = createSelector(selectDeckData, (deck) => {
  return deck.file_deck.deck_items.ids;
});

export const selectDeckItemsEntities = createSelector(
  selectDeckData,
  (deck) => {
    return deck.file_deck.deck_items.entities;
  }
);

export const selectDeckDatasetsIds = createSelector(
  selectDeckData,
  (deck) => deck.file_deck.deck_dataset_files.ids
);

export const selectDeckDatasets = createSelector(selectDeckData, (deck) =>
  Object.values(deck.file_deck.deck_dataset_files.entities)
);

export const selectUserRequestingId = createSelector(
  selectDeckData,
  (deck) => deck.user_requesting.user_id
);

export const selectDeckUserOwnerId = createSelector(
  selectDeckData,
  (deck) => deck.user_owner.user_id
);

export const selectDeckDatasetsCount = createSelector(
  selectDeckData,
  (deck) => deck.file_deck.deck_dataset_file_count
);

export const selectDeckFolderId = createSelector(
  selectDeckData,
  (deck) => deck.ascending_hierarchy[0]?.folder_file_id
);

export const selectDeckDescriptionActive = createSelector(
  selectDeckData,
  (deck) => {
    return deck.file_deck.deck_description.deck_description_active;
  }
);

export const selectDeckItemsCount = createSelector(selectDeckData, (deck) => {
  return deck.file_deck.deck_item_count;
});

export const selectDeckItemById = createSelector(
  selectDeckData,
  (_, id) => id,
  (deck, id) => {
    return deck.file_deck.deck_items.entities[id];
  }
);

export const selectItemOrder = createSelector(
  selectDeckItemById,
  (item) => item.deck_item_order
);

export const selectItemType = createSelector(
  selectDeckItemById,
  (item) => item.deck_item_type
);

export const selectItemText = createSelector(
  selectDeckItemById,
  (item) => item.text
);

export const selectItemShowAsSugg = createSelector(
  selectDeckItemById,
  (item) => item.show_as_suggestion
);

export const selectItemDateMod = createSelector(
  selectDeckItemById,
  (item) => item.date_last_modified
);

// #region item analysis
export const selectItemAnalysis = createSelector(
  selectDeckItemById,
  (item) => item.analysis
);

export const selectItemAnalysisId = createSelector(
  selectDeckItemById,
  (item) => item.analysis?.analysis_id
);

export const selectItemAnalysisTitle = createSelector(
  selectDeckItemById,
  (item) => item.analysis?.analysis_title.title_active
);

export const selectItemAnalysisType = createSelector(
  selectDeckItemById,
  (item) => item.analysis?.analysis_type
);

export const selectItemAnalysisInfo = createSelector(
  selectDeckItemById,
  (item) => item.analysis?.analysis_information
);

export const selectItemAnalysisStory = createSelector(
  selectDeckItemById,
  (item) => item.analysis?.story
);

export const selectItemAnalyStoryActive = createSelector(
  selectDeckItemById,
  (item) => item.analysis.story.story_active
);

export const selectItemAnalysisRange = createSelector(
  selectDeckItemById,
  (item) => item.analysis.analysis_range
);

export const selectItemAnalysisKpis = createSelector(
  selectDeckItemById,
  (item) => item.analysis.kpis
);

export const selectItemAnalyIncludeKpis = createSelector(
  selectDeckItemById,
  (item) => item.analysis.includes_kpis
);

export const selectItemChart = createSelector(
  selectDeckItemById,
  (item) => item.analysis.chart
);

export const selectItemMetrics = createSelector(
  selectDeckItemById,
  (item) => item.analysis.analysis_metrics
);

export const selectItemDerivedDescEnabled = createSelector(
  selectDeckItemById,
  (item) => item.analysis.derived_descriptions_enabled
);

export const selectItemMetricsDerivedDriversEnabled = createSelector(
  selectDeckItemById,
  (item) => item.analysis?.metrics_derived_drivers_enabled
);

export const selectIsAnalContainData = createSelector(
  selectDeckItemById,
  (item) => item.analysis?.contains_data
);

export const selectItemDatasetId = createSelector(
  selectDeckItemById,
  (item) => item.analysis.dataset_file.dataset_file_id
);

export const selectItemDataset = createSelector(
  selectDeckData,
  selectDeckItemById,
  (deck, item) => {
    const datasetId = item.analysis.dataset_file.dataset_file_id;

    return deck.file_deck.deck_dataset_files.entities[datasetId];
  }
);
// #endregion item analysis

// #region item comments
export const selectItemActiveComments = createSelector(
  selectDeckItemById,
  (item) => item.comments.active_comments
);

export const selectItemArchivedComments = createSelector(
  selectDeckItemById,
  (item) => item.comments.archived_comments
);

export const selectItemActiveCommentsCount = createSelector(
  selectDeckItemById,
  (item) => item.comments.active_comments.length
);
// #endregion item comments

export const selectDeckCombinations = createSelector(selectDeckData, (deck) => {
  return deck.file_deck.deck_combinations;
});

export const selectNormalizedDeckCombinations = createSelector(
  selectDeckData,
  (deck) => {
    const deckCombinations = deck.file_deck.deck_combinations;
    if (!deckCombinations) return;

    const data = [];

    deckCombinations.forEach((dataset) => {
      const {
        combinations: aggregations,
        dataset_file_id,
        dataset_name,
        starred_by_user_requesting,
      } = dataset;

      aggregations.forEach((aggregation) => {
        const { aggregation_display_name, combinations: metrics } = aggregation;

        const combinations = {
          aggregation_display_name,
          dataset: {
            id: dataset_file_id,
            name: dataset_name,
            isStarred: starred_by_user_requesting,
          },
          metrics: [],
        };
        metrics.forEach((metric) => {
          const { combination_name } = metric;

          combinations.metrics.push(combination_name);
        });

        data.push(combinations);
      });
    });

    return data;
  }
);

export const selectDeckCombinationsNames = createSelector(
  selectDeckCombinations,
  (datasets) => {
    const suggestions = [];

    datasets.forEach((dataset) => {
      const { combinations, dataset_file_id } = dataset;

      combinations.forEach((combination) => {
        const { combinations } = combination;
        combinations.forEach(({ combination_name }) =>
          suggestions.push({
            datasetId: dataset_file_id,
            name: combination_name,
          })
        );
      });
    });

    return suggestions.sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const selectDatasetById = createSelector(
  selectDeckData,
  (_, id) => id,
  (deck, id) => {
    return deck.file_deck.deck_dataset_files.entities[id];
  }
);

const selectUserRequestCaps = createSelector(
  selectDeckData,
  (deck) => deck.user_requesting_capabilities || deck.public_capabilities
);

export const selectUserReqCapabCanEdit = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_edit
);
export const selectUserReqCapabCanRename = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_rename
);
export const selectUserReqCapabCanComment = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_comment
);

export const selectUserReqCapabCanCopy = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_copy
);
export const selectUserReqCapabCanLockUnlock = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_lock_unlock
);
export const selectUserReqCapabCanTrashUntrash = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_trash_untrash
);
export const selectUserReqCapabCanShare = createSelector(
  selectUserRequestCaps,
  (caps) => caps.can_share
);

export const selectAnalysisMetrics = createSelector(
  selectDeckData,
  (_, id) => id,
  (deck, id) => {
    const item = deck.file_deck.deck_items.entities[id];
    return item.analysis.analysis_metrics;
  }
);

export const selectAnalysisAttribute = createSelector(
  selectDeckData,
  (_, id) => id,
  (deck, id) => {
    const item = deck.file_deck.deck_items.entities[id];
    return item.analysis.analysis_attribute;
  }
);

export const selectAnalysisFilters = createSelector(
  selectDeckData,
  (_, id) => id,
  (deck, id) => {
    const item = deck.file_deck.deck_items.entities[id];
    return item.analysis.analysis_filters_additional;
  }
);

export const selectMetricStacksActive = createSelector(
  selectDeckData,
  (_, id) => id,
  (_, __, index) => index,
  (deck, id, index) => {
    const item = deck.file_deck.deck_items.entities[id];
    return item.analysis.analysis_metrics[index]?.stacks.stacks_active;
  }
);

export const selectMetricStacksFormat = createSelector(
  selectDeckData,
  (_, id) => id,
  (_, __, index) => index,
  (deck, id, index) => {
    const item = deck.file_deck.deck_items.entities[id];

    const { contains_data, chart } = item.analysis;
    if (!contains_data || !chart) return;

    return chart.chart_data.metrics[index]?.metric_styling.stacks_format;
  }
);

export const selectIsTrashedMetrics = createSelector(selectDeckData, (deck) => {
  return deck.file_deck.dependent_on_trashed_aggregations_in_untrashed_datasets
    .dependent;
});

export const selectTrashedMetrics = createSelector(selectDeckData, (deck) => {
  return deck.file_deck.dependent_on_trashed_aggregations_in_untrashed_datasets
    .trashed_aggregations;
});

export const selectIsTrashedDatasets = createSelector(
  selectDeckData,
  (deck) => {
    return deck.file_deck.dependent_on_trashed_dataset_files.dependent;
  }
);

export const selectTrashedDatasets = createSelector(selectDeckData, (deck) => {
  return deck.file_deck.dependent_on_trashed_dataset_files
    .trashed_dataset_files;
});
// ======================================================================================== //

// Selectors
export const selectUndoStack = ({ deck }) => deck.undoStack;

export const selectUndoPointer = ({ deck }) => deck.undoPointer;

export const selectExportedItems = ({ deck }) => deck.exportedItems;

export const selectIsEnlargedChartOpened = (state) => {
  const enlargedHistState = selectHistoryStateProp(state, ENLARGED_CHART_DEHS);
  const itemIdHistState = selectHistoryStateProp(state, "deckItemId");

  return enlargedHistState && itemIdHistState;
};

export const selectRelatedAnalyses = ({ deck }) => deck.relatedAnalyses;

export const selectIsRelatedAnalysesOpened = (
  state,
  deckItemId,
  isLargeScr
) => {
  const { deck } = state;

  const relatedAnalyses = deck.relatedAnalyses;

  const showHistState = selectHistoryStateProp(
    state,
    "showRelatedAnalysesScreen"
  );
  const itemIdHistState = selectHistoryStateProp(state, "deckItemId");

  if (relatedAnalyses.deckItemId !== deckItemId) {
    return;
  }

  if (isLargeScr) {
    return relatedAnalyses.show;
  }

  if (!relatedAnalyses.show) return;

  return showHistState && itemIdHistState === deckItemId;
};

export const selectShowDeckEditionMenu = ({ deck }) => deck.showDeckEditionMenu;

export const selectIsDeckDeletionOpened = (state) => {
  // ALGORITHM: Check "pages/DeckPage/DeckDeletion/DeckDeletion"

  const { deck } = state;

  // ALGORITHM.1.a.
  if (isMobile) {
    const histState = selectHistoryStateProp(state, DELET_SCR_DEHS);

    return histState;
  }

  // ALGORITHM.2.a.
  return deck.showDeckDeletion;
};

export const selectDeckDescEdit = ({ deck }) => deck.deckDescEdit;

export const selectNewDeckSuggestMsg = ({ deck }) => deck.newDeckSuggestMsg;

export const selectMetricCreationDataset = ({ deck }) =>
  deck.metricCreationScr.dataset;

export const selectDeckExportScrShow = ({ deck }) => deck.deckExportScr.show;

export const selectDeckExportScrType = ({ deck }) => deck.deckExportScr.type;

export const selectIsDeckItemOptsOpened = (state, id) => {
  const { deck } = state;

  const { show, deckItemId } = deck.deckItemOpts;
  if (isMobile) {
    const histState = selectHistoryStateProp(state, DECK_ITEM_OPTS_DEHS);

    return histState === id;
  }

  return show && deckItemId === id;
};

export const selectIsDeckAddItemOptsOpened = ({ deck }, id) => {
  const { show, deckItemId } = deck.deckAddItemOpts;
  return show && deckItemId === id;
};

export const selectIsTrashedDatasetsOpened = (state, showTrashedDatasets) => {
  // ALGORITHM.1.a
  if (isMobile) {
    const histState = selectHistoryStateProp(state, TRASHED_DATASET_SCR_DEHS);

    return histState;
  }

  // ALGORITHM.2.a
  return showTrashedDatasets;
};

export const selectIsAddItemOptsOpened = (state, showAddItemOpts) => {
  // ALGORITHM_01
  // Check "pages/DeckPage/ItemOptions/options/AddItemOpt"
  // ALGORITHM_01.1.a.
  if (isMobile) {
    const histState = selectHistoryStateProp(state, ADD_ITEM_OPT_DEHS);

    return histState;
  }

  // ALGORITHM_01.2.a.
  return showAddItemOpts;
};

export const selectIsMetricCreationScrOpened = (state) => {
  // ALGORITHM_01
  // Check "pages/DeckPage/MetricCreationScreen/MetricCreationScreen"

  const { deck } = state;

  // ALGORITHM_01.1.a.
  if (isMobile) {
    const histState = selectHistoryStateProp(state, "metricCreationScr");

    return histState?.show;
  }

  // ALGORITHM_01.2.a.
  return deck.metricCreationScr.show;
};

// #region AllSuggestionsMenu
export const selectCheckedSuggs = ({ deck }) => {
  return deck.allSuggesMenuState.checked;
};

export const selectIsSuggChecked = ({ deck }, index) => {
  return deck.allSuggesMenuState.checked.includes(index);
};

export const selectCheckedSuggCount = ({ deck }) => {
  return deck.allSuggesMenuState.checked.length;
};
// #endregion AllSuggestionsMenu

export const selectIsValueAuditScrOpened = (state) => {
  // ALGORITHM
  // Check "components/Deck/ValueAuditScreen/ValueAuditScreen"

  const { deck } = state;

  const histState = selectHistoryStateProp(state, VALUE_AUDIT_DEHS);

  return deck.valueAuditState.isOpened && histState;
};

export const selectIsValueAuditData = ({ deck }) => {
  return deck.valueAuditState;
};

// #region editors
const selectEditor = ({ deck }, id) => deck.editors[id];

export const selectIsEditorOpened = createSelector(
  (s, id) => selectEditor(s, id),
  (editor) => editor?.show
);

export const selectIsEditorChanged = createSelector(
  (s, id) => selectEditor(s, id),
  (editor) => editor.isChanged
);

export const selectMetricsED = createSelector(
  (s, id) => selectEditor(s, id),
  (editor) => editor.metrics
);

export const selectRankingED = createSelector(
  (s, id) => selectEditor(s, id),
  (editor) => editor.ranking
);

export const selectFiltersED = createSelector(
  (s, id) => selectEditor(s, id),
  (editor) => editor.filters
);

export const selectDimensionED = createSelector(
  (s, id) => selectEditor(s, id),
  (editor) => editor.dimension
);

export const selectRangeED = createSelector(
  (s, id) => selectEditor(s, id),
  (editor) => editor.range
);

export const selectDatasetIdED = createSelector(
  (s, id) => selectEditor(s, id),
  (editor) => {
    return editor.datasetFileId;
  }
);

export const selectDatasetED = createSelector(
  selectDeckData,
  (s, id) => selectEditor(s, id),
  (deck, editor) => {
    const datasetFileId = editor.datasetFileId;
    const dataset = deck.file_deck.deck_dataset_files.entities[datasetFileId];

    return dataset;
  }
);

export const selectExplanationsED = createSelector(
  (s, id) => selectEditor(s, id),
  (editor) => {
    return editor.explanations;
  }
);

export const selectExplanationsCountED = createSelector(
  (s, id) => selectExplanationsED(s, id),
  (explanations) => {
    return explanations.length;
  }
);

export const selectMetricsCountED = createSelector(
  (s, id) => selectEditor(s, id),
  (editor) => editor.metrics.length
);

export const selectDatasetAttrsED = createSelector(
  (s, id) => selectDatasetED(s, id),
  (dataset) => {
    const attributeHeaders = [];
    dataset.dataset_details.forEach((attr) => {
      const { display_name, detail_id, is_attribute } = attr;

      if (!is_attribute) return;

      attributeHeaders.push({
        label: display_name,
        attribute_display_name: display_name,
        attribute_detail_id: detail_id,
        ...attr,
      });
    });

    return attributeHeaders;
  }
);

export const selectRangeAttrsED = createSelector(
  (s, id) => selectMetricsED(s, id),
  (s, id) => selectDatasetED(s, id),
  (metrics, dataset) => {
    const compAttr = metrics.map(({ comparison }) => {
      return comparison.attribute_detail_id;
    });

    const attributeHeaders = [];
    dataset.dataset_details.forEach((attr) => {
      const { display_name, detail_id, is_attribute, is_time_attribute } = attr;

      if (!is_attribute || !is_time_attribute) return;

      let isDisabled = compAttr.includes(detail_id);

      attributeHeaders.push({
        label: display_name,
        isDisabled,
        attribute_display_name: display_name,
        attribute_detail_id: detail_id,
        ...attr,
      });
    });

    return attributeHeaders;
  }
);

export const selectAdditionalFiltersED = createSelector(
  (s, id) => selectFiltersED(s, id),
  (s, id) => selectMetricsED(s, id),
  (filters, metrics) => {
    const stacksCompAttrs = metrics.reduce((acc, curr) => {
      const { stacks, comparison } = curr;
      if (stacks.attribute_detail_id) {
        acc.push(stacks.attribute_detail_id);
      }
      if (comparison.attribute_detail_id) {
        acc.push(comparison.attribute_detail_id);
      }
      return acc;
    }, []);

    const newFilters = filters.filter((filter) => {
      const { attribute_detail_id } = filter;

      return !stacksCompAttrs.includes(attribute_detail_id);
    });

    return newFilters;
  }
);

export const selectFiltersAttrsED = createSelector(
  (s, id) => selectFiltersED(s, id),
  (s, id) => selectMetricsED(s, id),
  (s, id) => selectDatasetED(s, id),
  (s, id) => selectDimensionED(s, id),
  (filters, metrics, dataset, dimension) => {
    const disabledAttrs = [dimension.attribute_display_name];

    filters.forEach(({ attribute_display_name }) => {
      disabledAttrs.push(attribute_display_name);
    });

    metrics.forEach((metric) => {
      const { stacks, comparison } = metric;

      disabledAttrs.push(stacks.attribute_display_name);
      disabledAttrs.push(comparison.attribute_display_name);
    });

    const attributeHeaders = [];
    dataset.dataset_details.forEach((attr) => {
      const { display_name, detail_id, is_attribute } = attr;

      if (!is_attribute) return;

      attributeHeaders.push({
        label: display_name,
        isDisabled: disabledAttrs.includes(display_name),
        attribute_display_name: display_name,
        attribute_detail_id: detail_id,
        ...attr,
      });
    });

    return attributeHeaders;
  }
);

export const selectSplitOptsED = createSelector(
  (s, id) => selectMetricsED(s, id),
  (s, id) => selectDatasetED(s, id),
  (metrics, dataset) => {
    const stacksCompAttrs = metrics.reduce((acc, curr) => {
      const { stacks, comparison } = curr;
      if (stacks.attribute_detail_id) {
        acc.push(stacks.attribute_detail_id);
      }
      if (comparison.attribute_detail_id) {
        acc.push(comparison.attribute_detail_id);
      }
      return acc;
    }, []);

    const options = [];
    dataset.dataset_details.forEach((attr) => {
      const { display_name, detail_id, is_attribute } = attr;

      if (!is_attribute) return;

      const isDisabled = stacksCompAttrs.includes(detail_id);

      options.push({
        label: display_name,
        attribute_display_name: display_name,
        attribute_detail_id: detail_id,
        isDisabled,
        ...attr,
      });
    });

    return options;
  }
);

export const selectFiltersValuesED = createSelector(
  (s, id) => selectFiltersED(s, id),
  (_, __, attrDetailId) => attrDetailId,
  (filters, attrDetailId) => {
    // ALGORITHM:
    // 1- If user select SOME attribute values
    //    1.a. THEN get the values from [analysis_filters_additional][filter_values]
    // 2- If user select ALL attribute values
    //    2.a. THEN get the values from dataset
    const filter = filters.find((f) => f.attribute_detail_id === attrDetailId);

    let values = filter?.filter_values || [];

    return values;
  }
);

export const selectComparOptsED = createSelector(
  (s, id) => selectMetricsED(s, id),
  (s, id) => selectDatasetED(s, id),
  (s, id) => selectDimensionED(s, id),
  (s, id) => selectRangeED(s, id),
  (metrics, dataset, dimension, range) => {
    const dimenAttr = dimension.attribute_detail_id;
    const rangeAttr = range.attribute_detail_id;

    const disabledOpts = [];

    // TODO: Double check this logic
    if (dimenAttr !== rangeAttr) {
      disabledOpts.push(rangeAttr);
    }

    metrics.forEach(({ stacks }) => {
      const { attribute_detail_id } = stacks;
      if (!attribute_detail_id) return;
      disabledOpts.push(attribute_detail_id);
    });

    const options = [];
    dataset.dataset_details.forEach((attr) => {
      const { is_attribute, is_time_attribute, display_name, detail_id } = attr;

      if (!is_attribute || !is_time_attribute) return;

      const isDisabled = disabledOpts.includes(detail_id);

      options.push({
        label: display_name,
        attribute_display_name: display_name,
        attribute_detail_id: detail_id,
        isDisabled,
        ...attr,
      });
    });

    return options;
  }
);

export const selectComparAttrsED = createSelector(
  (s, id) => selectMetricsED(s, id),
  (metrics) => {
    return metrics.map(({ comparison }) => {
      return comparison.attribute_detail_id;
    });
  }
);

export const selectIsRangeDisabledED = createSelector(
  (s, id) => selectMetricsED(s, id),
  (s, id) => selectDatasetED(s, id),
  (s, id) => selectExplanationsED(s, id),
  (metrics, dataset, explanations) => {
    const compAttr = metrics.map(({ comparison }) => {
      return comparison.attribute_display_name;
    });
    const timeAttrs = dataset.dataset_details.filter((detail) => {
      const { is_attribute, is_time_attribute, display_name } = detail;
      if (!is_attribute || !is_time_attribute) return false;
      return !compAttr.includes(display_name);
    });

    return !!explanations.length || !timeAttrs.length;
  }
);
// #endregion editors

// #region single input frame
export const selectSingleInpMode = ({ deck }) => deck.singleInput.mode;
export const selectIsSearchMode = ({ deck }) =>
  deck.singleInput.mode === SEARCH_MODE;
export const selectIsChatMode = ({ deck }) =>
  deck.singleInput.mode === CHAT_MODE;
export const selectIsInstantMode = ({ deck }) =>
  deck.singleInput.mode === INSTANT_EDIT_MODE;
export const selectIsAnalyPlaceMode = ({ deck }) =>
  deck.singleInput.mode === ANALYSIS_PLACEHOLDER_MODE;

export const selectInstantEditId = ({ deck }) =>
  deck.singleInput.instantEdit.deckItemId;
export const selectInstantEditLoad = ({ deck }) =>
  deck.singleInput.instantEdit.loading;

export const selectEditFactId = ({ deck }) => deck.singleInput.editChatFact.id;
export const selectEditFactLoad = ({ deck }) =>
  deck.singleInput.editChatFact.loading;
export const selectEditFactBiqaId = ({ deck }) =>
  deck.singleInput.editChatFact.biqaId;
export const selectEditFactDatasetId = ({ deck }) =>
  deck.singleInput.editChatFact.datasetId;

export const selectIsInstantEditId = createSelector(
  selectIsInstantMode,
  selectInstantEditId,
  (_, deckItemId) => deckItemId,
  (isInstMode, instantEditId, deckItemId) =>
    isInstMode && instantEditId === deckItemId
);

export const selectIsInstantEditLoad = createSelector(
  selectIsInstantEditId,
  selectInstantEditLoad,
  (isInstId, load) => isInstId && load
);

export const selectAnalyPlaceId = ({ deck }) =>
  deck.singleInput.analyPlace.deckItemId;
export const selectAnalyPlaceLoad = ({ deck }) =>
  deck.singleInput.analyPlace.loading;
export const selectIsAnalyPlaceId = createSelector(
  selectIsAnalyPlaceMode,
  selectAnalyPlaceId,
  (_, deckItemId) => deckItemId,
  (isAnalMode, analyPlaceId, deckItemId) =>
    isAnalMode && analyPlaceId === deckItemId
);
export const selectIsAnalyPlaceLoad = createSelector(
  selectIsAnalyPlaceId,
  selectAnalyPlaceLoad,
  (isAnalyId, load) => isAnalyId && load
);
// #endregion single input frame

// #region chat
export const selectIsChatStreaming = ({ deck }) => deck.chat.isStreaming;
export const selectIsBiItemStreaming = ({ deck }) =>
  deck.chat.isBiItemStreaming;
export const selectIsResetStreaming = ({ deck }) => deck.chat.isResetStreaming;
export const selectIsChatEmpty = ({ deck }) =>
  deck.chat.deck_items.ids.length === 0;
export const selectChatItemsCount = ({ deck }) =>
  deck.chat.deck_items.ids.length || 0;
export const selectChatItemIds = ({ deck }) => deck.chat.deck_items.ids;
export const selectChatItemEntities = ({ deck }) =>
  deck.chat.deck_items.entities;
export const selectChatItemById = ({ deck }, deckItemId) =>
  deck.chat.deck_items.entities[deckItemId];
// #endregion chat
