import { Suspense, lazy } from "react";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useIsSidebarDisplayed } from "components/shared/AppSideBar/hooks";
import { ELID_TOAST_POPOVER } from "services/elements_ids";

import "./style.css";

const XIcon = lazy(() => import("assets/icons/shared/XIcon"));

function Toastify() {
  const isSidebar = useIsSidebarDisplayed();

  return (
    <div id={ELID_TOAST_POPOVER} popover="manual" className="toastify-popover">
      <ToastContainer
        position={"bottom-center"}
        draggable={false}
        hideProgressBar
        transition={Slide}
        closeButton={CloseButton}
        closeOnClick={false}
        className={isSidebar && "toastify-sidebar"}
      />
    </div>
  );
}

function CloseButton({ closeToast }) {
  return (
    <button className="Toastify-close-btn" onClick={closeToast}>
      <Suspense fallback={<></>}>
        <XIcon />
      </Suspense>
    </button>
  );
}

export default Toastify;
