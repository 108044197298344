import { createSlice } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";

import {
  ACCEPT_INVITE_SEHS,
  ACTIVE_SCR_SEHS,
  DELETE_SCR_SEHS,
} from "services/history_states";
import { selectHistoryStateProp } from "store/slices/appSlice";

const initialState = {
  memberActivationScr: { show: false, member: null },
  memberDeletionScr: { show: false, member: null },
  membersTab: {
    members: null,
    loading: true,
  },
  proposalToAccept: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetSettingsSlice: () => initialState,
    handleMemberActivationScr: (state, action) => {
      state.memberActivationScr = action.payload;
    },
    handleMemberDeletionScr: (state, action) => {
      state.memberDeletionScr = action.payload;
    },
    resetMembersTab: (state) => {
      state.membersTab = {
        members: null,
        loading: true,
      };
    },
    updateMembers: (state, action) => {
      state.membersTab = action.payload;
    },
    updateMember: (state, action) => {
      const updatedMemb = action.payload;
      state.membersTab.members = state.membersTab.members.map((memb) => {
        if (memb.user_id === updatedMemb.user_id) {
          return updatedMemb;
        }

        return memb;
      });
    },
    updateProposalToAccept: (state, action) => {
      state.proposalToAccept = action.payload;
    },
  },
});

export const {
  resetSettingsSlice,
  resetMembersTab,
  updateMembers,
  updateMember,
  handleMemberActivationScr,
  handleMemberDeletionScr,
  updateProposalToAccept,
} = settingsSlice.actions;

export default settingsSlice.reducer;

// Selectors
export const selectMembersData = ({ settings }) => {
  return settings.membersTab.members;
};

export const selectMembersLoading = ({ settings }) => {
  return settings.membersTab.loading;
};

export const selectIsMemberActivScrOpened = (state) => {
  const { settings } = state;

  const { show, member } = settings.memberActivationScr;

  if (isMobile) {
    const histState = selectHistoryStateProp(state, ACTIVE_SCR_SEHS);

    return show && member && histState;
  }

  return show && member;
};

export const selectActivationMemb = ({ settings }) => {
  return settings.memberActivationScr.member;
};

export const selectIsMemberDeletionScrOpened = (state) => {
  const { settings } = state;

  const { show, member } = settings.memberDeletionScr;

  if (isMobile) {
    const histState = selectHistoryStateProp(state, DELETE_SCR_SEHS);

    return show && member && histState;
  }

  return show && member;
};

export const selectDeletionMemb = ({ settings }) => {
  return settings.memberDeletionScr.member;
};

export const selectProposalToAccept = ({ settings }) => {
  return settings.proposalToAccept;
};

export const selectIsAcceptPropOpened = (state) => {
  // ALGORITHM
  // Check "pages/SettingsPage/AcceptInvite/AcceptInvite"

  const { settings } = state;

  // ALGORITHM.2.a. AND ALGORITHM.2.b.
  if (isMobile) {
    const histState = selectHistoryStateProp(state, ACCEPT_INVITE_SEHS);

    return settings.proposalToAccept && histState;
  }

  // ALGORITHM 2.a.
  return settings.proposalToAccept;
};
