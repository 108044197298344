import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ClipLoader from "components/shared/Loaders/ClipLoader/ClipLoader";

import Logo from "components/shared/Logo/Logo";

import {
  handleIsInitAppLoading,
  selectIsInitAppLoading,
} from "store/slices/appSlice";
import { TEID_INIT_PAGE_LOADER } from "services/test_ids";

// CSS prefix: .initpage-loader-
import "./style.css";

/**
 * InitPageLoader: used to handle app and page loader.
 *
 * - The message 'Loading Quickchin' needs to be displayed in loader when the
 *   user visits the app for the first time.
 * - To manage scenario, the redux store is used.
 *
 * @param {isPage} boolean Check if loader is used in page or not.
 * @param {caption} string custom caption to be displyed under loader.
 */
function InitPageLoader({ isPage = true, caption }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isInitAppLoad = useSelector(selectIsInitAppLoading);

  useEffect(() => {
    return () => isPage && dispatch(handleIsInitAppLoading(false));
  }, [dispatch, isPage]);

  const cap = caption || t("global.loading_qrunchin");
  return (
    <div className="initpage-loader" data-testid={TEID_INIT_PAGE_LOADER}>
      <div className="initpage-loader-icon">
        <ClipLoader />
        <Logo isIcon />
      </div>
      {isInitAppLoad && <p className="initpage-loader-caption">{cap}</p>}
    </div>
  );
}

export default InitPageLoader;
