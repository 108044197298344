const initialState = {
  dataset: null,
  file: null,
  isProcessing: false,
  isUploading: false,
  sourceType: null,
  error: null,
  showSheets: false,
  showConfirm: false,
  showDelete: false,
};

export default initialState;
