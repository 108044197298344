function updatePageLoader(state, action) {
  state.pageLoader = action.payload;
}

function handleFileMoveScreen(state, action) {
  state.fileMove = action.payload;
}

function updateSelect(state, action) {
  state.select = action.payload;
}

function updateSelectedFiles(state, action) {
  state.select.filesIds = action.payload;
}

function updateFileRename(state, action) {
  state.rename = action.payload;
}

function updateIsSharedView(state, action) {
  state.isSharedView = action.payload;
}

function handleFileDeletionScr(state, action) {
  state.fileDeletionScrState = action.payload;
}

function updateIsDraggingActive(state, action) {
  state.drag.isDraggingActive = action.payload;
}

function onDragStart(state, action) {
  // ALGORITHM:
  // 1- Update dragging status.
  // 2- Select the draggable file if it is not already selected.
  //    2.a. Check if file is selected
  //    2.b. Get the files from store to get draggable file
  //    2.c. Update the selected files with the draggable file

  const { draggableId, draggableFile } = action.payload;

  // ALGORITHM.1.
  state.drag.isDraggingActive = true;

  // ALGORITHM.2.
  // ALGORITHM.2.a
  const selectedFiles = state.selectedFilesState.files;
  const isSelected = selectedFiles.some(
    ({ file_id }) => file_id === draggableId
  );

  if (!isSelected) {
    state.selectedFilesState.files = [draggableFile];
  }
}

let actions = {
  updateIsSharedView,
  handleFileDeletionScr,
  updatePageLoader,
  handleFileMoveScreen,
  updateSelectedFiles,
  updateFileRename,
  onDragStart,
  updateIsDraggingActive,
  updateSelect,
};

export default actions;
