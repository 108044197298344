import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  selectHistoryPathname,
  selectHistorySearch,
} from "store/slices/appSlice";

function SegmentPageTracking() {
  const pathname = useSelector(selectHistoryPathname);
  const search = useSelector(selectHistorySearch);

  useEffect(() => {
    const analytics = window.analytics;

    if (!analytics) return;
    analytics.page();

    // With [location] as the dependency the code will execute even if user navigates to the same page
    // Example: if history state is updated
  }, [pathname, search]);

  return <></>;
}

export default SegmentPageTracking;
