import { useSelector } from "react-redux";
import { Suspense, lazy } from "react";

import { navigate } from "services/react_router";

import {
  errorCodesMessages,
  errorStatusMessages,
  errorCodes,
  errorStatus,
  planDatasetLimitErrorCodes,
} from "services/error_messages";
import { selectHistoryPathname } from "store/slices/appSlice";

import "./style.css";

const PlanIcon = lazy(() => import("assets/icons/shared/PlanIcon"));
const CloudIllustration = lazy(() =>
  import("assets/illustrations/FileCloudIllust")
);

function ErrorComponent({ className, retry, error, status, code, closeError }) {
  const pathname = useSelector(selectHistoryPathname);

  function onClick(type) {
    if (type === "Home") {
      navigate("/");
      return;
    }

    retry();
  }

  function getSecondaryMessage() {
    if (code) {
      return errorCodesMessages[code].secondary;
    }

    return errorStatusMessages[status].secondary;
  }

  function getPlanSecondaryMessage() {
    if (code === 40351) {
      return errorCodesMessages[code].secondary(error.details.limit);
    }

    if (code === 40352) {
      const { limit, display_suffix } = error.details;
      return errorCodesMessages[code].secondary(limit, display_suffix);
    }

    if (code === 40353) {
      const { limit } = error.details;
      return errorCodesMessages[code].secondary(limit);
    }

    if (code === 40354) {
      const { limit } = error.details;
      return errorCodesMessages[code].secondary(limit);
    }
  }

  function getPrimaryMsg() {
    if (code) {
      return errorCodesMessages[code].primary;
    }
    return errorStatusMessages[status].primary;
  }

  function getButton() {
    const button = code
      ? errorCodesMessages[code].button
      : errorStatusMessages[status].button;

    if (!button) return false;

    return button;
  }

  if (
    (!status && !code) ||
    (!errorStatus.includes(status) && !errorCodes.includes(code))
  ) {
    return (
      <div className={`error-comp ${className}`}>
        <div className="errcomp-cloud-icon">
          <Suspense fallback={<></>}>
            <CloudIllustration />
          </Suspense>
        </div>
        <p className="errcomp-primary-msg">
          {errorStatusMessages.default.primary}
        </p>

        <p className="errcomp-secondary-msg">
          {errorStatusMessages.default.secondary}
        </p>

        <button className="errcomp-btn" onClick={() => onClick("Home")}>
          Home
        </button>
      </div>
    );
  }

  function onClickUpgrade() {
    closeError && closeError();

    navigate(
      {
        pathname,
        search: "?settings=true&tab=plan",
      },
      {
        replace: true,
      }
    );
  }
  if (planDatasetLimitErrorCodes.includes(code)) {
    return (
      <div className={`error-comp ${className}`}>
        <div className="errcomp-plan-icon">
          <Suspense fallback={<></>}>
            <PlanIcon />
          </Suspense>
        </div>
        <p className="errcomp-primary-msg">{getPrimaryMsg()}</p>

        <p className="errcomp-secondary-msg">{getPlanSecondaryMessage()}</p>

        <button
          className="errcomp-btn errcomp-upgrade-btn"
          onClick={onClickUpgrade}
        >
          Upgrade plan
        </button>
      </div>
    );
  }

  return (
    <div className={`error-comp ${className}`}>
      <div className="errcomp-cloud-icon">
        <Suspense fallback={<></>}>
          <CloudIllustration />
        </Suspense>
      </div>
      <p className="errcomp-primary-msg">{getPrimaryMsg()}</p>

      <p className="errcomp-secondary-msg">{getSecondaryMessage()}</p>

      {getButton() && (
        <button className="errcomp-btn" onClick={() => onClick(getButton())}>
          {getButton()}
        </button>
      )}
    </div>
  );
}

export default ErrorComponent;
