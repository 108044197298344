import { SEARCH_MODE } from "services/constants";

const initialState = {
  undoStack: [{ undo: { type: "default" }, redo: { type: "default" } }],
  undoPointer: 0,
  deckDescEdit: false,
  relatedAnalyses: {
    show: false,
    valueOrder: null,
    metricOrder: null,
    deckItemId: null,
  },
  newDeckSuggestMsg: {
    show: false,
    count: null,
  },
  showDeckDeletion: false,
  showDeckEditionMenu: false,
  deckExportScr: {
    show: false,
    type: null,
  },
  deckItemOpts: {
    show: false,
    deckItemId: null,
  },
  deckAddItemOpts: {
    show: false,
    deckItemId: null,
  },
  metricCreationScr: {
    show: false,
    dataset: null,
  },
  allSuggesMenuState: {
    checked: [],
  },
  valueAuditState: {
    isOpened: false,
    deckItemId: null,
    metricOrder: null,
    stackOrder: null,
    valueOrder: null,
  },
  exportedItems: [],
  editors: {},
  singleInput: {
    mode: SEARCH_MODE,

    instantEdit: {
      deckItemId: null,
      loading: false,
    },

    analyPlace: {
      deckItemId: null,
      loading: false,
    },

    editChatFact: {
      id: null,
      loading: false,
      biqaId: null,
      datasetId: null,
    },
  },
  chat: {
    isStreaming: true,
    isBiItemStreaming: false,
    isResetStreaming: false,
    file: null,
    deck_items: {
      entities: {},
      ids: [],
    },
  },
};

export default initialState;
