import { createSelector, createSlice } from "@reduxjs/toolkit";

import { homeApiSlice } from "store/api/homeApiSlice";

// Get data from cache
const selectUserInfoResult = homeApiSlice.endpoints.recentlyUserInfo.select();
const selectUserInfoData = createSelector(
  selectUserInfoResult,
  (user) => user.data
);

const initialState = {
  pageLoader: false,
};

const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    resetHomeSlice: () => initialState,
    updatePageLoader: (state, action) => {
      state.pageLoader = action.payload;
    },
    updateRecentlyUserInfo: (state, action) => {
      state.files = action.payload;
    },
  },
});

export const { updatePageLoader, updateRecentlyUserInfo, resetHomeSlice } =
  homepageSlice.actions;

export default homepageSlice.reducer;

// Selector
export const selectIsEmptyState = createSelector(
  selectUserInfoData,
  (user) => !user?.groups.ids.length
);

export const selectGroupsIds = createSelector(
  selectUserInfoData,
  (user) => user.groups.ids
);

export const selectGroupById = createSelector(
  selectUserInfoData,
  (_, groupId) => groupId,
  (user, groupId) => {
    return user.groups.entities[groupId];
  }
);

export const selectItemById = createSelector(
  selectUserInfoData,
  (_, itemId) => itemId,
  (user, itemId) => {
    return user.items.entities[itemId];
  }
);

export const selectDatasetById = createSelector(
  selectUserInfoData,
  (state) => state.app.newlyCreatedDataset,
  (_, fileId) => fileId,
  (user, newlyCreatedDataset, fileId) => {
    if (newlyCreatedDataset?.file_id === fileId) {
      return newlyCreatedDataset;
    }

    return user?.items.entities[fileId];
  }
);

export const selectPageLoader = ({ homepage }) => {
  return homepage.pageLoader;
};
