import { createBrowserRouter } from "react-router-dom";

import store from "store/store";
import {
  selectHistoryHash,
  selectHistoryPathname,
  selectHistorySearch,
} from "store/slices/appSlice";
import AppPageWrapper from "pages/AppPageWrapper";

export const createReactRouter = (element) => {
  // if (process.env.JEST_WORKER_ID && process.env.NODE_ENV === "test") {
  //   const MockAppPage = import("mocks/components/MockAppPage");
  //   return createMemoryRouter([{ path: "*", element: <MockAppPage /> }], {
  //     initialIndex: 0,
  //   });
  // }

  return createBrowserRouter([{ path: "*", element }]);
};

export let router = createReactRouter(<AppPageWrapper />);
export const navigate = router.navigate;

export function navigateHistoryState(navigateOpts) {
  const storeState = store.getState();
  const pathname = selectHistoryPathname(storeState);
  const search = selectHistorySearch(storeState);
  const hash = selectHistoryHash(storeState);

  navigate({ pathname, search, hash }, navigateOpts);
}
