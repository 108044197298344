import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { selectUserInfoSegmentGroupAssoc } from "store/slices/appSlice";

function SegmentGroupAssociation() {
  const user = useSelector(selectUserInfoSegmentGroupAssoc);

  const { groupId, name, plan, avatar, createdAt } = user;

  useEffect(() => {
    const analytics = window.analytics;

    if (groupId) {
      const traits = {
        name,
        plan,
        avatar,
        createdAt: moment(createdAt).toISOString(),
      };

      analytics.group(groupId, traits);
    }

    // groupId is passed as dependency to detect if individual user accept the invites

    // With any changes in company_name, company_logo_secure_url or plan_name,
    // The group will be re-associated
  }, [groupId, name, avatar, plan, createdAt]);

  return <></>;
}

export default SegmentGroupAssociation;
