const defaultErrorPrimaryMsg = "Oops, something went wrong";

const defaultErrorSecondaryMsg =
  "Please try again after a while. If the problem persists, please contact our support team.";

export const errorStatusMessages = {
  default: {
    primary: defaultErrorPrimaryMsg,
    secondary: defaultErrorSecondaryMsg,
    button: "Home",
  },
  400: {
    primary: defaultErrorPrimaryMsg,
    secondary: defaultErrorSecondaryMsg,
    button: "Retry",
  },
  401: {
    primary: defaultErrorPrimaryMsg,
    secondary: defaultErrorSecondaryMsg,
    button: "Home",
  },
  403: {
    primary: "Oops, page or file out of reach",
    secondary:
      "It looks like you do not have permission to access this page or file.",
    button: "Home",
  },
  404: {
    primary: "Oops, page or file not found",
    secondary:
      "We cannot seem to find the page or file you are looking for. It may have been trashed.",
    button: "Home",
  },
  415: {
    primary: "Oops, file not supported",
    secondary:
      "We do not support the format of the file that you submitted. Please have a look at our list of supported files.",
    button: false,
  },
  500: {
    primary: defaultErrorPrimaryMsg,
    secondary: defaultErrorSecondaryMsg,
    button: "Home",
  },
};

export const errorCodesMessages = {
  40051: {
    primary: "Oops, the sheet may be empty",
    secondary:
      "No data was found in the selected sheet. If the sheet actually contains data, please contact our support team.",
  },
  40052: {
    primary: "Oops, the file was not recognized",
    secondary:
      "The content of the Excel file was not recognized. If the problem persists, please contact our support team.",
  },
  40053: {
    primary: "Oops, the file is not supported",
    secondary: "The dataset columns limit was reached.",
  },
  40081: { primary: "Your query was not recognized. See examples" },
  400801: { primary: "Your query was not recognized. See examples" },
  400821: {
    primary: "To add more metrics, please remove the current metric’s splits.",
  },
  400822: { primary: "To add splits, please keep only one metric." },
  400823: { primary: "Sorry, an analysis must keep at least one metric." },
  400824: { primary: "Sorry, an analysis must keep at least one dimension." },
  400851: { primary: "One of your queries was not recognized. See examples" },
  40301: {
    primary: "Your account is deactivated",
    secondary:
      "If you believe this is an error and your account should be reactivated, please contact our support team.",
  },
  40302: {
    primary: "Your account is deactivated",
    secondary:
      "If you believe this is an error and your account should be reactivated, please contact your organization’s admin or our support team.",
  },
  40303: {
    primary: "Your account is deactivated",
    secondary:
      "If you believe this is an error and your account should be reactivated, please contact your organization’s admin or our support team.",
  },
  41501: {
    primary: "Oops, the file is not supported",
    secondary: "Supported file extensions are .csv, .xls, and .xlsx.",
  },
  41502: {
    primary: "Oops, the file was not recognized",
    secondary:
      "The content of the Excel file was not recognized. If the problem persists, please contact our support team.",
  },
  40351: {
    primary: "One of your plan’s limits has been reached",
    secondary: (limit) => `Your plan has a limit of ${limit} datasets.`,
  },
  40352: {
    primary: "One of your plan’s limits has been reached",
    secondary: (limit, display_suffix) =>
      `Your plan has a data storage limit of ${limit}${display_suffix}.`,
  },
  40353: {
    primary: "One of your plan’s limits has been reached",
    secondary: (limit) =>
      `Your plan has a limit of ${limit} data points per dataset.`,
  },
  40354: {
    primary: "One of your plan’s limits has been reached",
    secondary: (limit) => `Your plan has a limit of ${limit} decks.`,
  },
  40321: {
    primary: "Your trial has ended",
    secondary: "Subscribe now to keep enjoying all features.",
    button: "Subscribe",
  },
  40322: {
    primary: "Your subscription is paused",
    secondary: "Reactivate it to regain access to all features.",
    button: "Reactivate",
  },
};

export const deactivatedErrorCodes = [40301, 40302, 40303];
export const planDatasetLimitErrorCodes = [40351, 40352, 40353, 40354];
export const instantEditErrorCodes = [
  40081, 400801, 400821, 400822, 400823, 400824, 400851,
];
export const subscriptionStatusLimitsCodes = [40321, 40322];
export const errorCodes = [
  40051, 40052, 40053, 40301, 40302, 40303, 41501, 41502, 40351, 40352, 40353,
  40354, 40081, 400801, 400821, 400822, 400823, 400824, 400851, 40321, 40322,
];
export const errorStatus = [400, 401, 403, 404, 415, 500];
